import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  lessons_amount: 0,
  expected_lessons: 0,
  billable_lessons: 0,
  total_lessons: 0,
};
const slice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null;
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload; // if you only write action.payload, you do not dot-in to the actual data, where all data for lesson is
    },
    getLessonsAmountSuccess(state, action) {
      state.isLoading = false;
      state.lessons_amount = action.payload.actual_lessons;
      state.expected_lessons = action.payload.expected_lessons;
      state.billable_lessons = action.payload.billable_lessons;
      state.total_lessons = action.payload.total_lessons;
    },
  },
});

export default slice.reducer;

/**
 *
 * @param start_date Lessons between this date, this is the first date
 * @param end_date Lessons between this date, this is the last date
 * @returns array of lessons and assings them to the state
 */
export function getLessonsAmount(start_date: string | undefined, end_date: string | undefined) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    // console.log('herer si the id for lessonsForStudent', id);

    const url =
      start_date && end_date
        ? `/api/statistics?start_date=${start_date}&end_date=${end_date}`
        : `/api/statistics`;
    try {
      const response = await axios.get(url, { withCredentials: true });
      // console.log(response);
      dispatch(slice.actions.getLessonsAmountSuccess(response.data.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
