import {
  Stack,
  Switch,
  Button,
  Card,
  Box,
  Typography,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
} from '@mui/material';
import { useState } from 'react';
import { IInvoice } from 'src/@types/invoice';
import { useSnackbar } from '../../../components/snackbar';
import { useLocales } from 'src/locales';

import Iconify from '../../../components/iconify';

// redux
import { useDispatch } from '../../../redux/store';

import { updateMembership } from 'src/redux/slices/invoice';

type Props = {
  latestCustomerOrder: IInvoice | null;
  customer_id?: string;
};

export default function DisableAutomaticRenewal({ latestCustomerOrder, customer_id }: Props) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const { translate } = useLocales();

  const [switchValue, setSwitchValue] = useState<boolean>(
    latestCustomerOrder ? latestCustomerOrder?.automatic_renewal : true
  );
  // console.log(switchValue);
  const handleUpdate = async (newRenewalValue: boolean) => {
    try {
      if (latestCustomerOrder !== null) {
        const updateData: IInvoice = {
          ...latestCustomerOrder,
          discount: latestCustomerOrder?.discount || '',
          automatic_renewal: newRenewalValue,
        };
        const response = await dispatch(updateMembership(updateData));
        if (response) {
          enqueueSnackbar(String(translate('snackBar.updated')), {
            variant: 'success',
          });

          // if (customer_id) {
          //   dispatch(getCustomerLatestOrder(customer_id));
          // }
        } else {
          enqueueSnackbar(String(translate('snackBar.updateFailed')), { variant: 'error' });
        }
      }
    } catch (err) {
      console.log(err);
      enqueueSnackbar(String(translate('snackBar.updateFailed')), { variant: 'error' });
    }
  };

  return (
    <>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          p: 3,
          position: 'relative',
          borderRadius: '25px',
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle2" paragraph>
            {`${translate('customer.membership.autoRenewalTitle')}`}
          </Typography>
          <Typography variant="subtitle2" gutterBottom sx={{ display: 'inline', mr: 2 }}>
            {`${translate('customer.membership.autoRenewalDesc')}`}{' '}
            {latestCustomerOrder?.installments} {`${translate('memberships.periods')}`}.
          </Typography>
        </Box>

        <Stack direction={'column'} spacing={2}>
          <FormControlLabel
            label={
              switchValue
                ? String(translate('customer.membership.turnOff'))
                : String(translate('customer.membership.turnOn'))
            }
            control={
              <Switch
                aria-label={
                  switchValue
                    ? String(translate('customer.membership.turnOff'))
                    : String(translate('customer.membership.turnOn'))
                }
                value={switchValue}
                checked={switchValue}
                onChange={() => {
                  setOpen(true);
                }}
              />
            }
          />
        </Stack>
      </Card>

      <Dialog fullWidth maxWidth="md" open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {switchValue
            ? String(translate('customer.membership.youSureOff'))
            : String(translate('customer.membership.youSureOn'))}
          <IconButton
            sx={{ position: 'absolute', right: 8, top: 8 }}
            color="error"
            onClick={() => setOpen(false)}
          >
            <Iconify icon="ic:sharp-close" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2" paragraph>
            {`${translate('customer.membership.autoRenewalWarning')}`}
          </Typography>
          <Typography variant="subtitle2" paragraph>
            {`${translate('customer.membership.autoRenewalWarning2')}`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleUpdate(!switchValue);
              setSwitchValue(!switchValue);
              setOpen(false);
            }}
          >
            {switchValue
              ? String(translate('customer.membership.turnOff'))
              : String(translate('customer.membership.turnOn'))}
          </Button>
          <Button variant="contained" color="error" onClick={() => setOpen(false)}>
            {`${translate('tutor.cancel')}`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
