import {
  DialogContent,
  Dialog,
  Typography,
  DialogActions,
  Button,
  Stack,
  useTheme,
  useMediaQuery,
  IconButton,
  Theme,
} from '@mui/material';
import { useLocales } from '../../../locales';
import { useSelector } from 'src/redux/store';
import { useNavigate } from 'react-router-dom';
import Iconify from 'src/components/iconify';

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
};

export default function PopUpReminderLessonPlan({ open, setOpen }: Props) {
  const { translate } = useLocales();
  const { lesson } = useSelector((state) => state.lesson);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        sx: {
          width: '100%',
          m: isMobile ? 2 : 3,
          p: isMobile ? 2 : 3,
        },
      }}
    >
      <DialogContent>
        <IconButton
          onClick={() => setOpen(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme: Theme) => theme.palette.grey[500],
          }}
        >
          <Iconify icon="eva:close-fill" />
        </IconButton>
        <Typography variant={isMobile ? 'h4' : 'h3'} align="center" gutterBottom>
          {String(translate('calender.popUpLessonPlan'))}
        </Typography>

        <Typography variant="body2" sx={{ mb: 2 }} align="center">
          {String(translate('calender.lessonPlanDescription'))}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={2}
          width="100%"
          justifyContent="center"
        >
          {lesson && (
            <Button
              variant="contained"
              color="success"
              fullWidth={isMobile}
              endIcon={<Iconify icon="ph:arrow-right-bold" />}
              size="large"
              sx={{
                backgroundColor: theme.palette.success.main,
                color: theme.palette.success.contrastText,
                borderRadius: theme.palette.borders.borderRadius,
                boxShadow: theme.palette.borders.boxShadow,
                padding: '10px 20px',
                transition: 'all 0.3s',
                '&:hover': {
                  backgroundColor: theme.palette.success.dark,
                  boxShadow: theme.palette.borders.boxShadowHoverBlack,
                  transform: 'translateY(-2px)',
                },
                '&:active': {
                  boxShadow: 'none',
                  transform: 'translateY(0)',
                },
                ...(isMobile && {
                  marginBottom: theme.spacing(2),
                }),
              }}
              onClick={() => {
                navigate(`/lesson/${lesson.id}/details`);
                setOpen(false);
              }}
            >
              {String(translate('calender.goToLessonPlan'))}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
