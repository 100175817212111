import * as Yup from 'yup';
import merge from 'lodash/merge';
import { isBefore } from 'date-fns';

// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocales } from '../../../../locales';

// @mui
import {
  Box,
  Stack,
  Button,
  TextField,
  DialogActions,
  Grid,
  Dialog,
  Typography,
} from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';

// @types
import { IStudent } from '../../../../@types/student';
import { ICalendarLessonRequest } from '../../../../@types/lessonRequest';
import { ITutor } from '../../../../@types/tutor';

import FormProvider, { RHFAutocomplete } from '../../../../components/hook-form';
import { useState } from 'react';
// ----------------------------------------------------------------------

type FormValuesProps = ICalendarLessonRequest;

type Props = {
  admin: boolean;
  editable: boolean;
  open: boolean;
  teachers: [];
  students: [];
  range: {
    start: Date;
    end: Date;
  } | null;
  onClose: VoidFunction;
  onCreateUpdateEvent: (newEvent: ICalendarLessonRequest) => void;
};

// ----------------------------------------------------------------------

const getInitialValues = (range: { start: Date; end: Date } | null) => {
  const today = new Date();
  const initialEvent: FormValuesProps = {
    title: '',
    color: '#1890FF',
    description: '',
    status: '',
    studentId: { code: '', label: '', priority: '' },
    studentName: '',
    teacherId: { code: '', label: '', priority: '' },
    teacherName: '',
    trial_lesson: false,
    start: range
      ? new Date(range.start)
      : new Date(today.getFullYear(), today.getMonth(), today.getDate(), 16),
    end: range
      ? new Date(range.end)
      : new Date(today.getFullYear(), today.getMonth(), today.getDate(), 18),
    request: true,
    paid: false,
    completionNotes: '',
    recurring: false,
  };

  if (range) {
    return merge({}, initialEvent);
  }

  return initialEvent;
};

// ----------------------------------------------------------------------
/**
 * @component
 *
 * @param {Props} {
 * event,
 * range,
 * admin,
 * editable,
 * teachers,
 * students,
 * cancel,
 * onCreateUpdateEvent,
 * onDelete
 * onClose,
 * onCancel,
 * cancelLesson,
 *  }
 * @returns  {JSX.Element} The rendered ViewLessonForm component. This is used to view Lessons in the Calendar
 */
export default function CreatePopUpRequest({
  range,
  admin,
  editable,
  open,
  teachers,
  students,
  onCreateUpdateEvent,
  onClose,
}: Props) {
  const { translate } = useLocales();
  const [studentId, setStudentId] = useState<string | null>(null);
  const [teacherId, setTeacherId] = useState<string | null>(null);
  const [isFilled, setIsFilled] = useState(false);

  const EventSchema = Yup.object().shape({
    teacherId: Yup.object().shape({
      code: Yup.string().required(),
      label: Yup.string().required(),
    }),
    studentId: Yup.object().shape({
      code: Yup.string().required(),
      label: Yup.string().required(),
    }),
    // start:Yup.date().min(new Date(),'Lesson cannot be created earlier'),
    // end:Yup.date().min(new Date(),'Lesson cannot be created earlier'),
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues: getInitialValues(range),
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
  // console.log(isSubmitting);
  const values = watch();
  // console.log(values);

  const handleSubmitButton = () => {
    if (studentId && teacherId) {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  };

  const onSubmit = () => {
    try {
      const startDateISO = new Date(values?.start).toISOString();
      const endDateISO = new Date(values?.end).toISOString();
      const newEvent = {
        title: values?.title,
        description: values?.description,
        status: 'pending',
        color: values?.color,
        completionNotes: values?.completionNotes,
        studentId: values?.studentId,
        studentName: values?.studentName,
        teacherName: values?.teacherName,
        teacherId: values?.teacherId,
        trial_lesson: values?.trial_lesson,
        start: startDateISO,
        end: endDateISO,
        request: true,
        recurring: false,
        paid: false,
      };
      onCreateUpdateEvent(newEvent);
      onClose();
      reset();
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(values);
  /**
   * Sorts and allows the RHFSelect element to accept the data
   * @param studentsArray Initial teacher array
   * @returns a sorted list of the teachers, the array has been mapped to fit the RHFSelect elemetn
   */
  const sortArrayTeachers = (
    teachersArray: ITutor[]
  ): { code: string; label: string; priority: string }[] => {
    const arrayForSorting = teachersArray.map((value) => {
      const priority = value.students.includes(values.studentId.code);
      return {
        code: value.id.toString(),
        label: `${value.first_name} ${value.last_name}`,
        priority: priority
          ? String(translate('calender.assigned'))
          : String(translate('customer.all')),
      };
    });
    return arrayForSorting.sort((a, b) => {
      if (a.priority === String(translate('calender.assigned'))) {
        return -1;
      }
      return 1;
    });
  };
  /**
   * Sorts and allows the RHFSelect element to accept the data
   * @param studentsArray Initial Student array
   * @returns a sorted list of the students, the array has been mapped to fit the RHFSelect elemetn
   */
  const sortArrayStudents = (
    studentsArray: IStudent[]
  ): { code: string; label: string; priority: string }[] => {
    const arrayForSorting = studentsArray.map((value) => {
      const priority = value.teachers.includes(values.teacherId.code);
      return {
        code: value.id.toString(),
        label: `${value.first_name} ${value.last_name}`,
        priority: priority
          ? String(translate('calender.assigned'))
          : String(translate('customer.all')),
      };
    });
    return arrayForSorting.sort((a, b) => {
      if (a.priority === String(translate('calender.assigned'))) {
        return -1;
      }
      return 1;
    });
  };

  const isDateError = () => {
    const today = new Date();
    today.setHours(23, 59, 59);
    const monthMiddle = new Date(today.getFullYear(), today.getMonth(), 15, 23, 59, 59);
    const checkAgeEnd = new Date(values?.end?.toString() || new Date());
    const checkAgeStart = new Date(values?.start?.toString() || new Date());
    const timeDiff = (today.getTime() - checkAgeEnd.getTime()) / (60 * 60 * 1000 * 24) > 8;

    if (values.start && values.end) {
      if (admin && !values.paid) {
        return {
          value: false,
          message: 'No errors',
        };
      }

      if ((checkAgeEnd.getTime() - checkAgeStart.getTime()) / (60 * 60 * 1000) > 10) {
        return { value: true, message: `${translate('snackBar.lessonLength')}` };
      } else if (timeDiff && editable) {
        // console.log('Check');
        return {
          value: true,
          message: `${translate('snackBar.lessonChange')}`,
        };
      } else if (
        checkAgeStart.getTime() <= monthMiddle.getTime() &&
        today.getDate() > 15 &&
        !admin &&
        timeDiff
      ) {
        // console.log('Check2');
        return {
          value: true,
          message: `${translate('snackBar.lessonChange')}`,
        };
      }

      return {
        value: isBefore(new Date(values.end), new Date(values.start)),
        message: `${translate('snackBar.lessonEndMessage')}`,
      };
    }
    return { value: false, message: 'No errors' };
  };

  // console.log(stateEdit);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Dialog fullWidth open={open} onClose={onClose}>
        <Stack spacing={3} sx={{ px: 3, marginTop: '5%' }}>
          <Typography variant="h5">{`${translate('calender.requestLessonTitle')}`}</Typography>
          <RHFAutocomplete
            disableClearable
            autoComplete
            autoHighlight
            autoSelect
            required
            groupBy={(option) => option.priority}
            label={`${translate('calender.student')}`}
            name="studentId"
            options={sortArrayStudents([...students])}
            ChipProps={{ size: 'small' }}
            noOptionsText={`${translate('calender.noOptions')}`}
          />
          <RHFAutocomplete
            autoComplete
            autoHighlight
            autoSelect
            disableClearable
            required
            groupBy={(option) => option.priority}
            label={`${translate('lesson.tutorTable')}`}
            name="teacherId"
            options={sortArrayTeachers([...teachers])}
            ChipProps={{ size: 'small' }}
            noOptionsText={`${translate('calender.noOptions')}`}
          />
          {/* If lesson has been completed then show completion notes. */}

          {/* <RHFSwitch
            disabled={!!isDateError().value}
            name="trial_lesson"
            label={`${translate('calender.startMeeting')}`}
          /> */}
          <Controller
            name="start"
            control={control}
            render={({ field }) => (
              <MobileDateTimePicker
                ampm={false}
                ampmInClock={false}
                disabled={!!isDateError().value}
                {...field}
                onChange={(newValue: Date | null) => {
                  field.onChange(newValue);
                  const newDate = new Date();
                  setValue(
                    'end',
                    new Date(
                      newValue?.getFullYear() || newDate.getFullYear(),
                      newValue?.getMonth() || newDate.getMonth(),
                      newValue?.getDate() || newDate.getDate(),
                      (newValue?.getHours() || newDate.getHours()) + 2
                    )
                  );
                }}
                label={`${translate('earnings.lessonTableHeaderStartTime')}`}
                minutesStep={15}
                openTo="day"
                views={['day', 'hours', 'minutes']}
                renderInput={(params) => <TextField disabled {...params} fullWidth />}
              />
            )}
          />

          <Controller
            name="end"
            control={control}
            render={({ field }) => (
              <MobileDateTimePicker
                {...field}
                disabled={!!isDateError().value}
                ampm={false}
                ampmInClock={false}
                onChange={(newValue: Date | null) => field.onChange(newValue)}
                label={`${translate('earnings.lessonTableHeaderEndTime')}`}
                minutesStep={15}
                openTo="day"
                views={['day', 'hours', 'minutes']}
                renderInput={(params) => (
                  <TextField
                    disabled={editable}
                    {...params}
                    fullWidth
                    error={!!isDateError().value}
                    helperText={isDateError().value && isDateError().message}
                  />
                )}
              />
            )}
          />
        </Stack>

        <Box sx={{ flexGrow: 1 }} />
        <DialogActions>
          <Grid container direction="column" spacing={2}>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12}>
                <Button
                  onClick={() => onSubmit()}
                  variant="contained"
                  fullWidth
                  disabled={values.studentId.code === '' || values.teacherId.code === ''}
                >
                  {`${translate('calender.requestLessonCTA')}`}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
