import { Theme } from '@mui/material/styles';
import { TFunction } from 'i18next';

export const getMemberships = (theme: Theme, translate: TFunction) => [
  {
    title: translate('memberships.unstoppable.title'),
    icon: 'heroicons:trophy',
    code: '12',
    value: 12,
    label: translate('memberships.unstoppable.label'),
    description: translate('memberships.unstoppable.desc'),
    package_info: 'Unlimited  tutors, Unlimited  elever, Unlimited  subjects',
    duration_options: '6 perioder, 12, months, 24 months, 36 months (for extra discount)',
    standard: true,
    attentionText: translate('memberships.unstoppable.attentionText'),
    attentionTextColor: theme.palette.warning.contrastText,
    attentionBackgroundColor: theme.palette.warning.main,
  },
  {
    title: translate('memberships.driven.title'),
    icon: 'majesticons:shooting-star-line',
    code: '8',
    label: translate('memberships.driven.label'),
    description: translate('memberships.driven.desc'),
    value: 8,
    package_info: 'Unlimited  tutors, Unlimited  elever, Unlimited  subjects',
    duration_options: '6 months, 12, months, 24 months, 36 months (for extra discount)',
    standard: true,
    attentionText: translate('memberships.driven.attentionText'),
    attentionTextColor: theme.palette.success.contrastText,
    attentionBackgroundColor: theme.palette.success.main,
  },
  {
    title: translate('memberships.casual.title'),
    icon: 'material-symbols:conversion-path',
    code: '6',
    label: translate('memberships.casual.label'),
    description: translate('memberships.casual.desc'),
    value: 6,
    package_info: '- 1 tutor, 1 elev, 1 subject',
    duration_options: '6 months, 12, months, 24 months, 36 months (for extra discount)',
    standard: true,
  },
  {
    code: '4',
    value: 4,
    label: translate('memberships.justTrying.label'),
    package_info: 'Unlimited  tutors, Unlimited  elever, Unlimited  subjects',
    duration_options: '6 months, 12, months, 24 months, 36 months (for extra discount)',
    standard: false,
  },
  {
    code: '16',
    value: 16,
    label: translate('memberships.engaged.label'),
    package_info: 'Unlimited  tutors, Unlimited  elever, Unlimited  subjects',
    duration_options: '6 months, 12, months, 24 months, 36 months (for extra discount)',
    standard: false,
  },
  {
    code: '24',
    value: 24,
    label: translate('memberships.fanatic.label'),
    package_info: 'Unlimited  tutors, Unlimited  elever, Unlimited  subjects',
    duration_options: '6 months, 12, months, 24 months, 36 months (for extra discount)',
    standard: false,
  },
];

export const getMonths = (translate: TFunction) => [
  {
    code: '24',
    label: `24 ${translate('memberships.periods')}`,
    title: translate('memberships.support.title'),
    desc: translate('memberships.support.desc'),
    value: '24',
    discount: 15 / 100,
  },
  {
    code: '12',
    label: `12 ${translate('memberships.periods')}`,
    title: translate('memberships.boost.title'),
    desc: translate('memberships.boost.desc'),
    value: '12',
    discount: 10 / 100,
  },
  {
    code: '6',
    label: `6 ${translate('memberships.periods')}`,
    title: translate('memberships.brushUp.title'),
    desc: translate('memberships.brushUp.desc'),
    value: '6',
    discount: 0 / 100,
  },
];

export const month_to_price = {
  '6-months-6': 299,
  '6-months-12': 249,
  '6-months-24': 229,
  '8-months-6': 269,
  '8-months-12': 239,
  '8-months-24': 219,
  '12-months-6': 249,
  '12-months-12': 229,
  '12-months-24': 209,
};

export const non_standard_options = {
  '4-months-6': 349,
  '4-months-12': 269,
  '4-months-24': 239,
  '16-months-6': 239,
  '16-months-12': 219,
  '16-months-24': 209,
  '24-months-6': 229,
  '24-months-12': 209,
  '24-months-24': 209,
};

export const discounts = [
  { code: '5%', label: '5%', value: 95 / 100 },
  { code: '10%', label: '10%', value: 90 / 100 },
  { code: '15%', label: '15%', value: 85 / 100 },
  { code: '20%', label: '20%', value: 80 / 100 },
];
