import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
// hooks
import { useAuthContext } from './useAuthContext';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
  redirectTo?: string;
};

/**
 * GuestGuard component restricts authenticated users from accessing certain routes.
 * If the user is authenticated, they are redirected to the specified path.
 * If the authentication status is still being determined, a loading screen is displayed.
 *
 * @param {ReactNode} children - The content to display if the user is not authenticated.
 * @param {string} [redirectTo='/dashboard'] - The path to redirect to if the user is authenticated.
 *
 * @example
 * <GuestGuard redirectTo="/home">
 *   <LoginPage />
 * </GuestGuard>
 *
 * @returns {JSX.Element} - The children if the user is not authenticated, otherwise a redirect or loading screen.
 */
export default function GuestGuard({ children, redirectTo = '/dashboard' }: GuestGuardProps) {
  const { user, isAuthenticated, isInitialized } = useAuthContext();

  if (isAuthenticated) {
    return <Navigate to={user?.freemium_signup ? '/ai' : redirectTo} />;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
