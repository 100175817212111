/* follow this path to see where the inspiration for this code comes from in the Minimal_TypeScript_v4.2.0:

src/sections/@dashboard/user/list/UserTableRow
*/
import { useState } from 'react';
import useDateFormat from '../../../hooks/useDateFormat';
import { fNumber } from '../../../utils/formatNumber';
// @mui
import {
  Stack,
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Divider,
  MenuItem,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

// @types
import { ILesson } from '../../../@types/lesson';
// components
import Label from '../../../components/label';
import { CustomAvatar } from '../../../components/custom-avatar';
import Iconify from '../../../components/iconify';
import MenuPopover from '../../../components/menu-popover';

// ----------------------------------------------------------------------

type Props = {
  row: ILesson;
  isAdmin: boolean;
  selected: boolean;
  onSelectRow: VoidFunction;
  onViewRow: VoidFunction;
  onEditRow: VoidFunction;
};

export default function LessonsTableRow({
  row,
  selected,
  isAdmin,
  onViewRow,
  onSelectRow,
  onEditRow,
}: Props) {
  const {
    student,
    teacher,
    from_time,
    to_time,
    status,
    duration_in_minutes,
    wage,
    paid,
    trial_lesson,
  } = row;
  const theme = useTheme();
  const { fDate } = useDateFormat();
  const isLight = theme.palette.mode === 'light';
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };
  return (
    <>
      <TableRow hover>
        {isAdmin ? <TableCell></TableCell> : <TableCell></TableCell>}
        <TableCell>
          <Link to={`/student/${student.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <CustomAvatar src={''} alt={student?.first_name} name={student?.first_name} />
              <Typography variant="subtitle2" noWrap>
                {student.first_name} {student.last_name}
              </Typography>
            </Stack>
          </Link>
        </TableCell>
        <TableCell>
          <Link to={`/tutor/${teacher.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Stack direction="row" spacing={1}>
              <Typography variant="subtitle2">
                {teacher.first_name} {teacher.last_name}
              </Typography>
            </Stack>
          </Link>
        </TableCell>

        <TableCell align="left">{fDate(from_time, 'dd MMM yyyy HH:mm')}</TableCell>

        <TableCell align="left">{fDate(to_time, ' dd MMM yyyy HH:mm')}</TableCell>

        <TableCell align="left">
          <Label
            variant={isLight ? 'soft' : 'filled'}
            color={
              (status === 'attended' && 'success') ||
              (status === 'scheduled' && 'warning') ||
              'error'
            }
            sx={{ textTransform: 'capitalize' }}
          >
            {status}
          </Label>
        </TableCell>

        <TableCell>
          {fNumber(Math.floor(duration_in_minutes / 60))}:
          {fNumber(Math.floor(duration_in_minutes % 60))}
        </TableCell>
        <TableCell align="left">
          {trial_lesson ? (
            <Iconify icon="mdi:check" color="green" />
          ) : (
            <Iconify icon="mdi:close" color="red" />
          )}
        </TableCell>
        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            onViewRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:eye-fill" />
          View
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Edit
        </MenuItem>

        <Divider sx={{ binvoiceStyle: 'dashed' }} />
      </MenuPopover>
    </>
  );
}
