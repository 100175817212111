export const onboardingCards = {
  premium: [
    {
      title: 'onboarding.title',
      text: 'onboarding.text',
    },
    {
      title: 'onboarding.title',
      text: 'onboarding.text',
    },
    {
      title: 'onboarding.title',
      text: 'onboarding.text',
    },
    {
      title: 'onboarding.title',
      text: 'onboarding.text',
    },
    {
      title: 'onboarding.title',
      text: 'onboarding.text',
    },
    {
      title: 'onboarding.title',
      text: 'onboarding.text',
    },
  ],
  ai: [
    {
      title: 'onboarding.AITitle1',
      text: 'onboarding.AIText1',
    },
    {
      title: 'onboarding.AITitle2',
      text: 'onboarding.AIText2',
    },
    {
      title: 'onboarding.AITitle3',
      text: 'onboarding.AIText3',
    },
    {
      title: 'onboarding.AITitle4',
      text: 'onboarding.AIText4',
    },
    {
      title: 'onboarding.AITitle5',
      text: 'onboarding.AIText5',
    },
  ],
  visitor: [
    {
      title: 'onboarding.visitorTitle1',
      text: 'onboarding.visitorText1',
    },
    {
      title: 'onboarding.visitorTitle2',
      text: 'onboarding.visitorText2',
    },
    {
      title: 'onboarding.visitorTitle3',
      text: 'onboarding.visitorText3',
    },
    {
      title: 'onboarding.visitorTitle4',
      text: 'onboarding.visitorText4',
    },
    {
      title: 'onboarding.visitorTitle5',
      text: 'onboarding.visitorText5',
    },
  ],
};
