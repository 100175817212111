// @mui
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

//
import { useLocales, localesDates, LocaleKey } from '../locales'; // Import useLocales

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function LocalizationProviderWrapper({ children }: Props) {
  const { currentLang } = useLocales();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={localesDates[currentLang.value as LocaleKey]}
    >
      {children}
    </LocalizationProvider>
  );
}
