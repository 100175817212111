import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import overwriteArray from '../../utils/overwriteArray';

import { IBalanceState } from '../../@types/balance';

const initialState: IBalanceState = {
  isLoading: false,
  error: null,
  balances: { data: [], page: 0, offset: 0, total: 0 },
  balance: null,
};

const slice = createSlice({
  name: 'balances',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET All Balances
    getBalancesSuccess(state, action) {
      state.isLoading = false;
      state.balances = {
        data: overwriteArray(state.balances.data, action.payload.data),
        page: 25 / action.payload.pagination.offset,
        offset: action.payload.pagination.offset,
        total: action.payload.pagination.total,
      };
    },
    // GET Specific Balance by ID
    getBalanceSuccess(state, action) {
      state.isLoading = false;
      state.balance = action.payload.data;
      state.error = null;
    },
  },
});

export default slice.reducer;

export function getBalances(offset: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const offsetStart = offset === 0 ? 0 : offset;
      // console.log(offsetStart);
      const response = await axios.get(`/api/balance?limit=25&offset=${offset}`, {
        withCredentials: true,
      });
      // console.log(response);
      dispatch(slice.actions.getBalancesSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getBalance(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/get_balance/${id}`, {
        withCredentials: true,
      });
      if (response.status === 404) {
        throw new Error('Order not found');
      } else {
        dispatch(slice.actions.getBalanceSuccess(response));
        // console.log(response.data);
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function recalculateBalance(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `/api/recalculate_balance`,
        { balance_id: id },
        {
          withCredentials: true,
        }
      );
      if (response.status === 404) {
        throw new Error('Order not found');
      } else {
        dispatch(slice.actions.getBalanceSuccess(response));
        // console.log(response.data);
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function giveFreeHours(id: string | number, hours: number, membership: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `/api/give_free_hours`,
        { id: id, hours: hours, membership: membership },
        {
          withCredentials: true,
        }
      );
      if (response.status === 404) {
        throw new Error('Balance not found');
      } else {
        dispatch(slice.actions.getBalanceSuccess(response));
        // console.log(response.data);
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function calculateChurn(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `/api/calculate_churn`,
        { balance_id: id },
        {
          withCredentials: true,
        }
      );
      if (response.status === 404) {
        throw new Error('Order not found');
      } else {
        const objectForSetting = {
          data: {
            ...response.data.balance,
            churn_value: response.data.churn_value,
            net_invoices: response.data.net_invoices,
            net_payments: response.data.net_payments,
            membership_churn_value: response.data.membership_churn_value,
            used_hours: response.data.used_hours,
            new_price: response.data.new_price,
            old_price: response.data.old_price,
          },
        };
        dispatch(slice.actions.getBalanceSuccess(objectForSetting));
        // console.log(response.data);
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
