// @mui
import { Stack, Typography } from '@mui/material';
// components
import { useLocales } from '../../../../locales';
import Iconify from '../../../../components/iconify/Iconify';
import { ILessonPlan } from '../../../../@types/lesson';
import { fNumber } from '../../../../utils/formatNumber';

/**
 * This functional component renders the LessonDetailsPage, which displays lesson Detailss.
 *
 * @component
 * @returns {JSX.Element} The rendered LessonDetailsPage component.
 */

type Props = {
  lessonPlan: ILessonPlan | null;
};
export default function LessonPlanBox({ lessonPlan }: Props) {
  const { translate } = useLocales();

  return (
    <Stack spacing={2}>
      {/* Duration */}
      <Stack spacing={2} direction="row" sx={{ wordBreak: 'break-all', typography: 'body2' }}>
        <Iconify
          icon="ph:timer"
          width={24}
          sx={{
            flexShrink: 0,
          }}
        />
        <Typography>
          {`${fNumber(lessonPlan?.duration_in_minutes || 0)}`}{' '}
          {String(translate('genericLabels.minutes'))}
        </Typography>
      </Stack>

      {/* Subject */}
      <Stack spacing={2} direction="row" sx={{ wordBreak: 'break-all', typography: 'body2' }}>
        <Iconify
          icon="ph:books"
          width={24}
          sx={{
            flexShrink: 0,
          }}
        />
        <Typography>{`${lessonPlan?.subject}`}</Typography>
      </Stack>

      <Stack spacing={2} direction="row" sx={{ wordBreak: 'break-all', typography: 'body2' }}>
        <Iconify
          icon="ph:backpack"
          width={24}
          sx={{
            flexShrink: 0,
          }}
        />
        <Typography>{`${lessonPlan?.grade}`}</Typography>
      </Stack>
    </Stack>
  );
}
