import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import overwriteArray from '../../utils/overwriteArray';

import { ICustomerState, ICustomer } from '../../@types/customer';

const initialState: ICustomerState = {
  isLoading: false,
  error: null,
  customers: { data: [], total: 0, offset: 0 },
  periods: [],
  latestCustomerOrder: null,
  latestNonMembershipOrder: null,
  customer: null,
  activeCustomerEmails: [],
};

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload; // if you only write action.payload, you do not dot-in to the actual data, where all data for customer is
    },
    // GET Customer
    getCustomersSuccess(state, action) {
      state.error = null;
      state.isLoading = false;
      state.customers = {
        data: overwriteArray(state.customers.data, action.payload.data),
        total: action.payload.pagination.total,
        offset: action.payload.pagination.offset,
      };
    },
    getPeriodsSuccess(state, action) {
      state.error = null;
      state.isLoading = false;
      state.periods = [...action.payload.data.periods];
    },
    // GET Customer
    getCustomerSuccess(state, action) {
      state.error = null;
      state.isLoading = false;
      state.customer = action.payload.data;
    },
    // GET Customer Latest Order
    getCustomerLatestOrderSuccess(state, action) {
      state.error = null;
      state.isLoading = false;
      state.latestCustomerOrder = action.payload.data;
    },
    getCustomerNonMembershipOrder(state, action) {
      state.error = null;
      state.isLoading = false;
      state.latestNonMembershipOrder = action.payload.data;
    },
    //GET CUSTOMER EMAILS ACTIVE
    getActiveCustomerEmailsSuccess(state, action) {
      state.isLoading = false;
      state.activeCustomerEmails = action.payload;
      state.error = null;
    },

    // UPDATE CUSTOMER
    updateCustomerSuccess(state, action) {
      state.error = null;
      state.isLoading = false;
      state.customer = action.payload.data;
      state.customers = {
        ...state.customers,
        data: state.customers.data.map((customer) => {
          if (parseInt(customer.id || '') === parseInt(action.payload.id)) {
            return action.payload;
          }
          return customer;
        }),
      };
    },
    // CREATE CUSTOMER
    createCustomerSuccess(state, action) {
      state.error = null;
      state.isLoading = false;
      state.customer = action.payload.data;
      state.customers = {
        ...state.customers,
        total: state.customers.total + 1,
        data: [action.payload.data, ...state.customers.data],
      };
    },
    // DELETE CUSTOMER
    deleteCustomerSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.customer = null;
    },
    assignTeachersSuccess(state, action) {
      state.isLoading = false;
      if (state.customer) state.customer.students = action.payload;
      state.error = null;
    },
  },
});
export const { getCustomerLatestOrderSuccess, getCustomerSuccess } = slice.actions;
export default slice.reducer;

export function getCustomers(status: string | undefined, customer_type: string | undefined) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    const query_status = status ? `status=${status}` : '';
    const query_customer_type = customer_type ? `customer_type=${customer_type}` : '';
    try {
      const response = await axios.get(`/api/customers?${query_status}&${query_customer_type}`, {
        withCredentials: true,
      });
      // console.log(response);
      dispatch(slice.actions.getCustomersSuccess(response.data));
      return true;
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }

      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getActiveCustomerEmails() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/customers_active_email');
      dispatch(slice.actions.getActiveCustomerEmailsSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function getCustomerUnprotected(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/customer_quick/${id}`);
      if (response.status === 404) {
        throw new Error('Customer not found');
      } else {
        dispatch(slice.actions.getCustomerSuccess(response));
        return true;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }

      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function getCustomer(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/customer/${id}`);
      if (response.status === 404) {
        throw new Error('Customer not found');
      } else {
        dispatch(slice.actions.getCustomerSuccess(response));
        return true;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }

      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function getLatestNonMembershipOrder(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/latest_nonmembership_order/${id}`);
      if (response.status === 404) {
        throw new Error('Customer not found');
      } else {
        // console.log(response);
        dispatch(slice.actions.getCustomerNonMembershipOrder(response));
        return true;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }

      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function getCustomerLatestOrder(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/latest_customer_order/${id}`);
      if (response.status === 404) {
        throw new Error('Customer not found');
      } else {
        dispatch(slice.actions.getCustomerLatestOrderSuccess(response));
        return true;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }

      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function getPeriods(id: string | undefined) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.get(`/api/get_periods_customer/${id}`);
      if (response.status === 200) {
        dispatch(slice.actions.getPeriodsSuccess(response));
      } else {
        throw new Error('Customer not found');
      }
      return true;
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function deleteCustomer(id: string | undefined) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/customer/${id}`);

      if (response.status === 404) {
        throw new Error('Customer not found');
      } else {
        dispatch(slice.actions.deleteCustomerSuccess({}));
        return true;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
/**
 * Method that creates a new customer and a student attached depending if it is family type
 * @param form ICustomer object with both customer and student data depending on the type
 * @param customerCreationType type of customer independent or family
 * @returns returns true or false depending on the success or failure of the operation
 */
export function createCustomer(
  form: ICustomer | any,
  customerCreationType: string,
  send_email: boolean
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      // console.log(form);
      if (customerCreationType === 'independent') {
        const submitForm = {
          email: form.email,
          first_name: form.first_name,
          last_name: form.last_name,
          phone: form.phone,
          send_email: send_email,
        };
        const response = await axios.post(
          `/api/independent`,
          {
            ...submitForm,
          },
          { withCredentials: true }
        );
        dispatch(slice.actions.createCustomerSuccess(response));
        return response.data.id;
      } else if (customerCreationType === 'family' && form?.student_email === '') {
        const submitForm = {
          email: form.email,
          first_name: form.first_name,
          last_name: form.last_name,
          student_first_name: form.student_first_name,
          student_last_name: form.student_last_name,
          gender: 'male',
          phone: form.phone,
          send_email,
        };
        const response = await axios.post(
          `/api/family_with_student`,
          {
            ...submitForm,
            send_email: send_email,
          },
          { withCredentials: true }
        );
        dispatch(slice.actions.createCustomerSuccess(response));
        return response.data.id;
      } else {
        const customerForm = {
          email: form.email,
          first_name: form.first_name,
          last_name: form.last_name,
          phone: form.phone,
          send_email: send_email,
        };
        const studentForm = {
          email: form.student_email,
          first_name: form.student_first_name,
          last_name: form.student_last_name,
          gender: 'male',
          phone: form.student_phone,
          send_email: send_email,
        };
        const responseCustomer = await axios.post(
          `/api/family`,
          {
            ...customerForm,
          },
          { withCredentials: true }
        );
        const responseStudent = await axios.post(
          `/api/student_with_user`,
          {
            ...studentForm,
            id: responseCustomer.data.id,
          },
          { withCredentials: true }
        );
        dispatch(slice.actions.createCustomerSuccess(responseCustomer));
        return responseCustomer.data.id;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function changeStatus(
  old_email: string,
  email: string,
  first_name: string,
  last_name: string,
  new_user: boolean,
  send_email: boolean,
  phone: string,
  conversionType: boolean
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    /**
     * conversion_type is either to_student or to_customer
     * to_student means we convert the current user
     * into a student and create a new user account for the customer.
     *
     * to_customer means we convert the current user into the customer
     * and create a new student(with optional user account).
     */
    const conversion_type = conversionType ? 'to_customer' : 'to_student';
    try {
      const response = await axios.post(
        '/api/convert_to_family_student',
        {
          old_email: old_email,
          email: email,
          first_name: first_name,
          last_name: last_name,
          new_user: new_user,
          send_email: send_email,
          phone: phone,
          conversion_type: conversion_type,
        },
        { withCredentials: true }
      );
      if (response.status == 201) {
        dispatch(slice.actions.updateCustomerSuccess(response));
        // console.log(response);
        return true;
      } else {
        dispatch(slice.actions.hasError('Failure to convert to family'));
        return false; // return false on error
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }

      dispatch(slice.actions.hasError(errorMessage));
      return false; // return false on error
    }
  };
}
export function editCustomer(form: Partial<ICustomer>) {
  const data_to_update_user = {
    id: form.id,
    email: form.email,
    first_name: form.first_name,
    last_name: form.last_name,
    phone: form.phone,
    email_lesson_reminder: form.email_lesson_reminders,
    email_lesson_notes: form.email_lesson_notes,
    status: form.status,
    had_trial_lesson: form.had_trial_lesson,
    consent_recordings: form.consent_recordings,
    first_time_consent_recording: form.first_time_consent_recording,
  };

  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/customer`, {
        ...data_to_update_user,
      });
      dispatch(slice.actions.updateCustomerSuccess(response));
      return true; // Return true on success
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function churnCustomer(form: Partial<ICustomer>, override: boolean, voidOverride: boolean) {
  const data_to_update_user = {
    id: form.id,
    override: override,
    voidOverride: voidOverride,
  };

  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/churn_customer`, {
        ...data_to_update_user,
      });
      dispatch(slice.actions.updateCustomerSuccess(response));
      return true; // Return true on success
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
