export const cancellations = [
  { code: 'good cancellation', key: 'goodCancellation', value: 'good cancellation' },
  {
    code: 'bad cancellation student',
    key: 'lateCancellationStudent',
    value: 'bad cancellation student',
  },
  {
    code: 'bad cancellation teacher',
    key: 'lateCancellationTeacher',
    value: 'bad cancellation teacher',
  },
];
