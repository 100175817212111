import keyBy from 'lodash/keyBy';
import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// @types
import { INotificationState, INotificationItem } from '../../@types/notification';

const initialState: INotificationState = {
  isLoading: false,
  error: null,
  notifications: { byId: {}, allIds: [] },
};

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Mark all notifications as read
    markAllNotificationsAsReadSuccess(state) {
      state.notifications.allIds.forEach((id) => {
        const notification = state.notifications.byId[id];
        if (notification) {
          notification.is_unread = false;
        }
      });
    },

    // Mark a single notification as read
    markNotificationAsReadSuccess(state, action) {
      const notificationId = action.payload;
      const notification = state.notifications.byId[notificationId];
      if (notification) {
        notification.is_unread = false;
      }
    },

    // GET NOTIFICATIONS SUCCESS
    getNotificationSuccess(state, action) {
      const notifications = action.payload;
      state.notifications.byId = keyBy(notifications, 'id');
      state.notifications.allIds = notifications.map(
        (notification: INotificationItem) => notification.id
      );
    },
  },
});

// Actions
//export const { markAllNotificationsAsRead, markNotificationAsRead } = slice.actions;

// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------

export function markNotificationAsRead(notification_id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        '/api/mark_notification_as_read',
        {
          notification_id: notification_id,
        },
        { withCredentials: true }
      );
      dispatch(slice.actions.markNotificationAsReadSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function markAllNotificationsAsRead(uid: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        '/api/mark_notifications_as_read',
        {
          uid: uid,
        },
        { withCredentials: true }
      );
      if (response) {
        dispatch(slice.actions.markAllNotificationsAsReadSuccess());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications(uid: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/notifications', {
        params: { uid }, // roles are included as query parameters
        withCredentials: true,
      });
      dispatch(slice.actions.getNotificationSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
