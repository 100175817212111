import * as Yup from 'yup';
import merge from 'lodash/merge';
import { isBefore } from 'date-fns';
import { EventInput } from '@fullcalendar/core';

// redux

// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Stack, Button, Tooltip, TextField, DialogActions, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MobileDateTimePicker } from '@mui/x-date-pickers';

import { useLocales } from '../../../locales';

// @types
import { IStudent } from '../../../@types/student';
import { ICalendarEvent } from '../../../@types/calendar';
import { ITutor } from '../../../@types/tutor';
// components
import Iconify from '../../../components/iconify';
import PopUpReminderLessonPlan from './PopUpReminderLessonPlan';
import FormProvider, {
  RHFTextField,
  RHFSwitch,
  RHFAutocomplete,
} from '../../../components/hook-form';
import { useAuthContext } from '../../../auth/useAuthContext';
import { AuthUserType } from '../../../auth/types';
import { getEmoji } from '../../../utils/utils';
// ----------------------------------------------------------------------
type FormValuesProps = ICalendarEvent;

type Props = {
  event: EventInput | null | undefined;
  admin: boolean;
  teachers: [];
  students: [];
  range: {
    start: Date;
    end: Date;
  } | null;
  onCancel: VoidFunction;
  onCreateUpdateEvent: (newEvent: ICalendarEvent) => void;
  nextLesson: boolean;
};
const today = new Date();
// ----------------------------------------------------------------------
/**
 * This function returns the initial values for the form based on provided event or range.
 *
 * @function getInitialValues
 * @param {EventInput | null | undefined} event - The event input data.
 * @param {{ start: Date; end: Date } | null} range - The date range for the event.
 * @returns {FormValuesProps} An object containing the initial form values.
 */
const getInitialValues = (
  event: EventInput | null | undefined,
  range: { start: Date; end: Date } | null,
  user: AuthUserType,
  teachers: ITutor[]
) => {
  // console.log(user);
  const teacherId =
    user?.teacher && teachers.length
      ? {
          code: teachers[0].id,
          label: `${user.first_name} ${user.last_name}`,
          priority: 'Assigned',
        }
      : { code: '', label: '', priority: '' };

  const initialEvent: FormValuesProps = {
    title: '',
    title_data: '',
    description: '',
    completionNotes: '',
    color: '#1890FF',
    paid: false,
    allDay: false,
    trial_lesson: false,
    recurring: false,
    studentId: { code: '', label: '', priority: '' },
    studentName: '',
    teacherName: teacherId.label,
    teacherId: teacherId,
    start: range
      ? new Date(range.start)
      : new Date(today.getFullYear(), today.getMonth(), today.getDate(), 16),
    end: range
      ? new Date(range.end)
      : new Date(today.getFullYear(), today.getMonth(), today.getDate(), 18),
  };

  if (event || range) {
    return merge({}, initialEvent, event);
  }
  // console.log(event);
  // console.log(initialEvent);
  return initialEvent;
};

// Use effect get list of students if we don't already have it.
// If calendar.students is empty make dispatch to get students
// Dispatching actions to the Redux store

// ----------------------------------------------------------------------
/**
 * This functional component renders a calendar form with various input fields to create or update events.
 *
 * @component
 * @param {Props} {
 *   event,
 *   teachers,
 *   students,
 *   range,
 *   admin,
 *   onCreateUpdateEvent,
 *   onCancel,
 * } - Props required for the CalendarForm component.
 * @returns {JSX.Element} The rendered CalendarForm component.
 */
export default function CalendarForm({
  event,
  teachers,
  students,
  range,
  admin,
  onCreateUpdateEvent,
  onCancel,
  nextLesson,
}: Props) {
  const hasEventData = !!event;

  const { translate } = useLocales();
  const theme = useTheme();

  const EventSchema = Yup.object().shape({
    title_data: Yup.string()
      .min(2, `${translate('lesson.titleData')}`)
      .max(255)
      .required(`${translate('lesson.titleData')}`),
    description: Yup.string().max(5000).nullable(true),
    teacherId: Yup.object().shape({
      code: Yup.string().required('Error'),
      label: Yup.string().required('Error'),
    }),
    studentId: Yup.object().shape({
      code: Yup.string().required(),
      label: Yup.string().required(),
    }),
    // start:Yup.date().min(new Date(),'Lesson cannot be created earlier'),
    // end:Yup.date().min(new Date(),'Lesson cannot be created earlier')
  });
  const { user } = useAuthContext();
  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues: getInitialValues(event, range, user, teachers),
  });
  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();
  // console.log(values);

  /**
   * This function sorts an array of teachers based on priority.
   *
   * @function sortArrayTeachers
   * @param {ITutor[]} teachersArray - The array of teachers to be sorted.
   * @returns {Array<{ code: string; label: string; priority: string }>} A sorted array of teachers with priority info.
   */
  const sortArrayTeachers = (
    teachersArray: ITutor[]
  ): { code: string; label: string; priority: string }[] => {
    const arrayForSorting = teachersArray.map((value) => {
      /** for some reason students array gets turned from string[] to int[] .map is to convert it to the correct format */ const priority =
        value.students.map(String).includes(values.studentId.code);
      return {
        code: value.id.toString(),
        label: `${value.first_name} ${value.last_name}`,
        priority: priority ? `${translate('calender.assigned')}` : `${translate('calender.all')}`,
      };
    });
    return arrayForSorting.sort((a, b) => {
      if (a.priority === `${translate('calender.assigned')}`) {
        return -1;
      }
      return 1;
    });
  };
  /**
   * This function sorts an array of students based on priority.
   *
   * @function sortArrayStudents
   * @param {IStudent[]} studentsArray - The array of students to be sorted.
   * @returns {Array<{ code: string; label: string; priority: string }>} A sorted array of students with priority info.
   */
  const sortArrayStudents = (
    studentsArray: IStudent[]
  ): { code: string; label: string; priority: string }[] => {
    const arrayForSorting = studentsArray.map((value) => {
      const priority = value.teachers.includes(values.teacherId.code);
      return {
        code: value.id.toString(),
        label: `${value.first_name} ${value.last_name}`,
        priority: priority ? `${translate('calender.assigned')}` : `${translate('calender.all')}`,
      };
    });
    return arrayForSorting.sort((a, b) => {
      if (a.priority === `${translate('calender.assigned')}`) {
        return -1;
      }
      return 1;
    });
  };

  /**
   * This function handles form submission by creating or updating events.
   *
   * @function onSubmit
   * @param {FormValuesProps} data - The form values to be submitted.
   */
  const onSubmit = async (data: FormValuesProps) => {
    try {
      // console.log('test');
      const startDateISO = new Date(data?.start || new Date()).toISOString();
      const endDateISO = new Date(data?.end || new Date()).toISOString();
      const newEvent = {
        title: data.title_data,
        title_data: data.title_data,
        description: data.description,
        trial_lesson: data.trial_lesson,
        color: data.color,
        paid: data.paid,
        studentId: data.studentId,
        studentName: data.studentName,
        teacherName: data.teacherName,
        completionNotes: data.completionNotes,
        teacherId: data.teacherId,
        start: startDateISO,
        end: endDateISO,
        space: '',
        recurring: data.recurring,
      };
      onCreateUpdateEvent(newEvent);
      onCancel();
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * This function checks for date-related errors in the form.
   *
   * @function isDateError
   * @returns {{ value: boolean; message: string }} An object containing the error status and message.
   */
  const isDateError = () => {
    const today = new Date();
    const monthMiddle = new Date(today.getFullYear(), today.getMonth(), 15, 23, 59, 59);
    const checkAgeEnd = new Date(values?.end?.toString() || new Date());
    const checkAgeStart = new Date(values?.start?.toString() || new Date());
    const timeDiff = (today.getTime() - checkAgeEnd.getTime()) / (60 * 60 * 1000 * 24) > 7;
    const editable = !(user?.teacher || user?.admin);
    if (values.start && values.end) {
      if (admin && !values.paid) {
        return {
          value: false,
          message: 'No errors',
        };
      }
      if ((checkAgeEnd.getTime() - checkAgeStart.getTime()) / (60 * 60 * 1000) > 10) {
        return { value: true, message: `${translate('snackBar.lessonLength')}` };
      } else if (timeDiff && editable) {
        // console.log('Check');
        return {
          value: true,
          message: `${translate('snackBar.lessonChange')}`,
        };
      } else if (
        checkAgeStart.getTime() <= monthMiddle.getTime() &&
        today.getDate() > 15 &&
        !admin &&
        timeDiff
      ) {
        // console.log('Check2');
        return {
          value: true,
          message: `${translate('snackBar.lessonChange')}`,
        };
      }
      return {
        value: isBefore(new Date(values.end), new Date(values.start)),
        message: `${translate('snackBar.lessonEndMessage')}`,
      };
    }
    return { value: false, message: 'No errors' };
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ px: 3 }}>
        <RHFAutocomplete
          disableClearable
          required
          groupBy={(option) => option.priority}
          name="studentId"
          label={`${translate('calender.student')}`}
          options={sortArrayStudents([...students])}
          ChipProps={{ size: 'small' }}
          onSelect={(event) => {
            const emoji = getEmoji();
            setValue('title_data', `${(event.target as HTMLInputElement).value} ${emoji}`);
          }}
          noOptionsText={`${translate('calender.noOptions')}`}
        />

        <RHFSwitch name="trial_lesson" label={`${translate('calender.startMeeting')}`} />

        <RHFTextField
          disabled={values?.studentId && values?.studentId?.code === ''}
          name="title_data"
          label={`${translate('calender.title')}`}
        />

        <RHFTextField
          disabled={values?.studentId && values?.studentId.code === ''}
          name="description"
          label={`${translate('calender.description')}`}
          multiline
          rows={3}
        />
        {user?.teacher ? (
          <></>
        ) : (
          <RHFAutocomplete
            disableClearable
            required={!user?.teacher}
            groupBy={(option) => option.priority}
            label={`${translate('genericLabels.tutor')}`}
            name="teacherId"
            options={sortArrayTeachers([...teachers])}
            ChipProps={{ size: 'small' }}
            noOptionsText={`${translate('calender.noOptions')}`}
          />
        )}

        <Controller
          name="start"
          control={control}
          render={({ field }) => (
            <MobileDateTimePicker
              {...field}
              ampm={false}
              ampmInClock={false}
              onChange={(newValue: Date | null) => {
                field.onChange(newValue);
                const newDate = new Date();
                setValue(
                  'end',
                  new Date(
                    newValue?.getFullYear() || newDate.getFullYear(),
                    newValue?.getMonth() || newDate.getMonth(),
                    newValue?.getDate() || newDate.getDate(),
                    (newValue?.getHours() || newDate.getHours()) + 2
                  )
                );
              }}
              label={`${translate('calender.startTime')}`}
              openTo="day"
              views={['day', 'hours', 'minutes']}
              minutesStep={15}
              renderInput={(params) => (
                <TextField
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  {...params}
                  fullWidth
                />
              )}
            />
          )}
        />

        <Controller
          name="end"
          control={control}
          render={({ field }) => (
            <MobileDateTimePicker
              {...field}
              ampm={false}
              ampmInClock={false}
              onChange={(newValue: Date | null) => field.onChange(newValue)}
              label={`${translate('calender.endTime')}`}
              minutesStep={15}
              openTo="day"
              views={['day', 'hours', 'minutes']}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  error={!!isDateError().value}
                  helperText={isDateError().value && isDateError().message}
                />
              )}
            />
          )}
        />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <RHFSwitch name="recurring" label={`${translate('lesson.planLesson')}`} />
          <Tooltip title={`${translate('lesson.planLessonMessage')}`}>
            <Iconify icon="ic:outline-info"></Iconify>
          </Tooltip>
        </Box>
      </Stack>

      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />

        <Button
          variant="outlined"
          color="error"
          onClick={onCancel}
          size="large"
          startIcon={<Iconify icon="ph:x" />}
        >
          {`${translate('tutor.cancel')}`}
        </Button>

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          color="success"
          style={{
            border: theme.palette.borders.border,
            boxShadow: theme.palette.borders.boxShadow,
            borderRadius: theme.palette.borders.borderRadius,
          }}
          loading={isSubmitting}
          startIcon={<Iconify icon="ph:calendar-star" />}
        >
          {hasEventData
            ? `${translate('tutor.update')}`
            : nextLesson
            ? `${translate('calender.planLessonAndRegister')}`
            : `${translate('calender.planLesson')}`}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
