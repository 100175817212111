// @mui
import { Theme } from '@mui/material/styles';
import { Box, SxProps, TablePagination, TablePaginationProps } from '@mui/material';
import { useLocales } from '../../locales';

//

// ----------------------------------------------------------------------

type Props = {
  dense?: boolean;
  onChangeDense?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps<Theme>;
};

export default function TablePaginationCustom({
  dense,
  onChangeDense,
  rowsPerPageOptions = [5, 10, 25],
  sx,
  ...other
}: Props & TablePaginationProps) {
  const { translate } = useLocales();

  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        labelRowsPerPage={String(translate('genericLabels.rowsPerPage'))}
        component="div"
        {...other}
      />
    </Box>
  );
}
