import { useState } from 'react';
// @mui
import { Stack, Button, Typography, IconButton, MenuItem, useTheme } from '@mui/material';
// utils
// hooks
import useResponsive from '../../../hooks/useResponsive';
// @types
import { ICalendarViewValue } from '../../../@types/calendar';
// components
import Iconify from '../../../components/iconify';
import MenuPopover from '../../../components/menu-popover';
import { useLocales } from '../../../locales';
import useDateFormat from '../../../hooks/useDateFormat';

// ----------------------------------------------------------------------

type Props = {
  isAdmin: boolean;
  date: Date;
  view: ICalendarViewValue;
  onToday: VoidFunction;
  onNextDate: VoidFunction;
  onPrevDate: VoidFunction;
  onOpenFilter: VoidFunction;
  onChangeView: (newView: ICalendarViewValue) => void;
};

export default function CalendarToolbar({
  isAdmin,
  date,
  view,
  onToday,
  onNextDate,
  onPrevDate,
  onChangeView,
  onOpenFilter,
}: Props) {
  const { translate } = useLocales();

  const { fDate } = useDateFormat();
  const theme = useTheme();

  const VIEW_OPTIONS = [
    {
      value: 'dayGridMonth',
      label: String(translate('genericLabels.month')),
      icon: 'ic:round-view-module',
    },
    {
      value: 'timeGridWeek',
      label: String(translate('genericLabels.week')),
      icon: 'ic:round-view-week',
    },
    {
      value: 'timeGridDay',
      label: String(translate('genericLabels.day')),
      icon: 'ic:round-view-day',
    },
    {
      value: 'listWeek',
      label: String(translate('genericLabels.agenda')),
      icon: 'ic:round-view-agenda',
    },
  ] as const;

  const isDesktop = useResponsive('up', 'sm');

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const selectedItem = VIEW_OPTIONS.filter((item) => item.value === view)[0];

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 2.5, pr: 2 }}
      >
        {isDesktop && (
          <Button
            color="inherit"
            onClick={handleOpenPopover}
            startIcon={<Iconify icon={selectedItem.icon} />}
            endIcon={<Iconify icon="eva:chevron-down-fill" />}
            sx={{
              py: 0.5,
              pl: 1.5,
              bgcolor: 'action.selected',
              '& .MuiButton-endIcon': { ml: 0.5 },
            }}
          >
            {selectedItem.label}
          </Button>
        )}

        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton onClick={onPrevDate}>
            <Iconify icon="eva:arrow-ios-back-fill" />
          </IconButton>

          <Typography variant="h6">{fDate(date, 'dd MMM yy')}</Typography>

          <IconButton onClick={onNextDate}>
            <Iconify icon="eva:arrow-ios-forward-fill" />
          </IconButton>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          <Button
            size="small"
            startIcon={<Iconify icon="ph:calendar-dot" />}
            style={{
              border: theme.palette.borders.border,
              boxShadow: theme.palette.borders.boxShadow,
              borderRadius: theme.palette.borders.borderRadius,
            }}
            variant="contained"
            onClick={onToday}
          >
            {String(translate('genericLabels.today'))}
          </Button>
          {isAdmin ? (
            <IconButton onClick={onOpenFilter}>
              <Iconify icon="ic:round-filter-list" />
            </IconButton>
          ) : (
            <></>
          )}
        </Stack>
      </Stack>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="top-left"
        sx={{ width: 160 }}
      >
        {VIEW_OPTIONS.map((viewOption) => (
          <MenuItem
            key={viewOption.value}
            onClick={() => {
              handleClosePopover();
              onChangeView(viewOption.value);
            }}
            sx={{
              ...(viewOption.value === view && {
                bgcolor: 'action.selected',
              }),
            }}
          >
            <Iconify icon={viewOption.icon} />
            {viewOption.label}
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  );
}
