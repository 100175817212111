// imports
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// components
import CompleteLessonDialong from '../../../sections/@dashboard/lessons/CompleteLessonDialog';
import { CancelLessonDialog } from '../../../sections/@dashboard/calendar/CancelLessonDialog';

import { useSnackbar } from '../../../components/snackbar';

// sections
import { useAuthContext } from '../../../auth/useAuthContext';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { getLesson } from '../../../redux/slices/lesson';
import { completeLesson, createEvent, cancellationLesson } from '../../../redux/slices/calendar';
import { getLessonUrlStudent, getLessonUrlTeacher } from '../../../utils/services';
import { ICalendarEvent } from '../../../@types/calendar';
import { useLocales } from '../../../locales';

// Define PropTypes for the component
type Props = {
  setUrlForLesson: (value: string) => void;
  goBackStudent: (value: boolean) => void;
  setLessonId: (value: string) => void;
  urlForLesson: string;
  setRedirectionUrl: (value: string) => void;
  finishLesson: boolean;
  openCancelDialog: boolean;
  handleCancelModal: (value: boolean) => void;
  setFinishLesson: (value: boolean) => void;
  setTypeUser: (value: string) => void;
};

/**
 * The LessonPage component handles the rendering of individual lessons, allowing
 * teachers to complete lessons, and students to access them. It sets the URL for the lesson
 * based on the user type (teacher or student), manages redirection after the lesson completion,
 * and handles fetching and displaying the lesson's content.
 *
 * @component
 * @param {Props} {
 *   setUrlForLesson,
 *   urlForLesson,
 *  handleCancelModal,
 *  openCancelDialog
 *   setRedirectionUrl,
 *   finishLesson,
 *   goBackStudent,
 *   setTypeUser
 *   setFinishLesson,
 * } - The component props.
 * @returns {JSX.Element} The rendered LessonPage component.
 */
export default function LessonPage({
  openCancelDialog,
  setLessonId,
  handleCancelModal,
  setUrlForLesson,
  urlForLesson,
  setRedirectionUrl,
  setTypeUser,
  finishLesson,
  goBackStudent,
  setFinishLesson,
}: Props) {
  const { user } = useAuthContext();
  const { translate } = useLocales();

  const { id } = useParams();
  // console.log('Params:', params);

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  // Get lesson and calendar data from the store
  const { lesson } = useSelector((state) => state.lesson);
  const { teachers, students, error } = useSelector((state) => state.calendar);

  // Handles everything related to completing the lesson.
  const sendLessonCompletion = async (completionNotes: string) => {
    const response = await dispatch(
      completeLesson(user?.admin, parseInt(lesson?.id || '', 10), completionNotes)
    );

    if (response) {
      enqueueSnackbar(`${translate('snackBar.registered')}`, { variant: 'success' });
      // After the lesson completion we update the lesson data, so it includes the added completion data.
      if (user && id !== undefined) {
        dispatch(getLesson(id));
      }
      setTimeout(() => {
        window.location.replace('/calendar');
      }, 1000);
    } else {
      if (typeof error === 'string' && error) {
        // Call enqueueSnackbar only if error is a string and not empty
        enqueueSnackbar(error, { variant: 'error' });
      } else {
        // Handle the case when error is not a string or is empty/null
        enqueueSnackbar(`${translate('snackBar.lesson')}`, { variant: 'error' });
      }
    }
  };
  // Creates a new lesson if they choose to plan it right away.

  const scheduleLesson = async (newEvent: ICalendarEvent) => {
    const response = await dispatch(createEvent(newEvent));
  };

  /**
   * Handles lesson cancellation based on the provided reason.
   * @param {string} reason - The reason for cancelling the lesson.
   */
  const cancelLesson = async (reason: string) => {
    try {
      const today = new Date();
      today.setHours(23, 59, 59);
      const monthMiddle = new Date(today.getFullYear(), today.getMonth(), 15, 23, 59, 59);
      const checkAgeEnd = new Date(lesson?.to_time?.toString() || new Date());
      const checkAgeStart = new Date(lesson?.from_time?.toString() || new Date());
      if (!user?.admin || !lesson?.paid) {
        if ((today.getTime() - checkAgeEnd.getTime()) / (60 * 60 * 24 * 1000) > 8) {
          throw Error('Lesson is older than 7 days');
        } else if (lesson?.paid) {
          throw Error('Paid lesson cannot be edited');
        } else if (checkAgeStart.getTime() <= monthMiddle.getTime() && today.getDate() > 15) {
          throw Error('Lesson cannot be edited in the previous wage month');
        }
      }

      // const cancellationType:string=checkCancellationTime(startTime,reason)
      const response = await dispatch(cancellationLesson(lesson?.id || '', reason, user?.roles));
      if (response) {
        handleCancelModal(false);
        enqueueSnackbar(`${translate('snackBar.lessonUpdated')}`);

        setTimeout(() => {
          window.location.replace('/calendar');
        }, 1000);
      } else {
        enqueueSnackbar(`${translate('snackBar.lessonNotUpdated')}`, {
          variant: 'error',
        });
      }
    } catch (err) {
      enqueueSnackbar(`${translate('snackBar.lessonNotUpdated')}`, {
        variant: 'error',
      });
    }
  };
  // console.log(id);
  // Fetch lesson information, set the lesson URL, and handle errors when the component mounts or updates.
  useEffect(() => {
    if (user && id !== undefined) {
      dispatch(getLesson(id));
      setLessonId(id);
    }
    if (setTypeUser !== undefined) {
      if (user?.teacher || user?.admin) {
        setTypeUser('teacher');
      } else if (user?.student) {
        setTypeUser('student');
      }
    }
    if ((user?.teacher || user?.admin) && id !== undefined) {
      getLessonUrlTeacher(parseInt(id || '', 10), setUrlForLesson);
      goBackStudent(false);
      setRedirectionUrl('/calendar');
    } else if ((user?.customer || user?.student) && id !== undefined) {
      goBackStudent(true);
      getLessonUrlStudent(parseInt(id || '', 10), setUrlForLesson);
      setRedirectionUrl('/dashboard');
    }
    if (error) {
      enqueueSnackbar(`${translate('snackBar.updatePage')}`, { variant: 'error' });
    }
  }, [user, id, dispatch, error, enqueueSnackbar]);

  // console.log('ID' + id);

  useEffect(() => {
    if (id === undefined) {
      // console.log('ID is now undefined!');
    }
  }, [id]);

  if (lesson?.status === 'scheduled' || lesson?.status === 'attended') {
    return (
      <>
        {user?.teacher || user?.admin ? (
          <>
            <CompleteLessonDialong
              error={error}
              handleCreateUpdateEvent={scheduleLesson}
              teachers={teachers}
              students={students}
              finishLessson={sendLessonCompletion}
              cancel={finishLesson}
              admin={false}
              onCancel={setFinishLesson}
            />
            <CancelLessonDialog
              teacher={user?.teacher}
              admin={user?.admin}
              cancel={openCancelDialog}
              onCancel={handleCancelModal}
              cancelLesson={cancelLesson}
            />
          </>
        ) : (
          <></>
        )}
        <iframe
          id="iframe"
          width="100%"
          height="100%"
          title="Lesson"
          src={urlForLesson}
          allow="camera; microphone; display-capture;fullscreen;"
        />
      </>
    );
  }
  return <></>;
}
