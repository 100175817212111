import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { IParticipantState } from '../../@types/participant';

const initialState: IParticipantState = {
  isLoading: false,
  error: null,
  participants: [],
  participant: null,
};

const participantSlice = createSlice({
  name: 'participant',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getParticipantSuccess(state, action) {
      state.isLoading = false;
      state.participant = action.payload;
    },
    getParticipantsSuccess(state, action) {
      state.isLoading = false;
      state.participants = action.payload;
    },
    createParticipantSuccess(state, action) {
      state.isLoading = false;
      state.participant = action.payload;
    },
  },
});

export default participantSlice.reducer;

export function getParticipant(id: string) {
  // Gets participant by user ID.
  return async (dispatch: Dispatch) => {
    dispatch(participantSlice.actions.startLoading());
    try {
      const response = await axios.get(`/api/participant/${id}`);
      if (response.status === 404) {
        throw new Error('Participant not found');
      } else {
        dispatch(participantSlice.actions.getParticipantSuccess(response.data));
      }
    } catch (error) {
      dispatch(participantSlice.actions.hasError(error));
    }
  };
}

export function getParticipants() {
  return async (dispatch: Dispatch) => {
    dispatch(participantSlice.actions.startLoading());
    try {
      const response = await axios.get(`/api/participants`);
      if (response.status === 404) {
        throw new Error('Participants not found');
      } else {
        dispatch(participantSlice.actions.getParticipantsSuccess(response.data));
      }
    } catch (error) {
      dispatch(participantSlice.actions.hasError(error));
    }
  };
}

export function createParticipant(user_id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(participantSlice.actions.startLoading());
    try {
      const response = await axios.post('/api/participants', { user_id });
      if (response.status === 201) {
        dispatch(participantSlice.actions.createParticipantSuccess(response.data));
        return true;
      }
    } catch (error) {
      dispatch(participantSlice.actions.hasError(error));
      return false;
    }
  };
}
