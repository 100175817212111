import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../../utils/axios';

import { IProgramState } from '../../../@types/arrays';

const initialState: IProgramState = {
  loadingProgram: false,
  error: null,
  programmes: [],
  program: null,
};

const slice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loadingProgram = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.loadingProgram = false;
      state.error = action.payload;
    },
    // GET ProgramS
    getProgrammesSuccess(state, action) {
      state.loadingProgram = false;
      state.programmes = action.payload.data;
    },
    // GET Program
    getProgramSuccess(state, action) {
      state.loadingProgram = false;
      state.program = action.payload;
      // console.log(state.program);
    },
  },
});

export function getPrograms() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/programs');
      dispatch(slice.actions.getProgrammesSuccess(response.data));
      return true;
      // console.log(response.data);
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export default slice.reducer;
