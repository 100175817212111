import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

import { ITeacherKpiState } from 'src/@types/teacher-kpi';

const initialState: ITeacherKpiState = {
  isLoading: false,
  error: null,
  hours: null,
  earnings: null,
  totalStreak: null,
  studentStreaks: null,
};
const slice = createSlice({
  name: 'teacherKpi',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    }, // GET Lesosns
    getHoursSuccess(state, action) {
      state.isLoading = false;
      state.hours = action.payload;
    },
    getEarningsSuccess(state, action) {
      state.isLoading = false;
      state.earnings = action.payload;
    },
    getTotalStreakSuccess(state, action) {
      state.isLoading = false;
      state.totalStreak = action.payload;
    },
    getStudentStreaksSuccess(state, action) {
      state.isLoading = false;
      state.studentStreaks = action.payload;
    },
  },
});

export default slice.reducer;

export function getHoursTaught(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `/api/hours-taught/${id}`;
      const response = await axios.get(url, {
        withCredentials: true,
      });
      if (response.status === 404) {
        throw new Error('Hours taught not found');
      } else {
        dispatch(slice.actions.getHoursSuccess(response.data));
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
    }
  };
}

export function getEarnings(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `/api/earnings/${id}`;
      const response = await axios.get(url, {
        withCredentials: true,
      });
      if (response.status === 404) {
        throw new Error('Earnings taught not found');
      } else {
        dispatch(slice.actions.getEarningsSuccess(response.data));
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
    }
  };
}

export function getTotalStreak(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `/api/total-teaching-streak/${id}`;
      const response = await axios.get(url, {
        withCredentials: true,
      });
      if (response.status === 404) {
        throw new Error('Total streak not found');
      } else {
        dispatch(slice.actions.getTotalStreakSuccess(response.data));
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
    }
  };
}

export function getStudentStreaks(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `/api/teaching-streak/${id}`;
      const response = await axios.get(url, {
        withCredentials: true,
      });
      if (response.status === 404) {
        throw new Error('Student streaks not found');
      } else {
        dispatch(slice.actions.getStudentStreaksSuccess(response.data));
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
    }
  };
}
