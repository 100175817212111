// @mui
import { TableRow, TableCell } from '@mui/material';
//
import EmptyContent from '../empty-content';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

type Props = {
  isNotFound: boolean;
  noResultsMessage?: string; // Optional prop for custom "no results" message
};

export default function TableNoData({ isNotFound, noResultsMessage }: Props) {
  const { translate } = useLocales();

  // Determine the message to display
  const message = noResultsMessage || String(translate('genericLabels.noResults'));

  return (
    <TableRow>
      {isNotFound ? (
        <TableCell colSpan={12}>
          <EmptyContent
            title={message}
            sx={{
              '& span.MuiBox-root': { height: 160 },
            }}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}
