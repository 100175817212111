// react
import { useEffect, useState } from 'react';
import { useLocales } from 'src/locales';
import axios from '../../../utils/axios';

// @mui
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Typography,
  IconButton,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import { EventInput } from '@fullcalendar/core';

import { useDispatch, useSelector } from '../../../redux/store';
import { getStudents, getTeachers } from '../../../redux/slices/calendar';
import { useAuthContext } from '../../../auth/useAuthContext';

// components
import Iconify from '../../../components/iconify';
import { CalendarForm } from '../calendar';
import { ICalendarEvent } from '../../../@types/calendar';
import { useSnackbar } from '../../../components/snackbar';
import RoleBasedGuard from 'src/auth/RoleBasedGuard';

type FinishLessonProps = {
  event?: EventInput | null;
  cancel: boolean;
  admin: boolean;
  error: string | Error | null;
  teachers: [];
  students: [];
  onCancel: (state: boolean) => void;
  finishLessson: (completionNotes: string) => void;
  handleCreateUpdateEvent: (newEvent: ICalendarEvent) => void;
  recurring?: boolean;
};
/**
 * Dialog for lesson completion for teachers
 *
 */
export default function CompleteLessonDialong({
  event,
  finishLessson,
  error,
  handleCreateUpdateEvent,
  cancel,
  teachers,
  students,
  admin,
  onCancel,
  recurring,
}: FinishLessonProps) {
  const [todayInfo, setTodayInfo] = useState('');
  const [improvement, setImprovement] = useState('');
  const [prevTodayInfo, setPrevTodayInfo] = useState('');
  const [prevImprovement, setPrevImprovement] = useState('');
  const [nextLesson, setNextLesson] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [showUndo, setShowUndo] = useState(false);

  const [selectedRange, setSelectedRange] = useState<{
    start: Date;
    end: Date;
  } | null>(null);

  const { lesson } = useSelector((state) => state.lesson);

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { translate } = useLocales();

  const { user } = useAuthContext();

  const handleButtonClick = () => {
    if (todayInfo.trim() === '' || improvement.trim() === '') {
      enqueueSnackbar(`${translate('snackBar.emptyAI')}`, {
        variant: 'error',
      });
      setClickCount(0);

      return;
    }
    enhanceReportWithAI();
    setClickCount((prevCount) => prevCount + 1);
  };

  const enhanceReportWithAI = async () => {
    const userMessage = `${translate('tutor.whatWorkedOnToday')}: ${todayInfo}\n${translate(
      'tutor.whatImproved'
    )}: ${improvement}`;

    let lessonID;
    // Get lesson ID either from event if event exists.
    if (event) {
      // If event exists then set lessonID to event?.id
      lessonID = event.id;
    } else if (lesson) {
      lessonID = lesson.id;
    }
    // const { lesson } = useSelector((state) => state.lesson);
    // if lesson exists then set lesson ID to lesson.id

    // TODO update the backend to use the ID of the lesson
    try {
      setLoading(true);
      // I think the event is only defined when it's from the calendar, but not from lesson page.
      // console.log(userMessage);
      // console.log(lessonID);
      const response = await axios.post('/api/generate_report', {
        message: userMessage,
        lessonID: String(lessonID),
      });
      // console.log(response);
      // console.log('Backend response:', response);

      setPrevTodayInfo(todayInfo);
      setPrevImprovement(improvement);

      const { enhancedTodayInfo, enhancedImprovement } = response.data;
      // console.log('Hvad er eleven blevet bedre til:', enhancedImprovement);
      // console.log('Hvad har i arbejdet på i dag?', enhancedTodayInfo);

      setTodayInfo(enhancedTodayInfo);
      setImprovement(enhancedImprovement);

      setLoading(false);
      setShowUndo(true);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.response) {
        console.error('Response Error:', error.response.data);
        console.error('Response Status:', error.response.status);
        console.error('Response Headers:', error.response.headers);
      } else if (error.request) {
        console.error('No Response Error:', error.request);
      } else {
        console.error('Error Message:', error.message);
      }
    }
  };

  const undoEnhancement = () => {
    setTodayInfo(prevTodayInfo);
    setImprovement(prevImprovement);
  };

  const steps = [
    {
      label: `${translate('lesson.confirmReport')}`,
      obligatory: true,
      field: (
        <>
          <Typography variant="body1" sx={{ my: '2rem', mx: '2rem' }}>{`${translate(
            'tutor.description'
          )}`}</Typography>
          <Typography variant="h6" sx={{ mx: '2rem' }}>{`${translate(
            'tutor.whatWorkedOnToday'
          )}`}</Typography>
          <TextField
            value={todayInfo}
            onChange={(e) => setTodayInfo(e.target.value)}
            multiline
            required
            rows={6}
            inputProps={{ maxLength: 9000 }}
            sx={{ mx: '2rem' }}
            placeholder={`${translate('tutor.whatWorkedOnTodayDescription')}`}
          />
          <Typography variant="h6" sx={{ mx: '2rem', mt: '2rem' }}>{`${translate(
            'tutor.whatImproved'
          )}`}</Typography>
          <TextField
            value={improvement}
            onChange={(e) => setImprovement(e.target.value)}
            multiline
            required
            rows={6}
            inputProps={{ maxLength: 9000 }}
            sx={{ mx: '2rem' }}
            placeholder={`${translate('tutor.whatImprovedDescription')}`}
          />
          <RoleBasedGuard markets={['1']}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                {clickCount < 1 && (
                  <Tooltip title={`${translate('lesson.tooltipAIBtn')}`}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        mt: '2rem',
                        ml: '2rem',
                        width: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        background: 'linear-gradient(45deg, #4CAF50 30%, #8BC34A 90%)',
                        borderRadius: '25px',
                        boxShadow: '0 3px 5px 2px rgba(76, 175, 80, .3)',
                        padding: '10px 30px',
                        fontSize: '1rem',
                        '&:hover': {
                          background: 'linear-gradient(45deg, #8BC34A 30%, #4CAF50 90%)',
                        },
                      }}
                      onClick={handleButtonClick}
                      disabled={loading}
                    >
                      {loading ? <CircularProgress size={24} /> : <>{translate('lesson.tryAI')}</>}
                    </Button>
                  </Tooltip>
                )}
              </div>
              {showUndo && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: '2rem',
                    ml: '2rem',
                    width: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    background: 'linear-gradient(45deg, #4CAF50 30%, #8BC34A 90%)',
                    borderRadius: '25px',
                    boxShadow: '0 3px 5px 2px rgba(76, 175, 80, .3)',
                    padding: '10px 30px',
                    fontSize: '1rem',
                    '&:hover': {
                      background: 'linear-gradient(45deg, #8BC34A 30%, #4CAF50 90%)',
                    },
                  }}
                  onClick={undoEnhancement}
                >
                  {`${translate('lesson.undo')}`}
                </Button>
              )}
            </div>
          </RoleBasedGuard>
          {loading && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255,255,255,0.8)',
                zIndex: 1000,
              }}
            >
              <CircularProgress color="success" size={60} />
              <p
                style={{
                  marginTop: '16px',
                  marginLeft: '20px',
                  fontSize: '20px',
                }}
              >
                {`${translate('lesson.loadingAI')}`}
              </p>
            </div>
          )}{' '}
        </>
      ),
    },
    {
      label: `${translate('tutor.planNextLesson')}`,
      obligatory: false,
      field: (
        <>
          <Typography variant="body1" sx={{ my: '2rem', mx: '2rem' }}>{`${translate(
            'tutor.descriptionCalendar'
          )}`}</Typography>
          <Button
            onClick={() => {
              setNextLesson(true);
            }}
            variant="contained"
            color="success"
            sx={{ margin: '2rem' }}
          >
            {`${translate('tutor.nextLessonButton')}`}
          </Button>
        </>
      ),
    },
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [openRecurringDialog, setOpenRecurringDialog] = useState(recurring || false);

  const handleSwitchChange = () => {
    setOpenRecurringDialog(!recurring);
  };

  const { students: studentsFromDispatch, teachers: teachersFromDispatch } = useSelector(
    (state) => state.calendar
  );

  useEffect(() => {
    if (teachersFromDispatch.length === 0) {
      if (user?.teacher) {
        dispatch(getTeachers(user.email, 'teacher'));
      } else if (user?.admin) {
        dispatch(getTeachers(user.email, 'admin'));
      }
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (studentsFromDispatch.length === 0) {
      if (user?.teacher) {
        dispatch(getStudents(user.email, 'teacher'));
      } else if (user?.admin) {
        dispatch(getStudents(user.email, 'admin'));
      }
    }
  }, [user, dispatch]);

  const scheduleLesson = (newEvent: ICalendarEvent) => {
    // console.log('scheduleLesson is called' + newEvent);
    handleCreateUpdateEvent(newEvent);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      setActiveStep(0);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleNextLessonClose = () => {
    setNextLesson(false);
  };

  useEffect(() => {
    if (error) {
      handleBack();
    }
  }, [error]);

  const finishCurrentLesson = () => {
    if (todayInfo !== '' && improvement !== '') {
      finishLessson(
        `${translate('tutor.whatWorkedOnToday')}\n${todayInfo}\n\n${translate(
          'tutor.whatImproved'
        )} \n${improvement}\n`
      );

      onCancel(false);
    } else {
      enqueueSnackbar(`${translate('tutor.errorMessage')}`, {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog maxWidth="lg" open={cancel} onClose={() => onCancel(false)}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={0.95}>
            <Typography variant="h4">{`${translate('tutor.title')}`}</Typography>
          </Box>
          <Box>
            <IconButton size="small" color="error" onClick={() => onCancel(false)}>
              <Iconify icon="ic:sharp-close" />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>

      {/* Showing all the steps */}
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index: number) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps = {
            optional: (
              <Typography variant="caption">
                {step.obligatory
                  ? `${translate('tutor.obligatoryField')}`
                  : `${translate('tutor.optionalField')}`}
              </Typography>
            ),
          };
          return (
            <Step key={step.label} {...stepProps}>
              <StepLabel {...labelProps}>{step.label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {/* If the teacher wants to schedule the next lesson we add the dialog for that */}
      {nextLesson ? (
        <Dialog maxWidth="lg" open={nextLesson} onClose={handleNextLessonClose}>
          <DialogTitle> {`${translate('tutor.obligatoryField')}`}</DialogTitle>
          <CalendarForm
            teachers={teachers}
            students={students}
            admin={!admin}
            event={null}
            range={selectedRange}
            onCancel={handleNextLessonClose}
            onCreateUpdateEvent={(newEvent) => {
              scheduleLesson(newEvent);
              finishCurrentLesson();
            }}
            nextLesson={nextLesson}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
          />
        </Dialog>
      ) : (
        <></>
      )}

      <>
        {steps[activeStep].field}
        <DialogActions>
          {/* Show the back button after the first step */}
          {activeStep !== 0 && (
            <Button variant="outlined" color="error" onClick={handleBack}>
              {`${translate('tutor.backButton')}`}{' '}
            </Button>
          )}
          {activeStep === steps.length - 1 ? (
            <>
              {recurring == true && (
                <>
                  <Dialog
                    open={openRecurringDialog}
                    onClose={handleSwitchChange}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                  >
                    <DialogTitle>
                      <Typography color={'info'} variant="h6">
                        {`${translate('calender.recurringLessonAlertTitle')}`}
                      </Typography>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-slide-description">
                        {`${translate('calender.recurringLessonAlert')}`}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleSwitchChange}>{`${translate(
                        'calender.recurringLessonAlertButton'
                      )}`}</Button>
                    </DialogActions>
                  </Dialog>
                </>
              )}

              <Button onClick={finishCurrentLesson} variant="contained" color="primary">
                {`${translate('tutor.finishButton')}`}{' '}
              </Button>
            </>
          ) : (
            <Button variant="outlined" color="primary" onClick={handleNext}>
              {`${translate('tutor.nextButton')}`}
            </Button>
          )}
        </DialogActions>
      </>
    </Dialog>
  );
}
