import { useState } from 'react';
/* follow this path to get to where the code is inspired from in the Minimal_TypeScript_v4.2.0:

src/sections/@dashboard/file/FilePanel
*/
// @mui
import {
  StackProps,
  Button,
  Typography,
  IconButton,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
// components
import { useLocales } from '../../../locales';

import Iconify from '../../../components/iconify';
import { useSnackbar } from '../../../components/snackbar';
import { useDispatch } from '../../../redux/store';
import { sendLessonRemainder } from '../../../redux/slices/lesson';
// ----------------------------------------------------------------------

interface Props extends StackProps {
  id?: string;
  onOpen?: VoidFunction;
}

export default function SendLessonReminder({ id, sx, ...other }: Props) {
  const { translate } = useLocales();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const sendEmail = async () => {
    const response = await dispatch(sendLessonRemainder(id));
    if (response) {
      enqueueSnackbar(`${translate('sendLessonReminder.emailOnWay')}`, { variant: 'success' });
    } else {
      enqueueSnackbar(`${translate('sendLessonReminder.emailFailed')}`, { variant: 'error' });
    }
    setOpenDialog(false);
  };
  return (
    <>
      <Button
        onClick={() => {
          setOpenDialog(true);
        }}
        variant="outlined"
        startIcon={<Iconify icon="ph:bell" />}
      >
        {`${translate('sendLessonReminder.sendEmail')}`}{' '}
      </Button>
      <Dialog fullWidth maxWidth="md" open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          {`${translate('sendLessonReminder.dialogTitle')}`}
          <IconButton
            sx={{ position: 'absolute', right: 8, top: 8 }}
            color="error"
            onClick={() => setOpenDialog(false)}
          >
            <Iconify icon="ic:sharp-close" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            {`${translate('sendLessonReminder.dialogContent')}`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={() => setOpenDialog(false)}>
            {`${translate('sendLessonReminder.cancel')}`}
          </Button>
          <Button variant="contained" color="primary" onClick={() => sendEmail()}>
            {`${translate('sendLessonReminder.sendEmail')}`}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
