// i18n
import './locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';
// cropperjs
// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//react latex
import 'katex/dist/katex.min.css';
import './theme/overrides/latex-overrides.css';
// ----------------------------------------------------------------------

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// redux
import { useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux/store';

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import LocalizationProviderWrapper from './locales';
// components
import SnackbarProvider from './components/snackbar';
import ScrollToTop from './components/scroll-to-top';

import { MotionLazyContainer } from './components/animate';
import { AuthProvider } from './auth/JwtContext';
import LessonPage from './pages/dashboard/lessons/LessonPage';

import LessonsButtons from './sections/@dashboard/lessons/LessonsButtons';

import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
// ----------------------------------------------------------------------

export default function App() {
  const [urlForLesson, setUrlForLesson] = useState<string>('');
  const [redirectionUrl, setRedirectionUrl] = useState<string>('');
  const [typeUser, setTypeUser] = useState('');
  const [finishLesson, setFinishLesson] = useState(false);
  const [returnForStudent, setReturnForStudent] = useState<boolean>(false);
  const [lessonId, setLessonId] = useState<string>('');
  /**
   * for opening the cancel lesson dialog
   */
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  //Closes the cancel lesson dialog
  const handleCancelModal = (state: boolean) => {
    setOpenCancelDialog(state);
  };

  const goBackStudent = (isStudent: boolean) => {
    if (isStudent) {
      window.location.replace('/dashboard');
    }
  };

  return (
    <AuthProvider>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <LocalizationProviderWrapper>
            <BrowserRouter>
              <ScrollToTop />
              <MotionLazyContainer>
                <ThemeProvider>
                  <SnackbarProvider>
                    {urlForLesson ? (
                      <>
                        <LessonsButtons
                          lesson_id={lessonId}
                          goBackStudent={goBackStudent}
                          handleCancelModal={handleCancelModal}
                          setFinishLesson={setFinishLesson}
                          returnForStudent={returnForStudent}
                          typeUser={typeUser}
                        />

                        <LessonPage
                          setLessonId={setLessonId}
                          openCancelDialog={openCancelDialog}
                          handleCancelModal={handleCancelModal}
                          setTypeUser={setTypeUser}
                          goBackStudent={setReturnForStudent}
                          urlForLesson={urlForLesson}
                          setRedirectionUrl={setRedirectionUrl}
                          setUrlForLesson={setUrlForLesson}
                          finishLesson={finishLesson}
                          setFinishLesson={setFinishLesson}
                        />
                      </>
                    ) : (
                      <Router
                        setLessonId={setLessonId}
                        setReturnForStudent={setReturnForStudent}
                        urlForLesson={urlForLesson}
                        setUrlForLesson={setUrlForLesson}
                      />
                    )}
                  </SnackbarProvider>
                </ThemeProvider>
              </MotionLazyContainer>
            </BrowserRouter>
          </LocalizationProviderWrapper>
        </ReduxProvider>
      </HelmetProvider>
    </AuthProvider>
  );
}
