import { emojis } from '../assets/data/emojis';
export function getEmoji() {
  const rand = Math.floor(Math.random() * (emojis.length - 1));
  const selectedEmoji = emojis[rand];
  return selectedEmoji;
}

export function formatDataStringAudio(data: string) {
  // Create a new Blob object from the audio data with MIME type 'audio/mpeg'
  const blob = new Blob([data], { type: 'audio/mpeg' });
  // Create a URL for the blob object
  const url = URL.createObjectURL(blob);
  // Set the audio URL state variable to the newly created URL
  console.log('URL', url);
  return url;
}

export function calculateExactMonthDifference(startDate: Date, endDate: Date) {
  const msInDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  const msIn28Days = 28 * msInDay; // Number of milliseconds in 28 days
  // Calculate the difference in time between the two dates in milliseconds
  const timeDifference = endDate.getTime() - startDate.getTime();

  // Calculate the difference in 28-day periods
  const exact28DayPeriods = timeDifference / msIn28Days;

  return Math.max(0, exact28DayPeriods);
}
export function getFirstRole(roles: string[]) {
  if (!roles || roles.length === 0) {
    return null; // Return null if the list is empty
  }

  if (roles[0].toLowerCase() === 'superadmin') {
    return roles.length > 1 ? roles[1] : null;
  } else {
    return roles[0];
  }
}
