import { useRef, useState, useEffect } from 'react';
import { m } from 'framer-motion';
import { useTheme, Button, Box, Stack } from '@mui/material';
import Iconify from '../../components/iconify';
import Latex from 'react-latex-next';
interface ButtonAnimateProps {
  text: string;
  textColor?: string;
  isMobile: boolean;
  onClick: () => void;
  buttonColor?: string;
  icon?: string;
  iconColor?: string;
  iconColorHover?: string;
  buttonColorHover?: string;
  textColorHover?: string;
}

export default function ButtonAnimate(props: ButtonAnimateProps) {
  const divRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  const theme = useTheme();

  useEffect(() => {
    if (!divRef.current) return;

    const rect = divRef.current.getBoundingClientRect();
    setDimensions({ width: rect.width, height: rect.height });
  }, []);

  const formatLatex = (input: string) => {
    return input.replace(/(?<!\\) /g, '\\ ');
  };
  return (
    <Box
      ref={divRef}
      width="fit-content"
      height="fit-content"
      marginBottom={1.5}
      position="relative"
    >
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: props.isMobile ? 0 : 1.5, duration: props.isMobile ? 0 : 0.5 }}
        whileHover={{ scale: 1.05, transition: { type: 'spring', stiffness: 400, damping: 17 } }}
        whileTap={{ scale: 0.9, transition: { type: 'spring', stiffness: 400, damping: 17 } }}
        style={{ pointerEvents: 'auto' }}
      >
        <Button
          color="inherit"
          size="small"
          onClick={props.onClick}
          sx={{
            width: '100%',
            py: '3px',
            px: '10px',
            height: 'fit-content',
            backgroundColor: props.buttonColor || theme.palette.background.paper, // Use buttonColor prop
            border: theme.palette.borders.border,
            borderRadius: theme.palette.borders.borderRadius,
            boxShadow: theme.palette.borders.boxShadow,
            whiteSpace: 'normal', // Allow text to wrap
            textAlign: 'left', // Align text to the left
            color: props.textColor || theme.palette.text.primary,
            transition: 'background-color 0.3s, color 0.3s',
            '&:hover': {
              backgroundColor: props.buttonColorHover || theme.palette.secondary.main,
              color: props.textColorHover || props.textColor || theme.palette.text.primary,
            },
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <Iconify
              color={props.iconColor || theme.palette.text.primary}
              icon={props.icon || 'ph:arrow-bend-down-right'} // Use icon prop
              sx={{
                m: '0px',
                width: '0.9rem',
                transition: 'color 0.3s',
                '&:hover': {
                  color: props.iconColorHover || props.iconColor || theme.palette.text.primary,
                },
              }}
            />
            <Box
              component={Latex}
              sx={{
                '& .latex': {
                  color: 'inherit',
                  fontSize: props.isMobile ? '0.8rem' : '0.9rem',
                },
              }}
            >
              {`\\(${formatLatex(props.text)}\\)`}

              {/* <Latex>{props.text}</Latex> */}
            </Box>
          </Stack>
        </Button>
      </m.div>
    </Box>
  );
}
