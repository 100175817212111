import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

import { IBadgeState, IBadge } from '../../@types/badge';

const initialState: IBadgeState = {
  isLoading: false,
  error: null,
  badges: [],
  badge: null,
};

const slice = createSlice({
  name: 'badge',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;

      state.error = action.payload; // if you only write action.payload, you do not dot-in to the actual data, where all data for badge is
    },
    // GET Badges
    getBadgesSuccess(state, action) {
      state.isLoading = false;
      state.badges = action.payload;
    },
    // GET Badge
    getBadgeSuccess(state, action) {
      state.isLoading = false;
      state.badge = action.payload;
    },
    // DELETE Badge
    deleteBadgeSuccess(state, action) {
      state.isLoading = false;
      state.badge = null;
    },
  },
});

export default slice.reducer;

export function getBadges(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/get_badges/${id}`);
      dispatch(slice.actions.getBadgesSuccess(response.data));
      return true;
      // console.log(response.data);
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
