export const countries = [
  {
    code: 'AD',
    label: 'Andorra',
    phone: '376',
    icon: '/assets/icons/flags/ad.svg',
    value: 3,
  },
  {
    code: 'AE',
    label: 'United Arab Emirates',
    phone: '971',
    icon: '/assets/icons/flags/ae.svg',
    value: 3,
  },
  {
    code: 'AF',
    label: 'Afghanistan',
    phone: '93',
    icon: '/assets/icons/flags/af.svg',
    value: 3,
  },
  {
    code: 'AG',
    label: 'Antigua and Barbuda',
    phone: '1-268',
    icon: '/assets/icons/flags/ag.svg',
    value: 3,
  },
  {
    code: 'AI',
    label: 'Anguilla',
    phone: '1-264',
    icon: '/assets/icons/flags/ai.svg',
    value: 3,
  },
  {
    code: 'AL',
    label: 'Albania',
    phone: '355',
    icon: '/assets/icons/flags/al.svg',
    value: 3,
  },
  {
    code: 'AM',
    label: 'Armenia',
    phone: '374',
    icon: '/assets/icons/flags/am.svg',
    value: 3,
  },
  {
    code: 'AO',
    label: 'Angola',
    phone: '244',
    icon: '/assets/icons/flags/ao.svg',
    value: 3,
  },
  {
    code: 'AQ',
    label: 'Antarctica',
    phone: '672',
    icon: '/assets/icons/flags/aq.svg',
    value: 3,
  },
  {
    code: 'AR',
    label: 'Argentina',
    phone: '54',
    icon: '/assets/icons/flags/ar.svg',
    value: 3,
  },
  {
    code: 'AS',
    label: 'American Samoa',
    phone: '1-684',
    icon: '/assets/icons/flags/as.svg',
    value: 3,
  },
  {
    code: 'AT',
    label: 'Austria',
    phone: '43',
    icon: '/assets/icons/flags/at.svg',
    value: 3,
  },
  {
    code: 'AU',
    label: 'Australia',
    phone: '61',
    suggested: true,
    icon: '/assets/icons/flags/au.svg',
    value: 3,
  },
  {
    code: 'AW',
    label: 'Aruba',
    phone: '297',
    icon: '/assets/icons/flags/aw.svg',
    value: 3,
  },
  {
    code: 'AX',
    label: 'Alland Islands',
    phone: '358',
    icon: '/assets/icons/flags/ax.svg',
    value: 3,
  },
  {
    code: 'AZ',
    label: 'Azerbaijan',
    phone: '994',
    icon: '/assets/icons/flags/az.svg',
    value: 3,
  },
  {
    code: 'BA',
    label: 'Bosnia and Herzegovina',
    phone: '387',
    icon: '/assets/icons/flags/ba.svg',
    value: 3,
  },
  {
    code: 'BB',
    label: 'Barbados',
    phone: '1-246',
    icon: '/assets/icons/flags/bb.svg',
    value: 3,
  },
  {
    code: 'BD',
    label: 'Bangladesh',
    phone: '880',
    icon: '/assets/icons/flags/bd.svg',
    value: 3,
  },
  {
    code: 'BE',
    label: 'Belgium',
    phone: '32',
    icon: '/assets/icons/flags/be.svg',
    value: 3,
  },
  {
    code: 'BF',
    label: 'Burkina Faso',
    phone: '226',
    icon: '/assets/icons/flags/bf.svg',
    value: 3,
  },
  {
    code: 'BG',
    label: 'Bulgaria',
    phone: '359',
    icon: '/assets/icons/flags/bg.svg',
    value: 3,
  },
  {
    code: 'BH',
    label: 'Bahrain',
    phone: '973',
    icon: '/assets/icons/flags/bh.svg',
    value: 3,
  },
  {
    code: 'BI',
    label: 'Burundi',
    phone: '257',
    icon: '/assets/icons/flags/bi.svg',
    value: 3,
  },
  {
    code: 'BJ',
    label: 'Benin',
    phone: '229',
    icon: '/assets/icons/flags/bj.svg',
    value: 3,
  },
  {
    code: 'BL',
    label: 'Saint Barthelemy',
    phone: '590',
    icon: '/assets/icons/flags/bl.svg',
    value: 3,
  },
  {
    code: 'BM',
    label: 'Bermuda',
    phone: '1-441',
    icon: '/assets/icons/flags/bm.svg',
    value: 3,
  },
  {
    code: 'BN',
    label: 'Brunei Darussalam',
    phone: '673',
    icon: '/assets/icons/flags/bn.svg',
    value: 3,
  },
  {
    code: 'BO',
    label: 'Bolivia',
    phone: '591',
    icon: '/assets/icons/flags/bo.svg',
    value: 3,
  },
  {
    code: 'BR',
    label: 'Brazil',
    phone: '55',
    icon: '/assets/icons/flags/br.svg',
    value: 3,
  },
  {
    code: 'BS',
    label: 'Bahamas',
    phone: '1-242',
    icon: '/assets/icons/flags/bs.svg',
    value: 3,
  },
  {
    code: 'BT',
    label: 'Bhutan',
    phone: '975',
    icon: '/assets/icons/flags/bt.svg',
    value: 3,
  },
  {
    code: 'BV',
    label: 'Bouvet Island',
    phone: '47',
    icon: '/assets/icons/flags/bv.svg',
    value: 3,
  },
  {
    code: 'BW',
    label: 'Botswana',
    phone: '267',
    icon: '/assets/icons/flags/bw.svg',
    value: 3,
  },
  {
    code: 'BY',
    label: 'Belarus',
    phone: '375',
    icon: '/assets/icons/flags/by.svg',
    value: 3,
  },
  {
    code: 'BZ',
    label: 'Belize',
    phone: '501',
    icon: '/assets/icons/flags/bz.svg',
    value: 3,
  },
  {
    code: 'CA',
    label: 'Canada',
    phone: '1',
    suggested: true,
    icon: '/assets/icons/flags/ca.svg',
    value: 3,
  },
  {
    code: 'CC',
    label: 'Cocos (Keeling) Islands',
    phone: '61',
    icon: '/assets/icons/flags/cc.svg',
    value: 3,
  },
  {
    code: 'CD',
    label: 'Congo',
    phone: '243',
    icon: '/assets/icons/flags/cd.svg',
    value: 3,
  },
  {
    code: 'CF',
    label: 'Central African Republic',
    phone: '236',
    icon: '/assets/icons/flags/cf.svg',
    value: 3,
  },
  {
    code: 'CG',
    label: 'Congo',
    phone: '242',
    icon: '/assets/icons/flags/cg.svg',
    value: 3,
  },
  {
    code: 'CH',
    label: 'Switzerland',
    phone: '41',
    icon: '/assets/icons/flags/ch.svg',
    value: 3,
  },
  {
    code: 'CI',
    label: "Cote d'Ivoire",
    phone: '225',
    icon: '/assets/icons/flags/ci.svg',
    value: 3,
  },
  {
    code: 'CK',
    label: 'Cook Islands',
    phone: '682',
    icon: '/assets/icons/flags/ck.svg',
    value: 3,
  },
  {
    code: 'CL',
    label: 'Chile',
    phone: '56',
    icon: '/assets/icons/flags/cl.svg',
    value: 3,
  },
  {
    code: 'CM',
    label: 'Cameroon',
    phone: '237',
    icon: '/assets/icons/flags/cm.svg',
    value: 3,
  },
  {
    code: 'CN',
    label: 'China',
    phone: '86',
    icon: '/assets/icons/flags/cn.svg',
    value: 3,
  },
  {
    code: 'CO',
    label: 'Colombia',
    phone: '57',
    icon: '/assets/icons/flags/co.svg',
    value: 3,
  },
  {
    code: 'CR',
    label: 'Costa Rica',
    phone: '506',
    icon: '/assets/icons/flags/cr.svg',
    value: 3,
  },
  {
    code: 'CU',
    label: 'Cuba',
    phone: '53',
    icon: '/assets/icons/flags/cu.svg',
    value: 3,
  },
  {
    code: 'CV',
    label: 'Cabe Verde',
    phone: '238',
    icon: '/assets/icons/flags/cv.svg',
    value: 3,
  },
  {
    code: 'CW',
    label: 'Curacao',
    phone: '599',
    icon: '/assets/icons/flags/cw.svg',
    value: 3,
  },
  {
    code: 'CX',
    label: 'Christmas Island',
    phone: '61',
    icon: '/assets/icons/flags/cx.svg',
    value: 3,
  },
  {
    code: 'CY',
    label: 'Cyprus',
    phone: '357',
    icon: '/assets/icons/flags/cy.svg',
    value: 3,
  },
  {
    code: 'CZ',
    label: 'Czech Republic',
    phone: '420',
    icon: '/assets/icons/flags/cz.svg',
    value: 3,
  },
  {
    code: 'DE',
    label: 'Germany',
    phone: '49',
    suggested: true,
    icon: '/assets/icons/flags/de.svg',
    value: 3,
  },
  {
    code: 'DJ',
    label: 'Djibouti',
    phone: '253',
    icon: '/assets/icons/flags/dj.svg',
    value: 3,
  },
  {
    code: 'DK',
    label: 'Denmark',
    phone: '45',
    icon: '/assets/icons/flags/dk.svg',
    value: 1,
  },
  {
    code: 'DM',
    label: 'Dominica',
    phone: '1-767',
    icon: '/assets/icons/flags/dm.svg',
    value: 3,
  },
  {
    code: 'DO',
    label: 'Dominican Republic',
    phone: '1-809',
    icon: '/assets/icons/flags/do.svg',
    value: 3,
  },
  {
    code: 'DZ',
    label: 'Algeria',
    phone: '213',
    icon: '/assets/icons/flags/dz.svg',
    value: 3,
  },
  {
    code: 'EC',
    label: 'Ecuador',
    phone: '593',
    icon: '/assets/icons/flags/ec.svg',
    value: 3,
  },
  {
    code: 'EE',
    label: 'Estonia',
    phone: '372',
    icon: '/assets/icons/flags/ee.svg',
    value: 3,
  },
  {
    code: 'EG',
    label: 'Egypt',
    phone: '20',
    icon: '/assets/icons/flags/eg.svg',
    value: 3,
  },
  {
    code: 'EH',
    label: 'Western Sahara',
    phone: '212',
    icon: '/assets/icons/flags/eh.svg',
    value: 3,
  },
  {
    code: 'ER',
    label: 'Eritrea',
    phone: '291',
    icon: '/assets/icons/flags/er.svg',
    value: 3,
  },
  {
    code: 'ET',
    label: 'Ethiopia',
    phone: '251',
    icon: '/assets/icons/flags/et.svg',
    value: 3,
  },
  {
    code: 'FI',
    label: 'Finland',
    phone: '358',
    icon: '/assets/icons/flags/fi.svg',
    value: 3,
  },
  {
    code: 'FJ',
    label: 'Fiji',
    phone: '679',
    icon: '/assets/icons/flags/fj.svg',
    value: 3,
  },
  {
    code: 'FK',
    label: 'Falkland Islands (Malvinas)',
    phone: '500',
    icon: '/assets/icons/flags/fk.svg',
    value: 3,
  },
  {
    code: 'FM',
    label: 'Micronesia, Federated States of',
    phone: '691',
    icon: '/assets/icons/flags/fm.svg',
    value: 3,
  },
  {
    code: 'FO',
    label: 'Faroe Islands',
    phone: '298',
    icon: '/assets/icons/flags/fo.svg',
    value: 3,
  },
  {
    code: 'FR',
    label: 'France',
    phone: '33',
    suggested: true,
    icon: '/assets/icons/flags/fr.svg',
    value: 3,
  },
  {
    code: 'GA',
    label: 'Gabon',
    phone: '241',
    icon: '/assets/icons/flags/ga.svg',
    value: 3,
  },
  {
    code: 'GB',
    label: 'United Kingdom',
    phone: '44',
    icon: '/assets/icons/flags/gb.svg',
    value: 3,
  },
  {
    code: 'GD',
    label: 'Grenada',
    phone: '1-473',
    icon: '/assets/icons/flags/gd.svg',
    value: 3,
  },
  {
    code: 'GE',
    label: 'Georgia',
    phone: '995',
    icon: '/assets/icons/flags/ge.svg',
    value: 3,
  },
  {
    code: 'GF',
    label: 'French Guiana',
    phone: '594',
    icon: '/assets/icons/flags/gf.svg',
    value: 3,
  },
  {
    code: 'GG',
    label: 'Guernsey',
    phone: '44',
    icon: '/assets/icons/flags/gg.svg',
    value: 3,
  },
  {
    code: 'GH',
    label: 'Ghana',
    phone: '233',
    icon: '/assets/icons/flags/gh.svg',
    value: 3,
  },
  {
    code: 'GI',
    label: 'Gibraltar',
    phone: '350',
    icon: '/assets/icons/flags/gi.svg',
    value: 3,
  },
  {
    code: 'GL',
    label: 'Greenland',
    phone: '299',
    icon: '/assets/icons/flags/gl.svg',
    value: 3,
  },
  {
    code: 'GM',
    label: 'Gambia',
    phone: '220',
    icon: '/assets/icons/flags/gm.svg',
    value: 3,
  },
  {
    code: 'GN',
    label: 'Guinea',
    phone: '224',
    icon: '/assets/icons/flags/gn.svg',
    value: 3,
  },
  {
    code: 'GP',
    label: 'Guadeloupe',
    phone: '590',
    icon: '/assets/icons/flags/gp.svg',
    value: 3,
  },
  {
    code: 'GQ',
    label: 'Equatorial Guinea',
    phone: '240',
    icon: '/assets/icons/flags/gq.svg',
    value: 3,
  },
  {
    code: 'GR',
    label: 'Greece',
    phone: '30',
    icon: '/assets/icons/flags/gr.svg',
    value: 3,
  },
  {
    code: 'GS',
    label: 'South Georgia and the South Sandwich Islands',
    phone: '500',
    icon: '/assets/icons/flags/gs.svg',
    value: 3,
  },
  {
    code: 'GT',
    label: 'Guatemala',
    phone: '502',
    icon: '/assets/icons/flags/gt.svg',
    value: 3,
  },
  {
    code: 'GU',
    label: 'Guam',
    phone: '1-671',
    icon: '/assets/icons/flags/gu.svg',
    value: 3,
  },
  {
    code: 'GW',
    label: 'Guinea-Bissau',
    phone: '245',
    icon: '/assets/icons/flags/gw.svg',
    value: 3,
  },
  {
    code: 'GY',
    label: 'Guyana',
    phone: '592',
    icon: '/assets/icons/flags/gy.svg',
    value: 3,
  },
  {
    code: 'HK',
    label: 'Hong Kong',
    phone: '852',
    icon: '/assets/icons/flags/hk.svg',
    value: 3,
  },
  {
    code: 'HM',
    label: 'Heard Island and McDonald Islands',
    phone: '672',
    icon: '/assets/icons/flags/hm.svg',
    value: 3,
  },
  {
    code: 'HN',
    label: 'Honduras',
    phone: '504',
    icon: '/assets/icons/flags/hn.svg',
    value: 3,
  },
  {
    code: 'HR',
    label: 'Croatia',
    phone: '385',
    icon: '/assets/icons/flags/hr.svg',
    value: 3,
  },

  {
    code: 'HT',
    label: 'Haiti',
    phone: '509',
    icon: '/assets/icons/flags/ht.svg',
    value: 3,
  },
  {
    code: 'HU',
    label: 'Hungary',
    phone: '36',
    icon: '/assets/icons/flags/hu.svg',
    value: 3,
  },
  {
    code: 'ID',
    label: 'Indonesia',
    phone: '62',
    icon: '/assets/icons/flags/id.svg',
    value: 3,
  },
  {
    code: 'IE',
    label: 'Ireland',
    phone: '353',
    icon: '/assets/icons/flags/ie.svg',
    value: 3,
  },
  {
    code: 'IL',
    label: 'Israel',
    phone: '972',
    icon: '/assets/icons/flags/il.svg',
    value: 3,
  },
  {
    code: 'IM',
    label: 'Isle of Man',
    phone: '44',
    icon: '/assets/icons/flags/im.svg',
    value: 3,
  },
  {
    code: 'IN',
    label: 'India',
    phone: '91',
    icon: '/assets/icons/flags/in.svg',
    value: 3,
  },
  {
    code: 'IO',
    label: 'British Indian Ocean Territory',
    phone: '246',
    icon: '/assets/icons/flags/io.svg',
    value: 3,
  },
  {
    code: 'IQ',
    label: 'Iraq',
    phone: '964',
    icon: '/assets/icons/flags/iq.svg',
    value: 3,
  },
  {
    code: 'IR',
    label: 'Iran, Islamic Republic of',
    phone: '98',
    icon: '/assets/icons/flags/ir.svg',
    value: 3,
  },
  {
    code: 'IS',
    label: 'Iceland',
    phone: '354',
    icon: '/assets/icons/flags/is.svg',
    value: 3,
  },
  {
    code: 'IT',
    label: 'Italy',
    phone: '39',
    icon: '/assets/icons/flags/it.svg',
    value: 3,
  },
  {
    code: 'JE',
    label: 'Jersey',
    phone: '44',
    icon: '/assets/icons/flags/je.svg',
    value: 3,
  },
  {
    code: 'JM',
    label: 'Jamaica',
    phone: '1-876',
    icon: '/assets/icons/flags/jm.svg',
    value: 3,
  },
  {
    code: 'JO',
    label: 'Jordan',
    phone: '962',
    icon: '/assets/icons/flags/jo.svg',
    value: 3,
  },
  {
    code: 'JP',
    label: 'Japan',
    phone: '81',
    suggested: true,
    icon: '/assets/icons/flags/jp.svg',
    value: 3,
  },
  {
    code: 'KE',
    label: 'Kenya',
    phone: '254',
    icon: '/assets/icons/flags/ke.svg',
    value: 3,
  },
  {
    code: 'KG',
    label: 'Kyrgyzstan',
    phone: '996',
    icon: '/assets/icons/flags/kg.svg',
    value: 3,
  },
  {
    code: 'KH',
    label: 'Cambodia',
    phone: '855',
    icon: '/assets/icons/flags/kh.svg',
    value: 3,
  },
  {
    code: 'KI',
    label: 'Kiribati',
    phone: '686',
    icon: '/assets/icons/flags/ki.svg',
    value: 3,
  },
  {
    code: 'KM',
    label: 'Comoros',
    phone: '269',
    icon: '/assets/icons/flags/km.svg',
    value: 3,
  },
  {
    code: 'KN',
    label: 'Saint Kitts and Nevis',
    phone: '1-869',
    icon: '/assets/icons/flags/kn.svg',
    value: 3,
  },
  {
    code: 'KP',
    label: "Korea, Democratic People's Republic of Country",
    phone: '850',
    icon: '/assets/icons/flags/kp.svg',
    value: 3,
  },
  {
    code: 'KR',
    label: 'South Korea',
    phone: '82',
    icon: '/assets/icons/flags/kr.svg',
    value: 3,
  },
  {
    code: 'KW',
    label: 'Kuwait',
    phone: '965',
    icon: '/assets/icons/flags/kw.svg',
    value: 3,
  },
  {
    code: 'KY',
    label: 'Cayman Islands',
    phone: '1-345',
    icon: '/assets/icons/flags/ky.svg',
    value: 3,
  },
  {
    code: 'KZ',
    label: 'Kazakhstan',
    phone: '7',
    icon: '/assets/icons/flags/kz.svg',
    value: 3,
  },
  {
    code: 'LA',
    label: "Lao People's Democratic Republic",
    phone: '856',
    icon: '/assets/icons/flags/la.svg',
    value: 3,
  },
  {
    code: 'LB',
    label: 'Lebanon',
    phone: '961',
    icon: '/assets/icons/flags/lb.svg',
    value: 3,
  },
  {
    code: 'LC',
    label: 'Saint Lucia',
    phone: '1-758',
    icon: '/assets/icons/flags/lc.svg',
    value: 3,
  },
  {
    code: 'LI',
    label: 'Liechtenstein',
    phone: '423',
    icon: '/assets/icons/flags/li.svg',
    value: 3,
  },
  {
    code: 'LK',
    label: 'Sri Lanka',
    phone: '94',
    icon: '/assets/icons/flags/lk.svg',
    value: 3,
  },
  {
    code: 'LR',
    label: 'Liberia',
    phone: '231',
    icon: '/assets/icons/flags/lr.svg',
    value: 3,
  },
  {
    code: 'LS',
    label: 'Lesotho',
    phone: '266',
    icon: '/assets/icons/flags/ls.svg',
    value: 3,
  },
  {
    code: 'LT',
    label: 'Lithuania',
    phone: '370',
    icon: '/assets/icons/flags/lt.svg',
    value: 3,
  },
  {
    code: 'LU',
    label: 'Luxembourg',
    phone: '352',
    icon: '/assets/icons/flags/lu.svg',
    value: 3,
  },
  {
    code: 'LV',
    label: 'Latvia',
    phone: '371',
    icon: '/assets/icons/flags/lv.svg',
    value: 3,
  },
  {
    code: 'LY',
    label: 'Libya',
    phone: '218',
    icon: '/assets/icons/flags/ly.svg',
    value: 3,
  },
  {
    code: 'MA',
    label: 'Morocco',
    phone: '212',
    icon: '/assets/icons/flags/ma.svg',
    value: 3,
  },
  {
    code: 'MC',
    label: 'Monaco',
    phone: '377',
    icon: '/assets/icons/flags/mc.svg',
    value: 3,
  },
  {
    code: 'MD',
    label: 'Moldova, Republic of',
    phone: '373',
    icon: '/assets/icons/flags/md.svg',
    value: 3,
  },
  {
    code: 'ME',
    label: 'Montenegro',
    phone: '382',
    icon: '/assets/icons/flags/me.svg',
    value: 3,
  },
  {
    code: 'MF',
    label: 'Saint Martin (French part)',
    phone: '590',
    icon: '/assets/icons/flags/mf.svg',
    value: 3,
  },
  {
    code: 'MG',
    label: 'Madagascar',
    phone: '261',
    icon: '/assets/icons/flags/mg.svg',
    value: 3,
  },
  {
    code: 'MH',
    label: 'Marshall Islands',
    phone: '692',
    icon: '/assets/icons/flags/mh.svg',
    value: 3,
  },
  {
    code: 'MK',
    label: 'Macedonia, the Former Yugoslav Republic of',
    phone: '389',
    icon: '/assets/icons/flags/mk.svg',
    value: 3,
  },
  {
    code: 'ML',
    label: 'Mali',
    phone: '223',
    icon: '/assets/icons/flags/ml.svg',
    value: 3,
  },
  {
    code: 'MM',
    label: 'Myanmar',
    phone: '95',
    icon: '/assets/icons/flags/mm.svg',
    value: 3,
  },
  {
    code: 'MN',
    label: 'Mongolia',
    phone: '976',
    icon: '/assets/icons/flags/mn.svg',
    value: 3,
  },
  {
    code: 'MO',
    label: 'Macao',
    phone: '853',
    icon: '/assets/icons/flags/mo.svg',
    value: 3,
  },
  {
    code: 'MP',
    label: 'Northern Mariana Islands',
    phone: '1-670',
    icon: '/assets/icons/flags/mp.svg',
    value: 3,
  },
  {
    code: 'MQ',
    label: 'Martinique',
    phone: '596',
    icon: '/assets/icons/flags/mq.svg',
    value: 3,
  },
  {
    code: 'MR',
    label: 'Mauritania',
    phone: '222',
    icon: '/assets/icons/flags/mr.svg',
    value: 3,
  },
  {
    code: 'MS',
    label: 'Montserrat',
    phone: '1-664',
    icon: '/assets/icons/flags/ms.svg',
    value: 3,
  },
  {
    code: 'MT',
    label: 'Malta',
    phone: '356',
    icon: '/assets/icons/flags/mt.svg',
    value: 3,
  },
  {
    code: 'MU',
    label: 'Mauritius',
    phone: '230',
    icon: '/assets/icons/flags/mu.svg',
    value: 3,
  },
  {
    code: 'MV',
    label: 'Maldives',
    phone: '960',
    icon: '/assets/icons/flags/mv.svg',
    value: 3,
  },
  {
    code: 'MW',
    label: 'Malawi',
    phone: '265',
    icon: '/assets/icons/flags/mw.svg',
    value: 3,
  },
  {
    code: 'MX',
    label: 'Mexico',
    phone: '52',
    icon: '/assets/icons/flags/mx.svg',
    value: 3,
  },
  {
    code: 'MY',
    label: 'Malaysia',
    phone: '60',
    icon: '/assets/icons/flags/my.svg',
    value: 3,
  },
  {
    code: 'MZ',
    label: 'Mozambique',
    phone: '258',
    icon: '/assets/icons/flags/mz.svg',
    value: 3,
  },
  {
    code: 'NA',
    label: 'Namibia',
    phone: '264',
    icon: '/assets/icons/flags/na.svg',
    value: 3,
  },
  {
    code: 'NC',
    label: 'New Caledonia',
    phone: '687',
    icon: '/assets/icons/flags/nc.svg',
    value: 3,
  },
  {
    code: 'NE',
    label: 'Niger',
    phone: '227',
    icon: '/assets/icons/flags/ne.svg',
    value: 3,
  },
  {
    code: 'NF',
    label: 'Norfolk Island',
    phone: '672',
    icon: '/assets/icons/flags/nf.svg',
    value: 3,
  },
  {
    code: 'NG',
    label: 'Nigeria',
    phone: '234',
    icon: '/assets/icons/flags/ng.svg',
    value: 3,
  },
  {
    code: 'NI',
    label: 'Nicaragua',
    phone: '505',
    icon: '/assets/icons/flags/ni.svg',
    value: 3,
  },
  {
    code: 'NL',
    label: 'Netherlands',
    phone: '31',
    icon: '/assets/icons/flags/nl.svg',
    value: 3,
  },
  {
    code: 'NO',
    label: 'Norway',
    phone: '47',
    icon: '/assets/icons/flags/no.svg',
    value: 3,
  },
  {
    code: 'NP',
    label: 'Nepal',
    phone: '977',
    icon: '/assets/icons/flags/np.svg',
    value: 3,
  },
  {
    code: 'NR',
    label: 'Nauru',
    phone: '674',
    icon: '/assets/icons/flags/nr.svg',
    value: 3,
  },
  {
    code: 'NU',
    label: 'Niue',
    phone: '683',
    icon: '/assets/icons/flags/nu.svg',
    value: 3,
  },
  {
    code: 'NZ',
    label: 'New Zealand',
    phone: '64',
    icon: '/assets/icons/flags/nz.svg',
    value: 3,
  },
  {
    code: 'OM',
    label: 'Oman',
    phone: '968',
    icon: '/assets/icons/flags/om.svg',
    value: 3,
  },
  {
    code: 'PA',
    label: 'Panama',
    phone: '507',
    icon: '/assets/icons/flags/pa.svg',
    value: 3,
  },
  {
    code: 'PE',
    label: 'Peru',
    phone: '51',
    icon: '/assets/icons/flags/pe.svg',
    value: 3,
  },
  {
    code: 'PF',
    label: 'French Polynesia',
    phone: '689',
    icon: '/assets/icons/flags/pf.svg',
    value: 3,
  },
  {
    code: 'PG',
    label: 'Papua New Guinea',
    phone: '675',
    icon: '/assets/icons/flags/pg.svg',
    value: 3,
  },
  {
    code: 'PH',
    label: 'Philippines',
    phone: '63',
    icon: '/assets/icons/flags/ph.svg',
    value: 3,
  },
  {
    code: 'PK',
    label: 'Pakistan',
    phone: '92',
    icon: '/assets/icons/flags/pk.svg',
    value: 3,
  },
  {
    code: 'PL',
    label: 'Poland',
    phone: '48',
    icon: '/assets/icons/flags/pl.svg',
    value: 3,
  },
  {
    code: 'PM',
    label: 'Saint Pierre and Miquelon',
    phone: '508',
    icon: '/assets/icons/flags/pm.svg',
    value: 3,
  },
  {
    code: 'PN',
    label: 'Pitcairn',
    phone: '870',
    icon: '/assets/icons/flags/pn.svg',
    value: 3,
  },
  {
    code: 'PR',
    label: 'Puerto Rico',
    phone: '1',
    icon: '/assets/icons/flags/pr.svg',
    value: 3,
  },
  {
    code: 'PS',
    label: 'Palestine, State of',
    phone: '970',
    icon: '/assets/icons/flags/ps.svg',
    value: 3,
  },
  {
    code: 'PT',
    label: 'Portugal',
    phone: '351',
    icon: '/assets/icons/flags/pt.svg',
    value: 3,
  },
  {
    code: 'PW',
    label: 'Palau',
    phone: '680',
    icon: '/assets/icons/flags/pw.svg',
    value: 3,
  },
  {
    code: 'PY',
    label: 'Paraguay',
    phone: '595',
    icon: '/assets/icons/flags/py.svg',
    value: 3,
  },
  {
    code: 'QA',
    label: 'Qatar',
    phone: '974',
    icon: '/assets/icons/flags/qa.svg',
    value: 3,
  },
  {
    code: 'RE',
    label: 'Reunion',
    phone: '262',
    icon: '/assets/icons/flags/re.svg',
    value: 3,
  },
  {
    code: 'RO',
    label: 'Romania',
    phone: '40',
    icon: '/assets/icons/flags/ro.svg',
    value: 3,
  },
  {
    code: 'RS',
    label: 'Serbia',
    phone: '381',
    icon: '/assets/icons/flags/rs.svg',
    value: 3,
  },
  {
    code: 'RU',
    label: 'Russian Federation',
    phone: '7',
    icon: '/assets/icons/flags/ru.svg',
    value: 3,
  },
  {
    code: 'RW',
    label: 'Rwanda',
    phone: '250',
    icon: '/assets/icons/flags/rw.svg',
    value: 3,
  },
  {
    code: 'SA',
    label: 'Saudi Arabia',
    phone: '966',
    icon: '/assets/icons/flags/sa.svg',
    value: 3,
  },
  {
    code: 'SB',
    label: 'Solomon Islands',
    phone: '677',
    icon: '/assets/icons/flags/sb.svg',
    value: 3,
  },
  {
    code: 'SC',
    label: 'Seychelles',
    phone: '248',
    icon: '/assets/icons/flags/sc.svg',
    value: 3,
  },
  {
    code: 'SD',
    label: 'Sudan',
    phone: '249',
    icon: '/assets/icons/flags/sd.svg',
    value: 3,
  },
  {
    code: 'ES',
    label: 'Spain',
    phone: '34',
    icon: '/assets/icons/flags/es.svg',
    value: 2,
  },
  {
    code: 'SE',
    label: 'Sweden',
    phone: '46',
    icon: '/assets/icons/flags/se.svg',
    value: 3,
  },
  {
    code: 'SG',
    label: 'Singapore',
    phone: '65',
    icon: '/assets/icons/flags/sg.svg',
    value: 3,
  },
  {
    code: 'SH',
    label: 'Saint Helena',
    phone: '290',
    icon: '/assets/icons/flags/sh.svg',
    value: 3,
  },
  {
    code: 'SI',
    label: 'Slovenia',
    phone: '386',
    icon: '/assets/icons/flags/si.svg',
    value: 3,
  },
  {
    code: 'SJ',
    label: 'Svalbard and Jan Mayen',
    phone: '47',
    icon: '/assets/icons/flags/sj.svg',
    value: 3,
  },
  {
    code: 'SK',
    label: 'Slovakia',
    phone: '421',
    icon: '/assets/icons/flags/sk.svg',
    value: 3,
  },
  {
    code: 'SL',
    label: 'Sierra Leone',
    phone: '232',
    icon: '/assets/icons/flags/sl.svg',
    value: 3,
  },
  {
    code: 'SM',
    label: 'San Marino',
    phone: '378',
    icon: '/assets/icons/flags/sm.svg',
    value: 3,
  },
  {
    code: 'SN',
    label: 'Senegal',
    phone: '221',
    icon: '/assets/icons/flags/sn.svg',
    value: 3,
  },
  {
    code: 'SO',
    label: 'Somalia',
    phone: '252',
    icon: '/assets/icons/flags/so.svg',
    value: 3,
  },
  {
    code: 'SR',
    label: 'Suriname',
    phone: '597',
    icon: '/assets/icons/flags/sr.svg',
    value: 3,
  },
  {
    code: 'SS',
    label: 'South Sudan',
    phone: '211',
    icon: '/assets/icons/flags/ss.svg',
    value: 3,
  },
  {
    code: 'ST',
    label: 'Sao Tome and Principe',
    phone: '239',
    icon: '/assets/icons/flags/st.svg',
    value: 3,
  },
  {
    code: 'SV',
    label: 'El Salvador',
    phone: '503',
    icon: '/assets/icons/flags/sv.svg',
    value: 3,
  },
  {
    code: 'SX',
    label: 'Sint Maarten (Dutch part)',
    phone: '1-721',
    icon: '/assets/icons/flags/sx.svg',
    value: 3,
  },
  {
    code: 'SY',
    label: 'Syrian Arab Republic',
    phone: '963',
    icon: '/assets/icons/flags/sy.svg',
    value: 3,
  },
  {
    code: 'SZ',
    label: 'Swaziland',
    phone: '268',
    icon: '/assets/icons/flags/sz.svg',
    value: 3,
  },
  {
    code: 'TC',
    label: 'Turks and Caicos Islands',
    phone: '1-649',
    icon: '/assets/icons/flags/tc.svg',
    value: 3,
  },
  {
    code: 'TD',
    label: 'Chad',
    phone: '235',
    icon: '/assets/icons/flags/td.svg',
    value: 3,
  },
  {
    code: 'TF',
    label: 'French Southern Territories',
    phone: '262',
    icon: '/assets/icons/flags/tf.svg',
    value: 3,
  },
  {
    code: 'TG',
    label: 'Togo',
    phone: '228',
    icon: '/assets/icons/flags/tg.svg',
    value: 3,
  },
  {
    code: 'TH',
    label: 'Thailand',
    phone: '66',
    icon: '/assets/icons/flags/th.svg',
    value: 3,
  },
  {
    code: 'TJ',
    label: 'Tajikistan',
    phone: '992',
    icon: '/assets/icons/flags/tj.svg',
    value: 3,
  },
  {
    code: 'TK',
    label: 'Tokelau',
    phone: '690',
    icon: '/assets/icons/flags/tk.svg',
    value: 3,
  },
  {
    code: 'TL',
    label: 'Timor-Leste',
    phone: '670',
    icon: '/assets/icons/flags/tl.svg',
    value: 3,
  },
  {
    code: 'TM',
    label: 'Turkmenistan',
    phone: '993',
    icon: '/assets/icons/flags/tm.svg',
    value: 3,
  },
  {
    code: 'TN',
    label: 'Tunisia',
    phone: '216',
    icon: '/assets/icons/flags/tn.svg',
    value: 3,
  },
  {
    code: 'TO',
    label: 'Tonga',
    phone: '676',
    icon: '/assets/icons/flags/to.svg',
    value: 3,
  },
  {
    code: 'TR',
    label: 'Turkey',
    phone: '90',
    icon: '/assets/icons/flags/tr.svg',
    value: 3,
  },
  {
    code: 'TT',
    label: 'Trinidad and Tobago',
    phone: '1-868',
    icon: '/assets/icons/flags/tt.svg',
    value: 3,
  },
  {
    code: 'TV',
    label: 'Tuvalu',
    phone: '688',
    icon: '/assets/icons/flags/tv.svg',
    value: 3,
  },
  {
    code: 'TW',
    label: 'Taiwan, Province of China',
    phone: '886',
    icon: '/assets/icons/flags/tw.svg',
    value: 3,
  },
  {
    code: 'TZ',
    label: 'United Republic of Tanzania',
    phone: '255',
    icon: '/assets/icons/flags/tz.svg',
    value: 3,
  },
  {
    code: 'UA',
    label: 'Ukraine',
    phone: '380',
    icon: '/assets/icons/flags/ua.svg',
    value: 3,
  },
  {
    code: 'UG',
    label: 'Uganda',
    phone: '256',
    icon: '/assets/icons/flags/ug.svg',
    value: 3,
  },
  {
    code: 'US',
    label: 'United States',
    phone: '1',
    suggested: true,
    icon: '/assets/icons/flags/us.svg',
    value: 3,
  },
  {
    code: 'UY',
    label: 'Uruguay',
    phone: '598',
    icon: '/assets/icons/flags/uy.svg',
    value: 3,
  },
  {
    code: 'UZ',
    label: 'Uzbekistan',
    phone: '998',
    icon: '/assets/icons/flags//uz.svg',
    value: 3,
  },
  {
    code: 'VA',
    label: 'Holy See (Vatican City State)',
    phone: '379',
    icon: '/assets/icons/flags/va.svg',
    value: 3,
  },
  {
    code: 'VC',
    label: 'Saint Vincent and the Grenadines',
    phone: '1-784',
    icon: '/assets/icons/flags/vc.svg',
    value: 3,
  },
  {
    code: 'VE',
    label: 'Venezuela',
    phone: '58',
    icon: '/assets/icons/flags/ve.svg',
    value: 3,
  },
  {
    code: 'VG',
    label: 'British Virgin Islands',
    phone: '1-284',
    icon: '/assets/icons/flags/vg.svg',
    value: 3,
  },
  {
    code: 'VI',
    label: 'US Virgin Islands',
    phone: '1-340',
    icon: '/assets/icons/flags/vi.svg',
    value: 3,
  },
  {
    code: 'VN',
    label: 'Vietnam',
    phone: '84',
    icon: '/assets/icons/flags/vn.svg',
    value: 3,
  },
  {
    code: 'VU',
    label: 'Vanuatu',
    phone: '678',
    icon: '/assets/icons/flags/vu.svg',
    value: 3,
  },
  {
    code: 'WF',
    label: 'Wallis and Futuna',
    phone: '681',
    icon: '/assets/icons/flags/wf.svg',
    value: 3,
  },
  {
    code: 'WS',
    label: 'Samoa',
    phone: '685',
    icon: '/assets/icons/flags/ws.svg',
    value: 3,
  },
  {
    code: 'XK',
    label: 'Kosovo',
    phone: '383',
    icon: '/assets/icons/flags/xk.svg',
    value: 3,
  },
  {
    code: 'YE',
    label: 'Yemen',
    phone: '967',
    icon: '/assets/icons/flags/ye.svg',
    value: 3,
  },
  {
    code: 'YT',
    label: 'Mayotte',
    phone: '262',
    icon: '/assets/icons/flags/yt.svg',
    value: 3,
  },
  {
    code: 'ZA',
    label: 'South Africa',
    phone: '27',
    icon: '/assets/icons/flags/za.svg',
    value: 3,
  },
  {
    code: 'ZM',
    label: 'Zambia',
    phone: '260',
    icon: '/assets/icons/flags/zm.svg',
    value: 3,
  },
  {
    code: 'ZW',
    label: 'Zimbabwe',
    phone: '263',
    icon: '/assets/icons/flags/zw.svg',
    value: 3,
  },
];
