import { m } from 'framer-motion';
// @mui
import { Container, Typography } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../components/animate';
// Hooks
import { useAuthContext } from './useAuthContext';
import { useLocales } from '../locales';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  hasContent?: boolean;
  freemium?: boolean;
  verifyUser?: boolean;
  roles?: string[];
  markets?: string[]; // List of market IDs
  redirectPath?: string;
  children: React.ReactNode;
};

/**
 * Component to guard access to content based on user roles and market permissions.
 *
 * @param {boolean} hasContent - If true, displays a "no access" message when access is denied.
 * @param {boolean} verifyUser - If true, ensures the user is authenticated before granting access.
 * @param {string[]} roles - Array of roles that are allowed to access the content.
 * @param {string[]} markets - Array of market IDs that are allowed to access the content.
 * @param {React.ReactNode} children - The content to be conditionally rendered.
 *
 * @returns {React.ReactNode} The children if access is granted, otherwise a "no access" message or null.
 */
export default function RoleBasedGuard({
  hasContent,
  verifyUser,
  roles,
  markets,
  redirectPath,
  children,
  freemium = false,
}: RoleBasedGuardProp) {
  const { translate } = useLocales();
  const navigate = useNavigate();

  // Get user role and market from the auth context
  const { user, isAuthenticated } = useAuthContext();
  const currentRole = user?.roles || ['']; // admin;
  const currentMarket = user?.market?.id;

  // Check if the user's roles match the required roles
  const compareRoles = () => {
    const value = currentRole.some((role: string) => roles?.includes(role));
    return value;
  };

  // Check if the user's market matches the required markets
  const compareMarkets = () => {
    return markets?.includes(currentMarket);
  };
  const compareFreemium = () => {
    return freemium;
  };

  if (
    (!(isAuthenticated && verifyUser) && typeof roles !== 'undefined' && !compareRoles()) ||
    (typeof markets !== 'undefined' && !compareMarkets()) ||
    (user?.freemium_signup && !compareFreemium())
  ) {
    // Redirect to the specified path if access is denied
    if (redirectPath) {
      navigate(!compareFreemium() ? '/ai' : redirectPath);
      return null;
    }
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            {`${translate('404.noAccess')}`}
          </Typography>
        </m.div>
      </Container>
    ) : null;
  }

  return <> {children} </>;
}
