import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { IPayslipState } from '../../@types/payslip';

const initialState: IPayslipState = {
  isLoading: false,
  error: null,
  issuesWithTransfers: [],
  payslips: [],
  payslip: null,
  temporaryPayslips: [],
};

const slice = createSlice({
  name: 'payslips',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
      state.issuesWithTransfers = [];
    },
    endLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET All Payslips
    getPayslipsSuccess(state, action) {
      state.isLoading = false;
      state.payslips = action.payload;
    },
    issuesWithTeachers(state, action) {
      state.isLoading = false;
      state.issuesWithTransfers = action.payload;
    },
    payoutPayslipsSuccess(state, action) {
      state.isLoading = false;
      state.payslips = [...state.payslips, ...action.payload];
    },
    // GET Specific Payslip by ID
    getPayslipSuccess(state, action) {
      state.isLoading = false;
      state.payslip = action.payload.data;
    },
    getTemporaryPayslipsSuccess(state, action) {
      state.isLoading = false;
      state.temporaryPayslips = action.payload.data;
    },
  },
});

export default slice.reducer;

export function getPayslips() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/wagepayments', { withCredentials: true });
      // console.log(response);
      dispatch(slice.actions.getPayslipsSuccess(response.data.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getPayslip(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/wagepayment/${id}`, { withCredentials: true });
      if (response.status === 404) {
        throw new Error('Payslip not found');
      } else {
        dispatch(slice.actions.getPayslipSuccess(response));
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getTutorPayslips(tutor_id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/tutor_wagepayments/${tutor_id}`, {
        params: { tutor_id },
        withCredentials: true,
      });
      if (response.status === 404) {
        throw new Error('Payslips not found');
      } else {
        dispatch(slice.actions.getPayslipsSuccess(response.data));
        return true;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function calculateMontlyPayslipExcel(
  start_date: string,
  end_date: string,
  market_id: number
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log(start_date);
      // console.log(end_date);
      const response = await axios.post(
        '/api/download_wages_hours',
        {
          start_date: start_date,
          end_date: end_date,
          market_id: market_id,
        },
        { withCredentials: true }
      );

      // console.log(response.data.file_name);

      const download = await axios
        .post(
          '/api/download',
          {
            file_name: response.data.file_name,
          },
          {
            responseType: 'blob',
            headers: {
              Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
            withCredentials: true,
          }
        )
        .then((result) => {
          const href = URL.createObjectURL(result.data);

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `wages_${start_date}_${end_date}.xlsx`); //or any other extension
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });

      const deleteFile = await axios.post('/api/delete_excel_file', {
        file_name: response.data.file_name,
      });

      dispatch(slice.actions.endLoading());
      return true;
      // console.log(response);
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function calculateMontlyPayslip(start_date: string, end_date: string, market_id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log(start_date);
      // console.log(end_date);
      const response = await axios.post(
        '/api/calculate_wages_hours',
        {
          start_date: start_date,
          end_date: end_date,
          market_id: market_id,
        },
        { withCredentials: true }
      );
      dispatch(slice.actions.getTemporaryPayslipsSuccess(response));
      return true;
      // console.log(response);
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function transferFunds(
  start_date: string,
  end_date: string,
  payment_date: string,
  market_id: number
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/api/transfer_wages',
        {
          payment_date: payment_date,
          start_date: start_date,
          end_date: end_date,
          market_id: market_id,
        },
        { withCredentials: true }
      );
      if (response.status !== 201) {
        throw Error('Error paying out funds');
      }
      dispatch(slice.actions.getPayslipsSuccess([]));
      const issues = response.data.issues_with_teachers;
      if (issues.length > 0) {
        dispatch(slice.actions.issuesWithTeachers(issues));
      }
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function createWagePayments(
  start_date: string,
  end_date: string,
  payment_date: string,
  market_id: number
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // console.log({
      //   payment_date: payment_date,
      //   start_date: start_date,
      //   end_date: end_date,
      //   market_id: market_id,
      // });
      const response = await axios.post(
        '/api/bulk_wagepayments',
        {
          payment_date: payment_date,
          start_date: start_date,
          end_date: end_date,
          market_id: market_id,
        },
        { withCredentials: true }
      );
      window.open(response.data.download_link, '_blank', 'noreferrer');
      dispatch(slice.actions.payoutPayslipsSuccess(response.data.wagepayments));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
