// React
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  AppBar,
  Toolbar,
  Box,
  BoxProps,
  Link,
  Stack,
  Button,
  IconButton,
  Typography,
} from '@mui/material';
// config
import { HEADER } from '../../config-global';
// utils
import { bgBlur } from '../../utils/cssStyles';
// components
import Logo from '../../components/logo';
import LanguagePopover from '../../layouts/dashboard/header/LanguagePopover';
import Iconify from 'src/components/iconify';
import SignUpPopUp from 'src/sections/@dashboard/components/SignUpPopUp';
// hooks
import { useAuthContext } from '../../auth/useAuthContext';
import { useLocales } from '../../locales';
import useResponsive from '../../hooks/useResponsive';

// ----------------------------------------------------------------------

type Props = {
  isOffset: boolean;
  onOpenNav?: VoidFunction;
};

export default function Header({ isOffset, onOpenNav }: Props) {
  const theme = useTheme();
  const { translate } = useLocales();
  const { user } = useAuthContext();
  const [openRegisterPopUp, setOpenRegisterPopUp] = useState(false);

  const isMobile = !useResponsive('up', 1200);
  const isDesktop = useResponsive('up', 'md');
  return (
    <AppBar color="transparent" sx={{ boxShadow: 0 }}>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        {!isDesktop && (
          <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButton>
        )}
        <Logo />

        <Stack direction="row" gap={2}>
          {/* if user is not logged in show the log in CTA on desktop */}
          {!user && isDesktop && (
            <>
              <Link
                href="/auth/login"
                underline="none"
                sx={{ display: 'flex', alignItems: 'center', color: 'black' }}
              >
                <Typography variant="body1" noWrap color={theme.palette.common.black}>
                  {String(translate('forgotPage.login'))}
                </Typography>
              </Link>
              <Button
                color="inherit"
                sx={{
                  width: '100%',
                  size: 'small',
                  px: 4,
                  py: 2,
                  maxHeight: isMobile ? '45px' : 'initial',
                  backgroundColor: theme.palette.error.main,
                  color: theme.palette.error.contrastText,
                  borderRadius: theme.palette.borders.borderRadius,
                  boxShadow: theme.palette.borders.boxShadowError,
                  transition: 'background-color 0.3s, box-shadow 0.3s, transform 0.3s',
                  '&:hover': {
                    color: theme.palette.error.contrastText,
                    backgroundColor: theme.palette.error.main,
                    boxShadow: theme.palette.borders.boxShadowHoverError,
                  },
                }}
                onClick={() => {
                  setOpenRegisterPopUp(true);
                }}
              >
                <Typography variant="body1" noWrap>
                  {String(translate('freemium.getStartedForFree'))}
                </Typography>
              </Button>
            </>
          )}

          <LanguagePopover />
        </Stack>
        {openRegisterPopUp && (
          <SignUpPopUp
            initialOpen={openRegisterPopUp}
            loginPageSignUp={true}
            loginPageClose={setOpenRegisterPopUp}
          />
        )}
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
