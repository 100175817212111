import { useEffect, useMemo, useState } from 'react';
// form
import { useForm } from 'react-hook-form';

// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Grid, Button, Link, Typography, Stack, Checkbox } from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
// utils
import { fCurrency, fPercent } from '../../../utils/formatNumber';
import { calculateExactMonthDifference } from '../../../utils/utils';
// select options
import { getMemberships, discounts } from '../../../assets/data/memberships';
import { currency } from '../../../assets/data/currency';
// routes
// @types
import { IInvoice } from '../../../@types/invoice';
import { ICustomerPagination, ICustomer } from '../../../@types/customer';

import { useLocales } from 'src/locales';

// components
import { CustomFile } from '../../../components/upload';
import { useSnackbar } from '../../../components/snackbar';

import FormProvider, {
  RHFAutocomplete,
  RHFSelect,
  RHFTextField,
} from '../../../components/hook-form';
import { IPricing } from '../../../@types/pricing';
import { useAuthContext } from 'src/auth/useAuthContext';
import Iconify from 'src/components/iconify/Iconify';

// ----------------------------------------------------------------------
interface CustomerIdObject {
  label: string;
  id: string;
}

interface FormValuesProps extends Omit<IInvoice, 'avatarUrl' | 'customer_id'> {
  avatarUrl: CustomFile | string | null;
  plan_selected: string;
  installments: number;
  email: string;
  customer_id: CustomerIdObject;
}

type Props = {
  invoice: IInvoice | null;
  old_invoice: IInvoice | null;
  admin: boolean;
  superAdmin: boolean;
  customers?: ICustomerPagination;
  pricings?: IPricing[];
  handleSwitch: (form: IInvoice) => void;
};

export default function SwitchMembershipForm({
  invoice,
  old_invoice,
  customers,
  pricings,
  admin,
  superAdmin,
  handleSwitch,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthContext();

  const { translate } = useLocales();

  const theme = useTheme();

  const memberships = useMemo(() => getMemberships(theme, translate), [theme, translate]);

  const defaultValuesIndependent = useMemo(
    () => ({
      id: invoice?.id || '',
      status: invoice?.status || '',
      name: invoice?.name || '',
      email: '',
      customer_id: { label: invoice?.customer_id, id: invoice?.customer_id } || {
        label: '',
        id: '',
      },
      email_sent: invoice?.email_sent || true,
      churn_penalty: invoice?.churn_penalty || false,
      discount: invoice?.discount || '',
      stripe_id: invoice?.stripe_invoice_id || '',
      expiration_date:
        invoice?.expiration_date || new Date(new Date().getFullYear(), new Date().getMonth() + 1),
      extra_student: invoice?.extra_student || 0,
      plan_selected: invoice?.type_order?.split('-')[0] || '6',
      amount: invoice?.total_price || 0,
      total_hours:
        invoice?.total_hours ||
        parseInt(invoice?.type_order?.split('-')[0] || '6') * (invoice?.installments || 6) ||
        0,
      installments: invoice?.installments || 12,
      currency: invoice?.currency || user?.market?.currency || 'DKK',
      pricing_id: invoice?.pricing_id || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invoice]
  );
  const methods = useForm<FormValuesProps>({
    defaultValues: defaultValuesIndependent,
  });
  const { reset, watch, setValue, handleSubmit } = methods;

  const values = watch();
  const isEdit = invoice?.status === 'pending' || !invoice ? false : true;
  const [nonStandardMembership, setNonStandardMembership] = useState(false);

  useEffect(() => {
    if (invoice) {
      setPrice(invoice.unit_price);
      reset(defaultValuesIndependent);
    }
  }, [invoice]);

  const price_compare =
    pricings?.find((value) => value.infliction_point === 0)?.price_per_hour || 0;
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [price, setPrice] = useState(0);
  // State for acceptTerms with default value based on admin
  const [acceaptTerms, setAcceptTerms] = useState(false);

  const [discountvisual, setDiscountVisual] = useState(1);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const hours = data.installments * parseInt(data.plan_selected);
      const installments = data.installments;
      const discount = fCurrency((1 - parseFloat(data?.discount || '1')) * 100);

      if (acceaptTerms) {
        handleSwitch({
          ...data,
          customer_id: data.customer_id.id,
          email: email,
          name: name,
          total_hours: Number(hours),
          installments: installments,
          membership: true,
          discount: parseFloat(discount) === 0 ? undefined : discount.toString(),
          stripe_invoice_id: `${data.plan_selected}-months-${installments}`,
          pricing_id: data.pricing_id,
        });
      } else {
        enqueueSnackbar(`${translate('snackBar.acceptTCs')}`, { variant: 'error' });
      }

      reset();
    } catch (err) {
      console.log(err);
      enqueueSnackbar(`${translate('snackBar.orderCreatingError')}`, { variant: 'success' });
    }
  };

  // Effect for managing plan selection changes.
  useEffect(() => {
    // console.log('Values run');
    if (!values?.plan_selected) return;

    const plan = memberships.find((v) => v.code === values.plan_selected);
    setValue('discount', '');
    if (!plan) return;
    setValue('total_hours', Number(plan.code) || 6);
    setAcceptTerms(false);
    const newPrice = pricings?.find((v) => v.infliction_point === values.installments * plan.value);

    setNonStandardMembership(!plan.standard);

    if (newPrice) {
      setPrice(newPrice.price_per_hour);
      setDiscountVisual(newPrice.price_per_hour / price_compare);
      setValue('pricing_id', newPrice.id.toString());
    }
  }, [values.plan_selected, values.installments, pricings, setValue, price_compare]);

  useEffect(() => {
    // console.log('Discount run');
    // console.log(values);
    if (values?.discount !== '') {
      setDiscountVisual(price / price_compare - (1 - parseFloat(values?.discount || '1')));
    }
  }, [values?.discount, price, price_compare]);

  // calculates the monthly difference between the booking date and the current date
  // if in the same month it will be calculated as a 0
  const dateDiff = invoice
    ? calculateExactMonthDifference(
        new Date(invoice?.booking_date),
        new Date(invoice?.expiration_date)
      )
    : 1;
  // Penalty calculation for admin and non-admin views
  const penalty = admin
    ? invoice
      ? (price - invoice?.unit_price) *
        invoice?.credits_per_month *
        (dateDiff - invoice.paused_periods)
      : 0
    : old_invoice
    ? (price - old_invoice?.unit_price) *
      old_invoice?.credits_per_month *
      (dateDiff - old_invoice.paused_periods)
    : 0;
  // console.log(invoice?.credits_per_month);
  // console.log(invoice?.unit_price);
  // console.log(price);
  // console.log(dateDiff);
  // console.log(invoice?.paused_periods);
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1} sx={{ mb: 10 }}>
        <Grid item xs={12} md={7}>
          <Card
            sx={{
              p: 2,
              [theme.breakpoints.up('md')]: {
                borderRadius: '20px 0px 0px 20px',
              },
            }}
          >
            <Box rowGap={2} columnGap={2} display="grid">
              <Grid item xs={12}>
                <Typography sx={{ mt: '20px', textAlign: 'center' }} variant="h3" color={'primary'}>
                  {`${translate('memberships.viewMembership')}`}
                </Typography>
                <Typography sx={{ m: '10px', textAlign: 'center' }} variant="h5">
                  {`${translate('memberships.selectMembership')}`}
                </Typography>
                {/* <Grid container justifyContent="center">
                  <Grid item xs={12} lg={8}>
                    <Typography sx={{ mb: '20px', textAlign: 'center' }} variant="body2">
                      {`${translate('memberships.selectMembershipBody')}`}
                    </Typography>
                  </Grid>
                </Grid> */}
                <Stack direction="row" spacing={2} paddingLeft={5} paddingRight={5} paddingTop={3}>
                  {memberships.map((value) =>
                    nonStandardMembership !== value.standard ? (
                      <div
                        style={{ width: '100%' }}
                        onClick={() => {
                          setValue('plan_selected', value.code);
                        }}
                        key={value.code}
                      >
                        <Card
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: '100%',
                            border:
                              value.code === values.plan_selected
                                ? `2px solid ${theme.palette.primary.main}`
                                : 'none',
                            transform:
                              value.code === values.plan_selected ? 'scale(1.075)' : 'scale(1.0)',
                          }}
                        >
                          <Stack
                            direction="column"
                            style={{
                              padding: 20,
                              textAlign: 'center',
                              minHeight: 300,
                            }}
                            justifyContent="space-between"
                          >
                            {value.attentionText ? (
                              <Typography
                                variant="body2"
                                style={{
                                  color: value.attentionTextColor,
                                  backgroundColor: value.attentionBackgroundColor,
                                  borderRadius: 10,
                                }}
                              >
                                {value.attentionText}
                              </Typography>
                            ) : (
                              <Typography style={{ visibility: 'hidden' }}>hidden</Typography>
                            )}
                            <div style={{ paddingTop: 20, paddingBottom: 30 }}>
                              {value.icon && (
                                <Iconify
                                  icon={value.icon}
                                  color={theme.palette.error.main}
                                  style={{ width: '40px', height: '40px', margin: 'auto' }}
                                />
                              )}
                              <Typography variant="h5">{value.title}</Typography>
                              <Typography
                                variant="caption"
                                color={alpha(theme.palette.common.black, 0.75)}
                              >
                                {value.description}
                              </Typography>
                            </div>

                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Typography variant="h6" style={{ marginRight: '5px' }}>
                                {value.label}
                              </Typography>
                              <Typography
                                variant="caption"
                                color={alpha(theme.palette.common.black, 0.75)}
                              >
                                {`${translate('memberships.perWeek')}`}
                              </Typography>
                            </div>
                            <Typography
                              variant="caption"
                              fontSize={'0.8rem'}
                              mt={1}
                              color={alpha(theme.palette.common.black, 0.75)}
                            >
                              {value.value} {`${translate('memberships.every4Weeks')}`}
                            </Typography>
                          </Stack>
                        </Card>
                      </div>
                    ) : (
                      <></>
                    )
                  )}
                </Stack>
              </Grid>

              {admin && (
                <>
                  {customers && (
                    <Grid item xs={12}>
                      <RHFAutocomplete
                        disabled={isEdit}
                        required
                        autoComplete
                        autoHighlight
                        autoSelect
                        label="Kunde"
                        name="customer_id"
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={customers?.data.map((value: ICustomer) => ({
                          label: `${value.first_name} ${value.last_name} (${value.id})`,
                          id: value.id,
                        }))}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <RHFTextField
                      disabled={isEdit}
                      label="Antal ekstra elever"
                      name="extra_student"
                      type="number"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <RHFSelect disabled={isEdit} type="number" native label="Rabat" name="discount">
                      <option />
                      {discounts.map((value) => (
                        <option label={value.label} value={value.value} key={value.code}>
                          {value.label}
                        </option>
                      ))}
                    </RHFSelect>
                  </Grid>
                </>
              )}

              {superAdmin && (
                <Grid item xs={12}>
                  <RHFSelect disabled={isEdit} native label="Currency" name="currency">
                    {currency.map((value) => (
                      <option label={value.label} value={value.value} key={value.code}>
                        {value.label}
                      </option>
                    ))}
                  </RHFSelect>
                </Grid>
              )}

              {admin && (
                <Grid item xs={12} mt={2}>
                  <Checkbox defaultChecked name="email_sent" />
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: 15,
                      p: (theme) => theme.spacing(0.75),
                    }}
                  >
                    Send Email
                  </Typography>
                </Grid>
              )}

              <Grid item xs={6}>
                <Button
                  variant="outlined"
                  onClick={() => setNonStandardMembership(!nonStandardMembership)}
                >
                  {nonStandardMembership
                    ? `${translate('memberships.standardMembership')}`
                    : `${translate('memberships.nonStandardMembership')}`}
                </Button>
              </Grid>
            </Box>
            <Commitment />
          </Card>
        </Grid>
        <Grid style={{ paddingLeft: '0px' }} item xs={12} md={5}>
          <Card
            sx={{
              p: 2,
              backgroundColor: theme.palette.background.neutral,
              height: '100%',
              [theme.breakpoints.up('md')]: { borderRadius: '0px 20px 20px 0px' },
            }}
          >
            <Box rowGap={3} columnGap={2} display="grid">
              <Grid container direction="row" justifyContent="space-evenly" spacing={1}>
                <Grid item xs={12}>
                  <Typography sx={{ mb: '10px' }} variant="h5">
                    {`${translate('memberships.orderOverview')}`}
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography color={alpha(theme.palette.common.black, 0.75)}>
                    {`${translate('memberships.hours')}`}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color={theme.palette.common.black}>{values.total_hours}</Typography>
                </Grid>
                {/* <Grid item xs={12}>
                  <hr />
                </Grid> */}
                <Grid item xs={8}>
                  <Typography color={alpha(theme.palette.common.black, 0.75)}>
                    {discountvisual !== 1
                      ? `${translate('memberships.normalPrice')}`
                      : `${translate('memberships.yourPrice')}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  color={theme.palette.common.black}
                  xs={4}
                  sx={{ textDecoration: discountvisual !== 1 ? 'line-through' : 'none' }}
                >
                  {`${fCurrency(price_compare + values?.extra_student * 50 || 0)} ${
                    values.currency
                  }`}
                </Grid>

                {discountvisual !== 1 && (
                  <>
                    <Grid item xs={8}>
                      <Typography color={alpha(theme.palette.common.black, 0.75)}>
                        {`${translate('memberships.yourPrice')}`}
                      </Typography>
                    </Grid>{' '}
                    <Grid item xs={4} color={theme.palette.common.black}>
                      {`${fCurrency(
                        (price_compare + values?.extra_student * 50 || 0) * discountvisual
                      )} ${values.currency}`}
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <hr />
                </Grid>
                {discountvisual !== 1 && (
                  <>
                    <Grid item xs={8}>
                      <Typography sx={{ color: theme.palette.success.main }}>
                        {`${translate('memberships.discount')}`} (
                        {fPercent((1 - discountvisual) * 100, false)})
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h5" sx={{ color: theme.palette.success.main }}>
                        {`${fCurrency(
                          -(
                            price_compare +
                            values?.extra_student * (50 || 0) -
                            (price_compare + values?.extra_student * (50 || 0)) * discountvisual
                          ) * values?.total_hours
                        )} ${values.currency}`}
                      </Typography>
                    </Grid>
                  </>
                )}

                {/* <Grid item xs={8}>
                  <Typography variant="h6">{`${translate('memberships.setupFee')}`}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h6" color={theme.palette.primary.main}>{`${fCurrency(0)} ${
                    values.currency
                  }`}</Typography>
                </Grid> */}
                <>
                  {penalty > 0 && (
                    <>
                      <Grid item xs={8} mt={2}>
                        <Typography color={theme.palette.error.dark}>
                          {`${translate('memberships.penalty')}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} mt={2}>
                        <Typography color={theme.palette.error.dark} variant="h4">{`${fCurrency(
                          penalty
                        )} ${values.currency}`}</Typography>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={8} mt={2}>
                    <Typography color={alpha(theme.palette.common.black, 0.75)}>{`${translate(
                      'memberships.total'
                    )}`}</Typography>
                  </Grid>
                  <Grid item xs={4} mt={2}>
                    <Typography variant="h4">{`${fCurrency(
                      invoice &&
                        (price_compare + values?.extra_student * 50 || 0) *
                          discountvisual *
                          values?.total_hours
                    )} ${values.currency}`}</Typography>
                  </Grid>

                  <Grid item xs={12} mt={2}>
                    <Checkbox
                      value={acceaptTerms}
                      onChange={(e, boolValue) => setAcceptTerms(boolValue)}
                      required
                      sx={{ p: 0 }}
                    />
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: 15,
                        p: (theme) => theme.spacing(0.75),
                      }}
                    >
                      <Link
                        sx={{ color: alpha(theme.palette.common.black, 0.75) }}
                        onClick={() => {
                          if (invoice?.market_id === '2') {
                            window.open('https://www.toptutors.com/es-es/terminos-y-condiciones');
                          } else {
                            window.open('https://www.toptutors.com/betingelser');
                          }
                        }}
                      >
                        {`${translate('memberships.acceptTCs')}`}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <LoadingButton
                      type="submit"
                      color="success"
                      variant="contained"
                      size="large"
                      disabled={!acceaptTerms}
                      sx={{
                        mt: 2,
                        mb: 5,
                        width: '100%',
                        height: '50px',
                        '&.Mui-disabled': {
                          background: alpha(theme.palette.success.main, 0.4),
                          color: theme.palette.success.contrastText,
                        },
                      }}
                    >
                      {admin
                        ? `${translate('memberships.sendOrder')}`
                        : `${translate('memberships.confirmMembership')}`}
                    </LoadingButton>
                  </Grid>
                </>
              </Grid>
            </Box>
            <Guarantee />
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}

export function Guarantee() {
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Stack direction="column" alignItems="center">
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Iconify icon="ph:seal-check-bold" color={theme.palette.success.main} width={25} />
        <Typography variant="h5" color={theme.palette.success.main} sx={{ ml: 0.5 }}>
          {`${translate('memberships.guaranteeTitle')}`}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="center">
        <Typography style={{ width: '75%' }} variant="body2" textAlign="center">
          {`${translate('memberships.guaranteeBody')}`}
        </Typography>
      </Stack>

      <InformationBox />
      <Box component="img" src="/assets/trustpilot_logo.svg" sx={{ height: 30, mt: '30px' }} />
      <Box component="img" src="/assets/Trustpilot_ratings_4halfstar.svg" sx={{ height: 30 }} />
    </Stack>
  );
}

export function Commitment() {
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Stack direction="column" alignItems="center" sx={{ my: 3 }}>
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Iconify icon="ph:seal-check-bold" color={theme.palette.success.main} width={25} />
        <Typography variant="h5" color={theme.palette.success.main} sx={{ ml: 0.5 }}>
          {`${translate('memberships.commitmentTitle')}`}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="center">
        <Typography style={{ width: '75%' }} variant="body2" textAlign="center">
          {`${translate('memberships.commitmentBody')}`}
        </Typography>
      </Stack>
    </Stack>
  );
}

export function InformationBox() {
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Stack
      direction="column"
      sx={{
        my: 3,
        mx: 3,
        backgroundColor: theme.palette.info.main,
        borderRadius: '16px',
        p: theme.spacing(2),
      }}
    >
      <Stack direction="row" alignItems="center">
        <Iconify icon="ph:info" color={theme.palette.info.highlightText} width={25} />
        <Typography variant="body1" color={theme.palette.info.highlightText} sx={{ ml: 0.5 }}>
          {`${translate('memberships.infoHeader')}`}
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="caption" color={theme.palette.info.contrastText} sx={{ mt: 1 }}>
          {`${translate('memberships.selectMembershipBody')}`}
        </Typography>
      </Stack>
    </Stack>
  );
}
