// react
import { useState } from 'react';
// @mui
import {
  Box,
  Button,
  IconButton,
  DialogActions,
  Dialog,
  DialogTitle,
  FormControl,
  InputLabel,
  Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// select data
import { cancellations } from '../../../assets/data';
import { useLocales } from '../../../locales';

// components
import Iconify from '../../../components/iconify';

type CancellationProps = {
  cancel: boolean;
  admin?: boolean;
  teacher?: boolean;
  onCancel: (state: boolean) => void;
  cancelLesson: (reason: string) => void;
  recurring?: boolean;
};

export function CancelLessonDialog({
  cancelLesson,
  cancel,
  admin,
  teacher,
  onCancel,
  recurring,
}: CancellationProps) {
  const [reason, setReason] = useState('');
  const handleChange = (event: SelectChangeEvent) => {
    // console.log(event.target.value);
    setReason(event.target.value as string);
  };
  const { translate } = useLocales();
  return (
    <Dialog fullWidth maxWidth="xs" open={cancel} onClose={() => onCancel(false)}>
      <DialogTitle>
        {admin
          ? `${translate('lesson.cancelLessonTitle')}`
          : `${translate('lesson.cancelLessonTitleNonAdmin')}`}
        <IconButton
          sx={{ position: 'absolute', right: 8, top: 5 }}
          color="error"
          onClick={() => onCancel(false)}
        >
          <Iconify icon="ic:sharp-close" />
        </IconButton>
      </DialogTitle>
      <DialogActions>
        <Box display="flex" flexDirection="column" alignItems="stretch" width="100%">
          {(admin || teacher) && (
            <FormControl>
              {reason === '' && (
                <InputLabel> {`${translate('lesson.chooseCancellationReason')}`}</InputLabel>
              )}
              <Select
                labelId="cancel-reason-label"
                onChange={(option: SelectChangeEvent) => {
                  // console.log(option);
                  handleChange(option);
                }}
                native
                name="reason"
              >
                <option />
                {cancellations.map((cancellation) => (
                  <option
                    // label={cancellation.label}
                    key={cancellation.code}
                    // id={cancellation.code}
                    value={cancellation.value}
                  >
                    {translate(`lesson.${cancellation.key}`).toString()}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}

          <Button
            onClick={() => {
              cancelLesson(reason);
              onCancel(false);
            }}
            variant="contained"
            color="error"
            sx={{ mt: 2 }}
          >
            {`${translate('lesson.cancelThisLesson')}`}
          </Button>
          {recurring && (
            <Box sx={{ pl: 3, pt: 1, pb: 1 }}>
              <Typography color={'error'} variant="h6">
                {`${translate('lesson.important')}`}
              </Typography>
              <Typography paragraph>{`${translate('lesson.recurringLessonNote')}`}</Typography>
            </Box>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
}
