import { useState } from 'react';
// mui
import {
  Button,
  Box,
  Stack,
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from '@mui/material';
// hooks
import { useSnackbar } from '../../../components/snackbar';
import { useAuthContext } from '../../../auth/useAuthContext';
import { useNavigate } from 'react-router-dom';
import { useTheme, alpha } from '@mui/material/styles';
import { useLocales } from '../../../locales';
// components
import Iconify from '../../../components/iconify/Iconify';
// redux
import { resetAiToken } from '../../../redux/slices/invoice';
import { useDispatch } from '../../../redux/store';
import useResponsive from 'src/hooks/useResponsive';

import { BootstrapTooltip } from './PurchaseTokensButton';

type Props = {
  initialOpen?: boolean;
  dontShowButton?: boolean;
};

const ResetAiTokensButton = ({ initialOpen, dontShowButton = true }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const { user } = useAuthContext();

  const [open, setOpen] = useState(initialOpen ? initialOpen : false);
  const [isLoading, setIsLoading] = useState(false);

  const handleReset = async () => {
    try {
      setIsLoading(true);
      const response = await dispatch(resetAiToken(user?.customer_dict.id));
      if (response) {
        setIsLoading(false);
        navigate(`/family/${user?.customer_dict.id}`);
        enqueueSnackbar(String(translate('freemium.resetTokensSuccess')), { variant: 'success' });
      } else {
        throw new Error('Order not created');
      }
      setOpen(false);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(String(translate('freemium.resetTokensError')), { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };
  const isMobile = !useResponsive('up', 1200);

  return (
    <>
      {!dontShowButton && (
        <Tooltip title={String(translate('freemium.resetTokens'))}>
          <IconButton
            color="inherit"
            sx={{ color: theme.palette.common.black }}
            onClick={() => setOpen(true)}
          >
            <Iconify icon="ph:arrow-counter-clockwise" />
          </IconButton>
        </Tooltip>
      )}

      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            p: 3,
            borderRadius: 2,
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h4" align="center" gutterBottom>
            {String(translate('freemium.resetTokens'))}
          </Typography>
        </DialogTitle>

        {isLoading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '200px',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogContent>
              <Stack
                direction={isMobile ? 'column' : 'row'}
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <Stack direction={'row'} alignItems="center" spacing={1}>
                  <Iconify icon="ph:check" color={theme.palette.success.main} />
                  <Typography variant="subtitle1">
                    {String(translate('freemium.renewalDescription'))}
                  </Typography>
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', gap: 2 }}>
              <Button
                variant="contained"
                color="success"
                size="large"
                onClick={handleReset}
                sx={{
                  minWidth: '300px',
                  '&.Mui-disabled': {
                    backgroundColor: alpha(theme.palette.success.main, 0.4),
                    color: theme.palette.success.contrastText,
                  },
                  borderRadius: theme.palette.borders.borderRadius,
                  boxShadow: theme.palette.borders.boxShadow,
                  border: theme.palette.borders.border,
                  maxWidth: '500px',
                  '&:hover': {
                    color: theme.palette.success.contrastText,
                    border: theme.palette.borders.border,
                    backgroundColor: theme.palette.success.main,
                    boxShadow: theme.palette.borders.boxShadow,
                  },
                }}
                endIcon={<Iconify icon="ph:caret-right" />}
              >
                {String(translate('ai.continue'))}
              </Button>
            </DialogActions>
          </>
        )}
        <BootstrapTooltip placement="top" title={String(translate('freemium.resetTokensTooltip'))}>
          <IconButton
            sx={{
              position: 'absolute',
              right: 5,
              top: 5,
            }}
          >
            <Iconify icon="ph:info-fill" width={35} />
          </IconButton>
        </BootstrapTooltip>
      </Dialog>
    </>
  );
};

export default ResetAiTokensButton;
