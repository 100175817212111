import keyBy from 'lodash/keyBy';
import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
// @types
import { IChatState } from '../../@types/chat';

import { allowedFileTypes } from '../../assets/data/allowedFileTypes';

// ----------------------------------------------------------------------

type NewMessage = {
  id: number;
  message: string;
  conversation_uuid: string;
  content_type: string;
  attachments: string[];
  created_at: Date;
  sender_id: number;
};

const initialState: IChatState = {
  isLoading: false,
  error: null,
  contacts: { byId: {}, allIds: [] },
  conversations: { byId: {}, allIds: [] },
  activeConversationId: null,
  participants: [],
  recipients: [],
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTACT SSUCCESS
    getContactsSuccess(state, action) {
      const contacts = action.payload;

      state.contacts.byId = keyBy(contacts, 'uid');
      state.contacts.allIds = Object.keys(state.contacts.byId);
    },

    // GET CONVERSATIONS
    getConversationsSuccess(state, action) {
      const conversations = action.payload;
      state.conversations.byId = keyBy(conversations, 'id');
      state.conversations.allIds = Object.keys(state.conversations.byId);
    },

    // GET CONVERSATION
    getConversationSuccess(state, action) {
      const conversation = action.payload;

      if (conversation) {
        state.conversations.byId[conversation.id] = conversation;
        state.activeConversationId = conversation.id;
        if (!state.conversations.allIds.includes(conversation.id)) {
          state.conversations.allIds.push(conversation.id);
        }
      } else {
        state.activeConversationId = null;
      }
    },

    //NOT IMPLEMENTED YET
    markConversationAsReadSuccess(state, action) {
      const { conversation_uuid } = action.payload;
      const conversation = state.conversations.byId[conversation_uuid];
      if (conversation) {
        conversation.unread_count = 0;
      }
    },

    //ADD NEWLY SENT MESSAGE
    saveMessageSuccess(state, action) {
      const newMessage = action.payload;
      state.conversations.byId[newMessage.conversation_uuid].messages.push(newMessage);
    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    addRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },

    //ADD MESSAGE TO STATE RECIVED FROM SOCKET
    addMessageFromSocket(state, action) {
      const message = action.payload.message;
      const conversation_uuid = action.payload.conversation_uuid;
      state.conversations.byId[conversation_uuid].messages.push(message);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { addRecipients, resetActiveConversation, addMessageFromSocket } = slice.actions;

// ----------------------------------------------------------------------

export function saveMessage(newMessage: NewMessage) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axios.post(
        '/api/send_message',
        {
          id: newMessage.id,
          conversation_uuid: newMessage.conversation_uuid,
          message: newMessage.message,
          content_type: newMessage.content_type,
          //attachments: newMessage.attachments,
          created_at: newMessage.created_at,
          sender_id: newMessage.sender_id,
        },
        { withCredentials: true }
      );
      dispatch(slice.actions.saveMessageSuccess(response.data));
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

// This will save conversation, participants and the message
// It will also update the state, so that you dont have to refresh
// to see the new conversation
export function createConversation(
  participants: number[],
  conversation_uuid: string,
  newMessage: NewMessage
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/api/create_conversation',
        {
          participants: participants,
          conversation_uuid: conversation_uuid,
          new_message: newMessage,
        },
        { withCredentials: true }
      );
      dispatch(slice.actions.getConversationSuccess(response.data));
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function getContacts(role: string, email: string, uid: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/contacts', {
        params: { role, email, uid }, // roles are included as query parameters
        withCredentials: true,
      });
      dispatch(slice.actions.getContactsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversations(uid: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/conversations', {
        params: { uid: uid },
      });
      dispatch(slice.actions.getConversationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversation(conversationKey: string, uid: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/conversation/${conversationKey}`, {
        params: { uid: uid },
      });
      dispatch(slice.actions.getConversationSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

//NOT IMPLEMENTED YET
export function markConversationAsRead(conversation_uuid: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.get('/api/chat/conversation/mark-as-seen', {
        params: { conversation_uuid },
      });
      dispatch(slice.actions.markConversationAsReadSuccess({ conversation_uuid }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getParticipants(conversationKey: string, uid: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/conversation_participants/${conversationKey}`, {
        params: { uid: uid },
      });
      dispatch(slice.actions.getParticipantsSuccess(response.data.participants));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function uploadChatFile(
  file: File,
  fileName: string,
  fileType: string,
  conversation_uuid: string,
  uid: number
) {
  return async (dispatch: Dispatch) => {
    const maxSize = 20 * 1024 * 1024; // 5 MB in bytes

    if (!allowedFileTypes.includes(file.type) || file.size > maxSize) {
      throw new Error('Validation failed: Incorrect file type or size exceeds limit');
    }

    try {
      const form = new FormData();
      form.append('file', file);
      form.append('fileName', fileName);
      form.append('fileType', fileType);
      form.append('conversation_uuid', conversation_uuid);
      form.append('uid', uid.toString());

      const response = await axios.post('/api/upload_chat_file', form, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(slice.actions.saveMessageSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
