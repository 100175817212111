import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { IReferralState } from '../../@types/referral';

const initialState: IReferralState = {
  isLoading: false,
  error: null,
  referrals: [],
  userReferrals: [],
  referral: null,
};

const referralSlice = createSlice({
  name: 'referral',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getReferralSuccess(state, action) {
      state.isLoading = false;
      state.referral = action.payload;
    },
    getReferralsSuccess(state, action) {
      state.isLoading = false;
      state.referrals = action.payload;
    },
    getUserReferralsSuccess(state, action) {
      state.isLoading = false;
      state.userReferrals = action.payload;
    },
  },
});

export default referralSlice.reducer;

export function getReferral(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(referralSlice.actions.startLoading());
    try {
      const response = await axios.get(`/api/referral/${id}`);
      if (response.status === 404) {
        throw new Error('Referral not found');
      } else {
        dispatch(referralSlice.actions.getReferralSuccess(response.data));
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(referralSlice.actions.hasError(errorMessage));
    }
  };
}

export function getReferrals() {
  return async (dispatch: Dispatch) => {
    dispatch(referralSlice.actions.startLoading());
    try {
      const response = await axios.get(`/api/referrals`);
      if (response.status === 404) {
        throw new Error('Referrals not found');
      } else {
        dispatch(referralSlice.actions.getReferralsSuccess(response.data));
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(referralSlice.actions.hasError(errorMessage));
    }
  };
}

export function getReferredByCustomer(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(referralSlice.actions.startLoading());
    try {
      const response = await axios.get(`/api/referred-customer/${id}`);
      if (response.status === 404) {
        throw new Error('Referrals not found');
      } else {
        // console.log(response.data);
        // console.log(!response.data.id);
        // console.log(!!response.data.id);
        if (response.data.id) {
          dispatch(referralSlice.actions.getReferralSuccess(response.data));
        } else {
          dispatch(referralSlice.actions.getReferralSuccess(null));
        }
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json_schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(referralSlice.actions.hasError(errorMessage));
    }
  };
}

export function getReferralsByTeacher(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(referralSlice.actions.startLoading());
    try {
      const response = await axios.get(`/api/referrals-teacher/${id}`);
      if (response.status === 404) {
        throw new Error('Referrals not found');
      } else {
        dispatch(referralSlice.actions.getUserReferralsSuccess(response.data));
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json_schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(referralSlice.actions.hasError(errorMessage));
    }
  };
}
