type CurrencyMapType = {
  [key: string]: string;
};

export const currency = [
  { code: 'DKK', label: 'kr.', value: 'DKK' },
  { code: 'EUR', label: '€', value: 'EUR' },
];

export const currencyMap: CurrencyMapType = {
  DKK: 'kr.',
  EUR: '€',
};

export const getCurrencyLabel = (currencyCode: string) => {
  return currencyMap[currencyCode] || currencyCode;
};
