import { useTranslation } from 'react-i18next';
import { allLangs, defaultLang } from './config-lang';
import { useAuthContext } from '../auth/useAuthContext';

import { useEffect } from 'react';

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const { user, updateLanguage } = useAuthContext();

  const language =
    (user?.language ? user.language.toLowerCase() : user?.market?.language.toLowerCase()) ||
    localStorage.getItem('i18nextLng');

  const currentLang = allLangs.find((_lang) => _lang.value === language) || defaultLang;

  useEffect(() => {
    if (i18n.language !== currentLang.value) {
      i18n.changeLanguage(currentLang.value);
    }
  }, [currentLang.value, i18n]);

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
    // console.log(user?.uid, newlang);

    if (user?.uid) {
      try {
        updateLanguage(user.uid, newlang);
      } catch (err) {
        console.log(err);
      }
    } else {
      localStorage.setItem('i18nextLng', newlang);
      sessionStorage.setItem('i18nextLng', newlang);
    }
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text: any, options?: any) => translate(text, options),
    currentLang,
    allLangs,
  };
}
