declare global {
  interface Window {
    grecaptcha: any;
  }
}

export default class reCAPTCHA {
  siteKey: string;
  action: string;

  constructor(siteKey: string, action: string) {
    this.siteKey = siteKey;
    this.action = action;
    loadReCaptcha(siteKey);
  }

  async getToken(): Promise<string> {
    const token = '';

    // await window.grecaptcha
    //   .execute(this.siteKey, { action: this.action })
    //   .then((res: string) => {
    //     token = res;
    //   })
    //   .catch((err: Error) => {
    //     console.log(err);
    //     console.error(err);
    //     throw Error('Issue validating user authenticity');
    //   });
    // console.log(token);

    return token;
  }
}

const loadReCaptcha = (siteKey: string) => {
  // const script = document.createElement('script');
  // script.setAttribute('id', 'g-recaptcha');
  // script.src = `https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=${siteKey}`;
  // script.async = true; // Add this line to load the script asynchronously
  // script.defer = true;
  // document.body.appendChild(script);
};
export const removeReCaptcha = () => {
  // const el = document.querySelector('.grecaptcha-badge');
  // if (el) {
  //   el.remove();
  // }
};
