import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

import { IStudentState, IStudent } from '../../@types/student';
import { ITutor } from '../../@types/tutor';
const initialState: IStudentState = {
  isLoading: false,
  error: null,
  students: [],
  student: null,
  studentLessonReport: [],
  activeStudentEmails: [],
};

const slice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null;
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;

      state.error = action.payload; // if you only write action.payload, you do not dot-in to the actual data, where all data for student is
    },
    // GET Students
    getStudentsSuccess(state, action) {
      state.isLoading = false;
      state.students = action.payload.data;
    },
    // GET Student
    getStudentSuccess(state, action) {
      state.isLoading = false;
      state.student = action.payload.data;
    },

    //GET lesson report student
    getStudentLessonReportSuccess(state, action) {
      state.isLoading = false;
      state.studentLessonReport = action.payload;
    },

    //GET STUDENT EMAILS ACTIVE
    getActiveStudentEmailsSuccess(state, action) {
      state.isLoading = false;
      state.activeStudentEmails = action.payload;
      state.error = null;
    },

    // CREATE Student
    createStudentSuccess(state, action) {
      const newEvent = action.payload;
      state.isLoading = false;
      state.students = state.students ? [newEvent, ...state.students] : [newEvent];
      state.error = null;
    },
    // UPDATE Student
    updateStudentSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.student = action.payload;
      if (state.students) {
        state.students = state.students.map((student) => {
          if (parseInt(student.id || '') === parseInt(action.payload.id)) {
            return action.payload;
          }
          return student;
        });
      }
    },
    assignTeachersSuccess(state, action) {
      state.isLoading = false;
      if (state.student) state.student.teachers = action.payload;
      state.error = null;
    },
    // DELETE Student
    deleteStudentSuccess(state, action) {
      state.isLoading = false;
      state.student = null;
    },
  },
});

export default slice.reducer;

export function getStudents(status: string | undefined) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    const status_query = status ? `status=${status}` : '';
    try {
      const response = await axios.get(`/api/students?${status_query}`);
      dispatch(slice.actions.getStudentsSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getStudentsCustomers(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/get_all_students_customer/${id}`);
      dispatch(slice.actions.getStudentsSuccess(response));
      // console.log(response);
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function getStudentsTeacher(email: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/get_all_students_teachers/${email}`);
      dispatch(slice.actions.getStudentsSuccess(response));
      return true;
      // console.log(response);
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getActiveStudentEmails() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/students_active_email');
      dispatch(slice.actions.getActiveStudentEmailsSuccess(response.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
/*
 * This method assigns tutors to students
 * @param teacherId Array of teacher Ids to assign to student
 * @param student_email Student Email
 * @returns the newly assigned teachers
 */
export function assignTeachersCustomers(teacherId: number[], studentEmail: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/api/take_teachers_customer',
        {
          teacher_id: teacherId,
          student_email: studentEmail,
        },
        { withCredentials: true }
      );
      // console.log(response);
      const ids = response.data.map((data: ITutor) => data.id.toString());
      dispatch(slice.actions.assignTeachersSuccess(ids));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
/**
 * This method assigns tutors to students
 * @param teacherId Array of teacher Ids to assign to student
 * @param student_email Student Email
 * @returns the newly assigned teachers
 */
export function assignTeachers(teacherId: number[], studentId: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        '/api/take_teachers',
        {
          teacher_id: teacherId,
          student_id: studentId,
        },
        { withCredentials: true }
      );
      // console.log(response);
      const ids = response.data.map((data: ITutor) => data.id.toString());
      dispatch(slice.actions.assignTeachersSuccess(ids));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getStudent(id: string | number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/students/${id}`);
      if (response.status === 404) {
        throw new Error('Student not found');
      } else {
        dispatch(slice.actions.getStudentSuccess(response));
        return true;
        // console.log(response.data);
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function getStudentLessonReport(status?: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());

    const statusQuery = status ? `status=${status}` : '';

    try {
      const response = await axios.get(`/api/students_lessons_report?${statusQuery}`);
      // console.log('API Response: ', response.data);

      const filteredResponse = response.data.report.filter(
        (entry: any) => entry.last_lesson_date !== null
      );

      dispatch(slice.actions.getStudentLessonReportSuccess(filteredResponse));
      return true;
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function createStudent(student: IStudent, send_email: boolean) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      if (student?.phone !== '') {
        const response = await axios.post(
          `/api/student_with_user`,
          {
            ...student,
            send_email: send_email,
          },
          { withCredentials: true }
        );
        if (response.status === 404) {
          throw new Error('Student not found');
        } else {
          dispatch(slice.actions.createStudentSuccess(response.data));
          return response.data.id;
          // console.log(response.data);
        }
      } else {
        const objectForRequest = {
          id: student.id,
          first_name: student.first_name,
          last_name: student.last_name,
          gender: student.gender,
          ...(student?.email && student?.email !== '' ? { email: student.email } : {}),
        };

        const response = await axios.post(
          '/api/student',
          {
            ...objectForRequest,
          },
          { withCredentials: true }
        );
        dispatch(slice.actions.createStudentSuccess(response.data));
        return response.data.id;
      }
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function deleteStudent(id: string | undefined) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/student/${id}`);

      if (response.status === 404) {
        throw new Error('Student not found');
      } else {
        dispatch(slice.actions.deleteStudentSuccess({}));
      }
    } catch (error) {
      // console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
    }
  };
}

export function editStudent(form: Partial<IStudent>) {
  const data_no_email = {
    id: form.id,
    first_name: form.first_name,
    last_name: form.last_name,
    phone: form.phone,
    email_lesson_reminder: form.email_lesson_reminders,
    email_lesson_notes: form.email_lesson_notes,
    status: form.status,
  };
  const data_to_update_user = {
    id: form.id,
    email: form.email,
    first_name: form.first_name,
    last_name: form.last_name,
    phone: form.phone,
    email_lesson_reminder: form.email_lesson_reminders,
    email_lesson_notes: form.email_lesson_notes,
    status: form.status,
  };

  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `/api/student`,
        form.email !== ''
          ? {
              ...data_to_update_user,
            }
          : { ...data_no_email },
        { withCredentials: true }
      );
      // // console.log(response);
      dispatch(slice.actions.updateStudentSuccess(response.data));
      return true; // Return true on success
    } catch (error) {
      // // console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function updateGradeLevel(
  student_id: string | number | undefined,
  customer_id: string | number | undefined,
  level: string,
  grade: string
) {
  const data_to_update_user = student_id
    ? {
        student_id: student_id,
        level: level,
        grade: grade,
      }
    : customer_id && { customer_id: customer_id, level: level, grade: grade };

  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(
        `/api/update_student_grade`,
        {
          ...data_to_update_user,
        },
        { withCredentials: true }
      );
      // // console.log(response);
      if (response.status === 200) {
        dispatch(slice.actions.updateStudentSuccess(response.data));
        return true; // Return true on success
      } else {
        return false;
      }
    } catch (error) {
      // // console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
export function convertChildStudent(
  studentId: number,
  studentEmail: string,
  phone: string,
  send_email: boolean
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `/api/convert_child_student`,
        {
          id: studentId,
          email: studentEmail,
          phone: phone,
          send_email: send_email,
        },
        { withCredentials: true }
      );
      // // console.log(response);
      dispatch(slice.actions.updateStudentSuccess(response.data));
      return true; // Return true on success
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
