import { useLocales, localesDates, LocaleKey } from '../locales'; // Import useLocales
import { format } from 'date-fns';
export default function useDateFormat() {
  const { currentLang } = useLocales();
  const locale = localesDates[currentLang.value as LocaleKey];

  function fDate(date: Date | string | number | null, formatString = 'dd MMM yy') {
    return date ? format(new Date(date), formatString, { locale }) : '';
  }

  return { fDate };
}
