import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Form
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider, { RHFTextField } from '../../../components/hook-form';
import CountrySelect from './CountrySelect';

// Hooks
import { useAuthContext } from '../../../auth/useAuthContext';
import { useLocales } from 'src/locales';
import { clearPropmtSignip } from '../../../redux/slices/ai';
// Mui components
import {
  Stack,
  Box,
  Typography,
  Alert,
  Link,
  Dialog,
  IconButton,
  InputAdornment,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme, alpha } from '@mui/material/styles';

// components
import Iconify from '../../../components/iconify';
import { useDispatch } from 'src/redux/store';

type FormValuesProps = {
  email: string;
  password: string;
  afterSubmit?: string;
};

type Props = {
  initialOpen: boolean;
  loginPageSignUp: boolean;
  loginPageClose?: (value: boolean) => void;
  setCookieUses?: (value: number) => void;
};

export default function SignUpPopUp({
  initialOpen,
  loginPageSignUp,
  loginPageClose,
  setCookieUses,
}: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log(initialOpen);
  const { registerAI } = useAuthContext();
  const { translate, currentLang } = useLocales();

  // Determine market based on currentLang.value
  const market = currentLang.value === 'es' ? 'ES' : 'DK';

  const [open, setOpen] = useState(initialOpen);
  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .required(translate('authLoginForm.emailRequired'))
      .email(translate('authLoginForm.validEmail')),
    password: Yup.string().required(translate('authLoginForm.passwordRequired')),
  });

  const defaultValues: FormValuesProps = {
    email: '',
    password: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const response = await registerAI(data.email, data.password, data.password, market);

      if (response) {
        // we clear the questions and the responses when nagivating
        await dispatch(clearPropmtSignip());
        setOpen(false);
        if (setCookieUses) {
          setCookieUses(0);
        }
        if (loginPageClose) {
          loginPageClose(false);
        }
        navigate('/ai');
      } else {
        reset();
      }
    } catch (error) {
      reset();
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.messages?.json._schema) {
        errorMessage = error?.messages?.json._schema[0];
      } else if (error?.messages?.json) {
        errorMessage = error?.messages?.json[Object.keys(error?.messages.json)[0]];
      } else if (error?.errors?.json) {
        console.log(error?.errors.json);
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      console.log(errorMessage);
      setError('afterSubmit', {
        ...error,
        message: errorMessage,
      });
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => {
        if (loginPageSignUp && loginPageClose) {
          setOpen(false);
          loginPageClose(false);
        }
      }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle sx={{ p: 2, m: 2 }} justifyContent="center">
          <Stack spacing={2} justifyContent="center">
            <Typography variant="h2" align="center">
              {String(translate('authLoginForm.getStartedForFree'))}
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
              <Stack direction="row" spacing={0.5}>
                <Iconify icon="ph:check" color={theme.palette.success.main} />
                <Typography variant="subtitle1">
                  {String(translate('authLoginForm.signUp2Minutes'))}
                </Typography>
              </Stack>
              {/* <Stack direction="row" spacing={0.5}>
                <Iconify icon="ph:check" color={theme.palette.success.main} />
                <Typography variant="subtitle1">
                  {String(translate('authLoginForm.signUpGetTokens'))}
                </Typography>
              </Stack> */}
            </Stack>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ p: 2, m: 2, display: 'flex', justifyContent: 'center' }}>
          <Stack spacing={2} maxWidth={'500px'}>
            <Stack direction="column" spacing={2} sx={{ my: 2, py: 2 }}>
              {errors.afterSubmit && (
                <Alert severity="error">
                  {String(translate('authForgotPasswordForm.errorMessage'))}{' '}
                  {String(errors.afterSubmit.message)}
                </Alert>
              )}
              {/* <CountrySelect /> */}
              <RHFTextField
                type="email"
                name="email"
                label={String(translate('authLoginForm.chooseEmail'))}
              />
              <RHFTextField
                name="password"
                label={String(translate('authLoginForm.choosePassword'))}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify
                          icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                          color={theme.palette.common.black}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Stack direction="column" spacing={2}>
              <LoadingButton
                fullWidth
                color="success"
                type="submit"
                size="large"
                variant="contained"
                sx={{
                  mt: 2,
                  mb: 5,
                  width: '100%',
                  height: '50px',
                  '&.Mui-disabled': {
                    background: alpha(theme.palette.success.main, 0.4),
                    color: theme.palette.success.contrastText,
                  },
                }}
                style={{
                  border: theme.palette.borders.border,
                  boxShadow: theme.palette.borders.boxShadow,
                  borderRadius: theme.palette.borders.borderRadius,
                }}
                endIcon={<Iconify icon="ph:caret-right" />}
                loading={isSubmitting}
              >
                {String(translate('authLoginForm.createAccount'))}
              </LoadingButton>
              <Typography
                component="div"
                sx={{ color: 'text.secondary', mt: 3, typography: 'caption', textAlign: 'center' }}
              >
                <Link underline="always" color="text.primary" href="/">
                  {String(translate('authLoginForm.terms'))}
                </Link>
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ m: 2, justifyContent: 'center' }}>
          <Typography
            component="div"
            sx={{ color: 'text.secondary', typography: 'body1', textAlign: 'center' }}
          >
            <Link underline="always" color="text.primary" href="/">
              {String(translate('authLoginForm.haveAccount'))}
            </Link>
          </Typography>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
