import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../../utils/axios';

import { IQualificationState } from '../../../@types/arrays';

const initialState: IQualificationState = {
  loadingQualification: false,
  error: null,
  qualifications: [],
  qualification: null,
};

const slice = createSlice({
  name: 'qualification',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loadingQualification = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.loadingQualification = false;
      state.error = action.payload;
    },
    // GET QualificationS
    getQualificationsSuccess(state, action) {
      state.loadingQualification = false;
      state.qualifications = action.payload.data;
    },
    // GET Tutor
    getQualificationSuccess(state, action) {
      state.loadingQualification = false;
      state.qualification = action.payload;
      // console.log(state.qualification);
    },
  },
});

export function getQualifications() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/qualifications');
      dispatch(slice.actions.getQualificationsSuccess(response.data));
      return true;
      // console.log(response.data);
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export default slice.reducer;
