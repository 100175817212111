import { useLocales } from '../../../locales';
import RoleBasedGuard from 'src/auth/RoleBasedGuard';
import Iconify from 'src/components/iconify';

import { Stack, Button, useTheme } from '@mui/material';

type LessonButtonsProps = {
  goBackStudent: (isStudent: boolean) => void;
  handleCancelModal: (state: boolean) => void;
  setFinishLesson: React.Dispatch<React.SetStateAction<boolean>>;
  returnForStudent: boolean;
  lesson_id: string;
  typeUser: string;
};

export default function LessonsButtons({
  goBackStudent,
  handleCancelModal,
  setFinishLesson,
  returnForStudent,
  lesson_id,
  typeUser,
}: LessonButtonsProps) {
  const { translate } = useLocales();

  const theme = useTheme();

  return (
    <Stack spacing={2} direction="row" sx={{ ml: 2, my: 0.75 }}>
      {typeUser === 'teacher' && (
        <>
          <Button
            variant="contained"
            color="warning"
            style={{
              border: theme.palette.borders.border,
              boxShadow: theme.palette.borders.boxShadow,
              borderRadius: theme.palette.borders.borderRadius,
            }}
            startIcon={<Iconify icon="ph:arrow-left" />}
            onClick={() => {
              goBackStudent(returnForStudent);
              window.location.replace('/');
            }}
          >
            {`${translate('lesson.goBack')}`}
          </Button>
          <RoleBasedGuard markets={['1']}>
            <Button
              variant="contained"
              color="info"
              style={{
                border: theme.palette.borders.border,
                boxShadow: theme.palette.borders.boxShadow,
                borderRadius: theme.palette.borders.borderRadius,
              }}
              startIcon={<Iconify icon="ph:blueprint" />}
              onClick={() => {
                if (lesson_id) {
                  window.open(`https://app.toptutors.com/lesson/${lesson_id}/details`);
                }
              }}
            >
              {`${translate('lesson.openLessonPlan')}`}
            </Button>
          </RoleBasedGuard>
          <Button
            variant="contained"
            color="error"
            startIcon={<Iconify icon="ph:calendar-x" />}
            style={{
              border: theme.palette.borders.border,
              boxShadow: theme.palette.borders.boxShadow,
              borderRadius: theme.palette.borders.borderRadius,
            }}
            onClick={() => {
              handleCancelModal(true);
            }}
          >
            {' '}
            {`${translate('lesson.registerCancellation')}`}
          </Button>
          <Button
            variant="contained"
            style={{
              border: theme.palette.borders.border,
              boxShadow: theme.palette.borders.boxShadow,
              borderRadius: theme.palette.borders.borderRadius,
            }}
            color="success"
            startIcon={<Iconify icon="ph:calendar-check" />}
            onClick={() => {
              setFinishLesson(true);
              goBackStudent(returnForStudent);
            }}
          >
            {`${translate('lesson.registerAttendance')}`}
          </Button>
        </>
      )}
      {typeUser === 'student' && (
        <Button
          variant="contained"
          startIcon={<Iconify icon="ph:arrow-left" />}
          style={{
            border: theme.palette.borders.border,
            boxShadow: theme.palette.borders.boxShadow,
            borderRadius: theme.palette.borders.borderRadius,
          }}
          onClick={() => {
            goBackStudent(returnForStudent);
          }}
        >
          {`${translate('lesson.finishLesson')}`}
        </Button>
      )}
    </Stack>
  );
}
