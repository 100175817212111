import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

import { IChargeTypeState, IChargeType } from '../../@types/charges';

const initialState: IChargeTypeState = {
  isLoading: false,
  error: null,
  chargesTypes: [],
  orderCharges: [],
  chargeType: null,
};

const slice = createSlice({
  name: 'chargetypetype',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null;
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;

      state.error = action.payload; // if you only write action.payload, you do not dot-in to the actual data, where all data for chargetype is
    },
    // GET ChargeTypes
    getChargeTypesSuccess(state, action) {
      state.isLoading = false;
      state.chargesTypes = action.payload;
    },
    // GET ChargeTypes
    getOrderChargesSuccess(state, action) {
      state.isLoading = false;
      state.orderCharges = action.payload;
    },
    // GET ChargeType
    getChargeTypeSuccess(state, action) {
      state.isLoading = false;
      state.chargeType = action.payload;
    },
    // DELETE ChargeType
    deleteChargeTypeSuccess(state, action) {
      state.isLoading = false;
      state.chargeType = null;
    },
  },
});

export default slice.reducer;

export function getChargeTypes() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/type`);
      dispatch(slice.actions.getChargeTypesSuccess(response.data.data));
      return true;
      // console.log(response.data);
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getChargesForOrder(id: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/charges_order/${id}`);
      dispatch(slice.actions.getOrderChargesSuccess(response.data));
      return true;
      // console.log(response.data);
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
