import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../../utils/axios';

import { ISubjectState } from '../../../@types/arrays';

const initialState: ISubjectState = {
  loadingSubject: false,
  error: null,
  subjects: [],
  subject: null,
};

const slice = createSlice({
  name: 'subject',
  initialState,
  reducers: {
    // START loadingG
    startLoading(state) {
      state.loadingSubject = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.loadingSubject = false;
      state.error = action.payload;
    },
    // GET subjectS
    getSubjectsSuccess(state, action) {
      state.loadingSubject = false;
      state.subjects = action.payload.data;
    },
    // GET Tutor
    getSubjectSuccess(state, action) {
      state.loadingSubject = false;
      state.subject = action.payload;
      // console.log(state.subject);
    },
  },
});

export function getSubjects() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/subjectss');
      dispatch(slice.actions.getSubjectsSuccess(response.data));
      return true;
      // console.log(response.data);
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export default slice.reducer;
