export const emojis = [
  '💪',
  '🙌',
  '🚀',
  '🎯',
  '🧠',
  '🌟',
  '🔍',
  '📚',
  '👏',
  '🎓',
  '🎉',
  '💡',
  '🏆',
  '📘',
  '✏️',
  '🔬',
  '📈',
  '👍',
  '⭐',
  '💫',
];
