import { useEffect } from 'react';
// redux
import { useDispatch, useSelector } from '../../../redux/store';
import { useAuthContext } from 'src/auth/useAuthContext';
import RoleBasedGuard from 'src/auth/RoleBasedGuard';
import ExtraCredits from './ExtraCredits';
import { getLatestNonMembershipOrder, getCustomerLatestOrder } from 'src/redux/slices/customer';

export default function SingleHoursOrder() {
  const { user } = useAuthContext();
  const dispatch = useDispatch();

  const hasMembership = user?.customer_dict
    ? user?.customer_dict.has_membership
    : user?.student && user?.student_dict
    ? user?.student_dict.has_membership
    : false;

  const { latestCustomerOrder, latestNonMembershipOrder } = useSelector((state) => state.customer);

  useEffect(() => {
    const customer_id = user?.customer_dict
      ? user?.customer_dict.id
      : user?.student
      ? user?.student_dict.customer_id
      : null;

    if (hasMembership) {
      if (!latestCustomerOrder && customer_id) {
        dispatch(getCustomerLatestOrder(customer_id));
      }
    } else {
      if (!latestNonMembershipOrder && customer_id) {
        dispatch(getLatestNonMembershipOrder(customer_id));
      }
    }
  }, [user]);

  return (
    <>
      <RoleBasedGuard roles={['customer', 'student']} markets={['1', '2']}>
        <ExtraCredits
          navbar={true}
          latestCustomerOrder={hasMembership ? latestCustomerOrder : latestNonMembershipOrder}
        />
      </RoleBasedGuard>
    </>
  );
}
