import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Link, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  redirectPath?: string;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, redirectPath, sx, ...other }, ref) => {
    // using local (public folder)
    // -------------------------------------------------------
    const logo = (
      <Box
        component="img"
        src="/logo/toptutors-logo.svg"
        sx={{ height: 50, cursor: 'pointer', ...sx }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link
        component={RouterLink}
        to={redirectPath ? redirectPath : '/'}
        sx={{ display: 'contents' }}
      >
        {logo}
      </Link>
    );
  }
);

Logo.displayName = 'Logo';

export default Logo;
