import { useState } from 'react';
// mui
import {
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Stack,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';

// hooks
import { useSnackbar } from '../../../components/snackbar';
import { useAuthContext } from '../../../auth/useAuthContext';
import { useLocales } from '../../../locales';
import { useTheme, alpha, styled } from '@mui/material/styles';
// components
import Iconify from '../../../components/iconify/Iconify';
// redux
import { orderAiTokens } from '../../../redux/slices/invoice';
import { useDispatch } from '../../../redux/store';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  dontShowButton?: boolean;
};

export const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontSize: '0.75rem',
    padding: 10,
    borderRadius: theme.palette.borders.borderRadius,
  },
}));

const PurchaseTokensButton = ({ open, setOpen, dontShowButton = true }: Props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const { user } = useAuthContext();

  const [quantity, setQuantity] = useState(5000);

  const handlePay = async () => {
    try {
      const response = await dispatch(
        orderAiTokens(
          user?.customer_dict ? user?.customer_dict.id : user?.student_dict.customer_id,
          quantity
        )
      );
      if (response) {
        // console.log('Success');
      } else {
        enqueueSnackbar('Checkout error. Try again or contact support', { variant: 'error' });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar('Checkout error. Try again or contact support', { variant: 'error' });
    }
  };

  return (
    <>
      {!dontShowButton && (
        <Tooltip title={String(translate('freemium.upgradePremium'))}>
          <IconButton
            onClick={() => setOpen(true)}
            color="inherit"
            sx={{
              color: theme.palette.common.black,
            }}
          >
            <Iconify icon="ph:basket" />
          </IconButton>
        </Tooltip>
      )}

      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        PaperProps={{
          sx: {
            p: 3,
            borderRadius: 2,
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h4" align="center" gutterBottom>
            {String(translate('freemium.upgradePremiumTitle'))}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="center"
            spacing={3}
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <Iconify icon="ph:check" color={theme.palette.success.main} />
              <Typography variant="subtitle1">
                {String(translate('freemium.noCommitment'))}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Iconify icon="ph:check" color={theme.palette.success.main} />
              <Typography variant="subtitle1">
                {String(translate('freemium.purchaseDescription'))}
              </Typography>
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions sx={{ justifyContent: 'center', gap: 2 }}>
          <Button
            variant="contained"
            fullWidth
            color="success"
            size="large"
            onClick={handlePay}
            endIcon={<Iconify icon="ph:caret-right" />}
            sx={{
              minWidth: '120px',
              '&.Mui-disabled': {
                backgroundColor: alpha(theme.palette.success.main, 0.4),
                color: theme.palette.success.contrastText,
              },
              borderRadius: theme.palette.borders.borderRadius,
              boxShadow: theme.palette.borders.boxShadow,
              border: theme.palette.borders.border,
              maxWidth: '500px',
              '&:hover': {
                color: theme.palette.success.contrastText,
                border: theme.palette.borders.border,
                backgroundColor: theme.palette.success.main,
                boxShadow: theme.palette.borders.boxShadow,
              },
            }}
          >
            {String(translate('ai.continue'))}
          </Button>
          <BootstrapTooltip
            placement="top"
            title={String(translate('ai.purchaseTokens.helpTooltip'))}
          >
            <IconButton
              sx={{
                position: 'absolute',
                right: 5,
                top: 5,
              }}
            >
              <Iconify icon="ph:info-fill" width={35} />
            </IconButton>
          </BootstrapTooltip>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PurchaseTokensButton;
