// src/components/Guarantee.tsx
import { Stack, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Iconify from 'src/components/iconify/Iconify';
import { useLocales } from 'src/locales';

export default function Guarantee() {
  const { translate } = useLocales();
  const theme = useTheme();

  return (
    <Stack direction="column" alignItems="center">
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Iconify icon="ph:seal-check-bold" color={theme.palette.success.main} width={25} />
        <Typography variant="h5" color={theme.palette.success.main} sx={{ ml: 0.5 }}>
          {String(translate('memberships.guaranteeTitle'))}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="center">
        <Typography style={{ width: '75%' }} variant="body2" textAlign="center">
          {String(translate('memberships.guaranteeBody'))}
        </Typography>
      </Stack>
      <Box component="img" src="/assets/trustpilot_logo.svg" sx={{ height: 30, mt: '30px' }} />
      <Box component="img" src="/assets/trustpilot_ratings_4halfstar.svg" sx={{ height: 30 }} />
    </Stack>
  );
}
