// @mui
import { enUS, daDK, esES } from '@mui/material/locale';
import { enGB, es, da } from 'date-fns/locale';

import '@formatjs/intl-displaynames/polyfill';
import '@formatjs/intl-displaynames/locale-data/en'; // locale-data for en
import '@formatjs/intl-displaynames/locale-data/da'; // locale-data for da
import '@formatjs/intl-displaynames/locale-data/es'; // locale-data for es

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'Dansk',
    value: 'da',
    systemValue: daDK,
    icon: '/assets/icons/flags/ic_flag_da.svg',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'Español',
    value: 'es',
    systemValue: esES,
    icon: '/assets/icons/flags/ic_flag_es.svg',
  },
];

export const localesDates = {
  en: enGB,
  es: es,
  da: da,
};

export type LocaleKey = 'en' | 'es' | 'da';

export const defaultLang = allLangs[1]; // default language
