import { createSlice, Dispatch } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { IRewardState } from '../../@types/reward'; // Make sure to define this type

const initialState: IRewardState = {
  isLoading: false,
  error: null,
  rewards: [],
  reward: null,
};

const rewardSlice = createSlice({
  name: 'reward',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getRewardSuccess(state, action) {
      state.isLoading = false;
      state.reward = action.payload;
    },
    getRewardsSuccess(state, action) {
      state.isLoading = false;
      state.rewards = action.payload;
    },
    createRewardSuccess(state, action) {
      state.isLoading = false;
      state.reward = action.payload;
    },
    updateRewardSuccess(state, action) {
      state.isLoading = false;
      state.reward = action.payload;
    },
  },
});

export default rewardSlice.reducer;

export function getReward(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(rewardSlice.actions.startLoading());
    try {
      const response = await axios.get(`/api/reward/${id}`);
      dispatch(
        response.status === 404
          ? rewardSlice.actions.hasError('Reward not found')
          : rewardSlice.actions.getRewardSuccess(response.data)
      );
    } catch (error) {
      dispatch(rewardSlice.actions.hasError(error));
    }
  };
}

export function getRewards() {
  return async (dispatch: Dispatch) => {
    dispatch(rewardSlice.actions.startLoading());
    try {
      const response = await axios.get(`/api/rewards`);
      dispatch(
        response.status === 404
          ? rewardSlice.actions.hasError('Rewards not found')
          : rewardSlice.actions.getRewardsSuccess(response.data)
      );
    } catch (error) {
      dispatch(rewardSlice.actions.hasError(error));
    }
  };
}

export function createReward(data: any) {
  return async (dispatch: Dispatch) => {
    dispatch(rewardSlice.actions.startLoading());
    try {
      const response = await axios.post('/api/rewards', data);
      dispatch(
        response.status === 201
          ? rewardSlice.actions.createRewardSuccess(response.data)
          : rewardSlice.actions.hasError('Failed to create reward')
      );
    } catch (error) {
      dispatch(rewardSlice.actions.hasError(error));
    }
  };
}

export function updateReward(data: any) {
  return async (dispatch: Dispatch) => {
    dispatch(rewardSlice.actions.startLoading());
    try {
      const response = await axios.put('/api/update_reward', data);
      dispatch(
        response.status !== 200
          ? rewardSlice.actions.hasError('Failed to update reward')
          : rewardSlice.actions.updateRewardSuccess(response.data)
      );
    } catch (error) {
      dispatch(rewardSlice.actions.hasError(error));
    }
  };
}

export function deleteReward(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(rewardSlice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/reward/${id}`);
      if (response.status !== 200) {
        dispatch(rewardSlice.actions.hasError('Failed to delete reward'));
      }
      // Note: You might want to handle success in some way, e.g., remove the reward from the state.
    } catch (error) {
      dispatch(rewardSlice.actions.hasError(error));
    }
  };
}
