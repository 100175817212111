import { createSlice, Dispatch } from '@reduxjs/toolkit';

import axios from '../../utils/axios';
import { IKpiState } from '../../@types/kpi';

const initialState: IKpiState = {
  isLoading: false,
  error: null,
  active_teachers: 0,
  active_students: 0,
  active_customers: 0,
  new_customers: 0,
  new_students: 0,
  hired_teachers: 0,
  monthly_billable_lessons: 0,
  new_teacher_applicants: 0,
  recent_billable_lessons: [],
  recent_attended_lessons: [],
  recent_expired_lessons: [],
  recent_bad_cancellation_lessons: [],
  top_tutors: [],
  top_customers: [],
  all_billable_lessons: [],
  booking_statistics: [],
  monthly_active_users: [],
  unique_bookings: [],
  average_booking_price: [],
  customer_churn_statistics: [],
  teacher_churn_statistics: [],
  sales_cycle: [],
  churn_monthly_active_users: [],
  monthly_churned_precentage: [],
};

const slice = createSlice({
  name: 'kpi',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null;
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload; // if you only write action.payload, you do not dot-in to the actual data, where all data for lesson is
    },
    getActiveStatusSuccess(state, action) {
      state.isLoading = false;
      state.active_teachers = action.payload.active_teachers;
      state.active_customers = action.payload.active_customers;
      state.active_students = action.payload.active_students;
    },
    getMonthStatusSuccess(state, action) {
      state.isLoading = false;
      state.new_customers = action.payload.new_customers;
      state.monthly_billable_lessons = action.payload.billable_lessons;
      state.new_students = action.payload.new_students;
      state.new_teacher_applicants = action.payload.new_teacher_applicants;
      state.hired_teachers = action.payload.hired_teachers;
    },
    getRecentBillableLessonsSuccess(state, action) {
      state.isLoading = false;
      state.recent_billable_lessons = action.payload.billable_lessons;
      state.recent_attended_lessons = action.payload.attended_lessons;
      state.recent_expired_lessons = action.payload.expired_lessons;
      state.recent_bad_cancellation_lessons = action.payload.bad_cancellation_lessons;
    },
    getTopTutorsSuccess(state, action) {
      state.isLoading = false;
      state.top_tutors = action.payload.top_tutors;
    },
    getTopCustomersSuccess(state, action) {
      state.isLoading = false;
      state.top_customers = action.payload.top_customers;
    },
    getAllBillableLessonsSuccess(state, action) {
      state.isLoading = false;
      state.all_billable_lessons = action.payload.billable_lessons;
    },
    getBookingStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.booking_statistics = action.payload.booking_statistics;
    },
    getMonthlyActiveUsersSuccess(state, action) {
      state.isLoading = false;
      state.monthly_active_users = action.payload.monthly_active_users;
    },
    getUniqueBookingsSuccess(state, action) {
      state.isLoading = false;
      state.unique_bookings = action.payload.unique_bookings;
    },
    getAverageBookingSuccess(state, action) {
      state.isLoading = false;
      state.average_booking_price = action.payload.average_booking_price;
    },
    getCustomerChurnStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.customer_churn_statistics = action.payload.customer_churn_statistics;
    },
    getTeacherChurnStatisticsSuccess(state, action) {
      state.isLoading = false;
      state.teacher_churn_statistics = action.payload.teacher_churn_statistics;
    },
    getSalesCycleSuccess(state, action) {
      state.isLoading = false;
      state.sales_cycle = action.payload.sales_cycle;
    },
    getChurnMonthlyActiveUsersSuccess(state, action) {
      state.isLoading = false;
      state.churn_monthly_active_users = action.payload.churn_monthly_active_users;
      state.monthly_churned_precentage = action.payload.monthly_churned_precentage;
    },
  },
});

export default slice.reducer;

/**
 * @returns array of lessons and assings them to the state
 */
export function getActiveStatus() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/active_status`, { withCredentials: true });
      if (response.status === 404) {
        throw new Error('Customer not found');
      } else {
        dispatch(slice.actions.getActiveStatusSuccess(response.data.data));
        return true;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }

      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getMonthStatus(start_date: string | undefined, end_date: string | undefined) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    const url =
      start_date && end_date
        ? `/api/month_status?start_date=${start_date}&end_date=${end_date}`
        : `/api/month_status`;
    try {
      const response = await axios.get(url, { withCredentials: true });
      dispatch(slice.actions.getMonthStatusSuccess(response.data.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getRecentBillableLessons(
  start_date: string | undefined,
  end_date: string | undefined
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    const url =
      start_date && end_date
        ? `/api/recent_billable_lessons?start_date=${start_date}&end_date=${end_date}`
        : `/api/recent_lessons_status`;
    try {
      const response = await axios.get(url, { withCredentials: true });
      dispatch(slice.actions.getRecentBillableLessonsSuccess(response.data.data));
      return true;
    } catch (error) {
      console.log(error);
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else if (error?.errors?.json) {
        errorMessage = error?.errors.json[Object.keys(error?.errors.json)[0]];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getTopTutors() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/top_tutors`, { withCredentials: true });
      if (response.status === 404) {
        throw new Error('Tutors not found');
      } else {
        dispatch(slice.actions.getTopTutorsSuccess(response.data.data));
        return true;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }

      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getTopCustomers() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/top_customers`, { withCredentials: true });
      if (response.status === 404) {
        throw new Error('Tutors not found');
      } else {
        dispatch(slice.actions.getTopCustomersSuccess(response.data.data));
        return true;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }

      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getAllBillableLessons() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/all_billable_lessons`, { withCredentials: true });
      if (response.status === 404) {
        throw new Error('Tutors not found');
      } else {
        dispatch(slice.actions.getAllBillableLessonsSuccess(response.data.data));
        return true;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getBookingStatistics() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/booking_statistics`, { withCredentials: true });
      if (response.status === 404) {
        throw new Error('Tutors not found');
      } else {
        // console.log(response);
        dispatch(slice.actions.getBookingStatisticsSuccess(response.data.data));
        return true;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getMonthlyActiveUsers() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/monthly_active_users`, { withCredentials: true });
      if (response.status === 404) {
        throw new Error('Tutors not found');
      } else {
        dispatch(slice.actions.getMonthlyActiveUsersSuccess(response.data.data));
        return true;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getUniqueBookings() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/unique_bookings`, { withCredentials: true });
      if (response.status === 404) {
        throw new Error('Tutors not found');
      } else {
        dispatch(slice.actions.getUniqueBookingsSuccess(response.data.data));
        return true;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getAverageBooking() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/average_booking_price`, { withCredentials: true });
      if (response.status === 404) {
        throw new Error('Tutors not found');
      } else {
        dispatch(slice.actions.getAverageBookingSuccess(response.data.data));
        return true;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getCustomerChurnStatistics() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/customer_churn_statistics`, { withCredentials: true });
      if (response.status === 404) {
        throw new Error('Tutors not found');
      } else {
        dispatch(slice.actions.getCustomerChurnStatisticsSuccess(response.data.data));
        return true;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getTeacherChurnStatistics() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/teacher_churn_statistics`, { withCredentials: true });
      if (response.status === 404) {
        throw new Error('Tutors not found');
      } else {
        dispatch(slice.actions.getTeacherChurnStatisticsSuccess(response.data.data));
        return true;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getSalesCycle() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/sales_cycle`, { withCredentials: true });
      if (response.status === 404) {
        throw new Error('Tutors not found');
      } else {
        dispatch(slice.actions.getSalesCycleSuccess(response.data.data));
        return true;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}

export function getChurnMonthlyActiveUsers() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/churn_monthly_active_users`, {
        withCredentials: true,
      });
      if (response.status === 404) {
        throw new Error('Tutors not found');
      } else {
        dispatch(slice.actions.getChurnMonthlyActiveUsersSuccess(response.data.data));
        return true;
      }
    } catch (error) {
      let errorMessage = '';
      if (error?.errors?.json._schema) {
        errorMessage = error?.errors?.json._schema[0];
      } else {
        errorMessage = error?.message;
      }
      dispatch(slice.actions.hasError(errorMessage));
      return false;
    }
  };
}
