// @mui
import { Stack, Button, Typography } from '@mui/material';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
import RoleBasedGuard from '../../../auth/RoleBasedGuard';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function NavDocs() {
  const { user } = useAuthContext();

  const { translate } = useLocales();

  return (
    <RoleBasedGuard roles={['teacher']}>
      <Stack
        spacing={3}
        sx={{
          px: 5,
          pb: 5,
          mt: 10,
          width: 1,
          display: 'block',
          textAlign: 'center',
        }}
      >
        <div>
          <Typography gutterBottom variant="subtitle1">
            {`${translate('overview.hi')},${user?.first_name ? ` ${user.first_name}` : ''}`}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-line' }}>
            {String(translate('overview.needHelp'))} <br />
            {String(translate('overview.wiki'))}
          </Typography>
        </div>

        <RoleBasedGuard markets={['1']}>
          <Button
            variant="contained"
            target="_blank"
            rel="noopener noreferrer"
            href="https://toptutors.notion.site/toptutors/Toptutors-Wiki-8b14e3a42c624207ac3aa8f0dac81c08"
          >
            Toptutors Wiki
          </Button>
        </RoleBasedGuard>
        <RoleBasedGuard markets={['2']}>
          <Button
            variant="contained"
            target="_blank"
            rel="noopener noreferrer"
            href="https://toptutors.notion.site/Toptutors-Wiki-8f4461e5454f4e9ab0a87274b0256268"
          >
            Wiki de Toptutors
          </Button>
        </RoleBasedGuard>
      </Stack>
    </RoleBasedGuard>
  );
}
